import React, {useState} from 'react';

import {useTranslation} from "react-i18next";
import {useAppDispatch} from "../../../../app/store";
import {useSelector} from "react-redux";
import {selectPackages, sendPackageInvites, updatePackage} from "../../packageSlice";

import {requestModalRoute} from "../../../modal/modalSlice";
import styled from "styled-components/macro";
import {Button} from "../../../../components/Button";
import {IconInput} from "../../../../components/IconInput";
import {unwrapResult} from "@reduxjs/toolkit";
import {selectUser} from "../../../user/userSlice";
import {formatDateForInput} from "../../../../helpers";
import {useHistory} from "react-router";
import {useToasts} from "react-toast-notifications";

export function PackageSettings() {
    const dispatch = useAppDispatch();
    const packagesState = useSelector(selectPackages);
    const userState = useSelector(selectUser);
    const history = useHistory();
    const {addToast} = useToasts();

    const initialValidationState = {
        welcomeMessage: [],
    };

    const [validationErrors, setValidationErrors] = useState(initialValidationState);
    const [welcomeMessage, setWelcomeMessage] = useState(packagesState.package.welcomeMessage);
    const [reminderInterval, setReminderInterval] = useState(packagesState.package.reminderInterval);
    const [expiresAt, setExpiresAt] = useState(packagesState.package.expiresAt);

    const {t} = useTranslation();

    const handleSubmit = () => {
        dispatch(updatePackage({
            id: packagesState.package.id,
            welcomeMessage: welcomeMessage,
            reminderInterval: reminderInterval,
            expiresAt: expiresAt,
            phoneVerificationRequired: packagesState.package.phoneVerificationRequired
        })).then(() => {
            const customerId = userState?.currentUser?.customer?.id; // for now...

            dispatch(sendPackageInvites({
                packageId: packagesState.package.id,
                customerId: customerId,
            })).then(unwrapResult).then(result => {
                if (result?.error) {
                    addToast('Uitnodiging kon niet verzonden worden, is de verloopdatum in de toekomst en is voor iedereen een handtekening veld geplaatst?', {appearance: 'error', autoDismiss: true});
                }
                else {
                    history.push('/packages');
                    dispatch(requestModalRoute(null, {}))
                }
            });
        })
    }

    return (
        <Wrapper>
            <form>
                <div className={"email"}>
                    <div className={"formgroup"}>
                        <IconInput className={"email"} validationError={t(validationErrors.welcomeMessage[0])}
                                   type={"textarea"}
                                   placeholder={t('welcome_message_placeholder')}
                                   value={packagesState.package.welcomeMessage}
                                   onChange={(e) => {
                                       setWelcomeMessage(e.target.value);
                                   }}/>
                    </div>
                    <div className={"formgroup setting"}>
                        Herinner ondertekenaars iedere
                        <IconInput className={"reminder-interval"} type={"number"}
                                   value={packagesState.package.reminderInterval}
                                   min={1}
                                   max={14}
                                   onChange={(e) => {
                                       setReminderInterval(e.target.value);
                                   }}/> dagen.
                    </div>
                    <div className={"formgroup setting"}>
                        Sta ondertekenen toe tot
                        <IconInput className={"expires-at"} type={"date"}
                                   value={formatDateForInput(packagesState.package.expiresAt)}
                                   min={formatDateForInput(new Date())}
                                   max={formatDateForInput(new Date().setDate(new Date().getDate() + 90))}
                                   onChange={(e) => {
                                       setExpiresAt(e.target.value);
                                   }}/> dagen.
                    </div>
                </div>
            </form>

            <div className={"actions"}>
                <Button text={t('send_invites')} onClick={handleSubmit} className={"primary"}/>
            </div>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    flex: 0 0 450px;       
    padding-bottom: 15px;
    
    @media screen and (max-width: 1000px) {
      flex: 1;    
    }
    
    .formgroup {      
        flex: 1;  
        padding-bottom: 20px;
        
        &.setting {
            display: flex;        
            align-items: center;
            
            @media screen and (max-width: 1000px) {
              flex-direction: column;
              
              >div {
                margin: 5px 0 5px 0;
              }
            }
        }
            
       .reminder-interval {
          display: inline-block;
          margin: 0 10px 0 10px;
          width: 80px;
              
          input {
            width: 100%;
          }
        }
               
        .expires-at {
          display: inline-block;
          margin: 0 10px 0 10px;
          width: 170px;
        }
    }
    
    .actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin: 0 -10px 0 -10px;
        
        .button {
          margin: 5px;
        }
  }
`;
