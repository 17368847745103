import React, {memo} from 'react';
import styled from 'styled-components/macro';
import {FaMinus, FaPlus, FiZoomIn, FiZoomOut, GoTrashcan} from "react-icons/all";


import './rnd.css';
import ReactTooltip from "react-tooltip";
import {useTranslation} from "react-i18next";

interface Props {
    activeFieldId: string;
    onZoomIn: () => any
    onZoomOut: () => any;
    onDelete: () => any;
    showDelete : boolean;
}


export const ToolBar = memo((props: Props) => {
    const {t} = useTranslation();

    return (
        <Wrappper>
            {props.showDelete && props.activeFieldId !== "" && <a onClick={props.onDelete} data-tip={t('delete')}><GoTrashcan className={"icon"}/></a>}
            <a onClick={props.onZoomOut} data-tip={t('zoom_out')}><FiZoomOut className={"icon"}/></a>
            <a onClick={props.onZoomIn} data-tip={t('zoom_in')}><FiZoomIn className={"icon"}/></a>
        </Wrappper>
    );
});


const Wrappper = styled.div`  
    display:flex;   
    flex: 0 0 32px;
    padding: 10px;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,.2);
    position: absolute;
    width: calc(100% - 20px);
    z-index: 2;
    
    @media screen and (max-width: 1000px) {
        .__react_component_tooltip {
          display: none;
        }
    }
    
    a {        
        display:flex;
        justify-content: center;
        align-items: center;
        color: #333;
        height:36px;
        width:36px;
        border-radius: 4px;
        cursor: pointer;
        background-color:white;
        margin: 0 8px 0 8px;
        transition: background 0.3s ease-out 0.05s;
        
        &:hover {
            background-color: #ccc;
        }
        
        .icon {
            font-size:1.4rem;
        }
    }
    
    
`;