import React from 'react'
import {Redirect, Route} from 'react-router-dom'
import {useSelector} from "react-redux";
import {selectUser} from "../features/user/userSlice";

interface Props {
    path: string,
    exact: boolean,
    component: any;
}

const PrivateRoute = ({component: Component, ...rest}: Props) => {

    const userState = useSelector(selectUser);

    return (
        <Route
            {...rest}
            render={props =>
                userState.currentUser ?
                    <Component {...props} />
                    :
                    <Redirect to={"/"}/>

            }
        />
    )
}

export default PrivateRoute