import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppThunk, RootState} from '../../app/store';
import PackageTransformer from "../../transformers/PackageTransformer";
import SignatureFieldTransformer from "../../transformers/SignatureFieldTransformer";
import DocumentTransformer from "../../transformers/DocumentTransformer";
import SignerTransformer from "../../transformers/SignerTransformer";
import {config} from "../../app/config";
import SignatureTransformer from "../../transformers/SignatureTransformer";
import {formatError, getHeaders} from '../../helpers';
import PackageStatsTransformer from "../../transformers/PackageStatsTransformer";
import TextFieldTransformer from "../../transformers/TextFieldTransformer";

const axios = require('axios').default;

interface PackageStats {
  all: number,
  draft: number,
  inProgress: number,
  completed: number,
  expired: number,
  canceled: number,
}

interface PackagesState {
  uploading: boolean;
  sending: boolean;
  packages: any[];
  package: any;
  packageStats: PackageStats;
  document: any;
  signatureFields: any[];
  textFields: any[];
  signers: any[];
  signatures: any[];
  activeFieldId: string;
  currentPage: number;
}

const initialState: PackagesState = {
  uploading: false,
  sending: false,
  packages: [],
  package: null,
  packageStats: {all: 0, draft: 0, inProgress: 0, completed: 0, expired: 0, canceled: 0},
  document: null,
  signatureFields: [],
  textFields: [],
  signers: [],
  signatures: [],
  activeFieldId: "",
  currentPage: 0,
};


const axiosInstance = axios.create({
  baseURL: config.api_base_url,
  timeout: 1000 * 60 * 5,
});

export const fetchPackages = (payload?: { status: string, query: string } | {}): AppThunk => dispatch => {
  // @ts-ignore
  const queryString = payload ? Object.keys(payload).map((key) => {
    // @ts-ignore
    return payload[key] ? key + '=' + payload[key] : ''
  }).join('&') : '';

  axiosInstance.get('/api/packages?' + queryString, {
    headers: getHeaders()
  })
    .then(function (response: any) {
      dispatch(packagesFetched(PackageTransformer.transformList(response.data)));
    })
    .catch(function (error: any) {
      return formatError(error);
    });
};

export const fetchPackageStats = (): AppThunk => dispatch => {
  axiosInstance.get('/api/packages/stats', {
    headers: getHeaders()
  })
    .then(function (response: any) {
      dispatch(packageStatsFetched(PackageStatsTransformer.transform(response.data)));
    })
    .catch(function (error: any) {
      return formatError(error);
    });
};

export const fetchPackage = createAsyncThunk(
  'package/fetch',
  async (payload: { id: string }, thunkAPI) => {
    const response = await axiosInstance.get('/api/packages/' + payload.id, {
      headers: getHeaders(payload.id)
    }).catch((error: any) => {
      return formatError(error);
    });

    if (response?.error) {
      return response;
    }

    thunkAPI.dispatch(packageFetched(PackageTransformer.transform(response.data)));
    thunkAPI.dispatch(signatureFieldsFetched(SignatureFieldTransformer.transformList(response.data.signatureFields ?? [])));
    thunkAPI.dispatch(textFieldsFetched(TextFieldTransformer.transformList(response.data.textFields ?? [])));
    thunkAPI.dispatch(signaturesFetched(SignatureTransformer.transformList(response.data.signatures ?? [])));
    thunkAPI.dispatch(documentFetched(DocumentTransformer.transform(response.data.documents[0])));

    return response.data;
  }
);

export const createPackage = createAsyncThunk(
  'package/create',
  async (payload: { name: string, customerId?: string }, thunkAPI) => {
    const response = await axiosInstance.post('/api/packages', payload, {
      headers: getHeaders()
    }).catch((error: any) => {
      return formatError(error);
    });

    thunkAPI.dispatch(packageCreated(PackageTransformer.transform(response.data)));
    return response.data;
  }
);

export const updatePackage = createAsyncThunk(
  'package/update',
  async (payload: { id: string, welcomeMessage?: string, reminderInterval?: number, expiresAt?: string, phoneVerificationRequired?: boolean }, thunkAPI) => {
    const response = await axiosInstance.patch('/api/packages/' + payload.id, JSON.stringify(payload), {
      headers: getHeaders(payload.id)
    }).catch(function (error: any) {
      return formatError(error);
    });

    return response.data;
  }
);

export const bindPackage = createAsyncThunk(
  'package/bind_package',
  async (payload: { id: string, anonymousToken: string }, thunkAPI) => {
    const response = await axiosInstance.patch('/api/packages/' + payload.id + '/bind_ownership', JSON.stringify(payload), {
      headers: getHeaders(payload.id)
    }).catch(function (error: any) {
      return formatError(error);
    });

    return response.data;
  }
);

export const sendPackageInvites = createAsyncThunk(
  'package/invite',
  async (payload: { packageId: string, customerId?: string }, thunkAPI) => {
    const response = await axiosInstance.post('/api/packages/' + payload.packageId + '/invite',
      {customerId: payload.customerId}, {
        headers: getHeaders(payload.packageId)
      }).catch((error: any) => {
      return formatError(error);
    });
    return response.data ? response.data : response;
  }
);

export const deletePackage = (id: string): AppThunk => dispatch => {
  axiosInstance.delete('/api/packages/' + id, {
    headers: getHeaders(id)
  })
    .then(function (response: any) {
      dispatch(packageDeleted(id));
      dispatch(fetchPackageStats());
    })
    .catch(function (error: any) {
      return formatError(error);
    });
};

export const cancelPackage = (id: string): AppThunk => dispatch => {
  axiosInstance.post('/api/packages/' + id + '/cancel', null, {
    headers: getHeaders(id)
  })
    .then(function (response: any) {
      dispatch(packageCanceled(response.data));
      dispatch(fetchPackages());
      dispatch(fetchPackageStats());
    })
    .catch(function (error: any) {
      return formatError(error);
    });
};

export const fetchDocument = (id: string, documentId: string): AppThunk => dispatch => {
  axiosInstance.get('/api/packages/' + id + '/documents/' + documentId, {
    headers: getHeaders(id)
  })
    .then(function (response: any) {
      dispatch(documentFetched(DocumentTransformer.transform(response.data)));
    })
    .catch(function (error: any) {
      return formatError(error);
    });
};

export const createDocument = createAsyncThunk(
  'document/create',
  async (payload: { packageId: string, document: { file: any }, onProgress?: any }, thunkAPI) => {
    const {packageId, document, onProgress} = payload;
    let formData = new FormData();
    formData.append('file', document.file);
    const headers = {
      ...getHeaders(payload.packageId),
      'Content-Type': 'multipart/form-data'
    }
    const response = await axiosInstance.post('/api/packages/' + packageId + '/documents', formData, {
      headers,
      onUploadProgress: (uploadProgressEvent: any) => onProgress ? onProgress(uploadProgressEvent) : null
    });
    thunkAPI.dispatch(documentCreated(DocumentTransformer.transform(response.data)));
    return response.data;
  }
);

export const fetchTextFields = (packageId: string, documentId: string): AppThunk => dispatch => {
  axiosInstance.get('/api/packages/' + packageId + '/documents/' + documentId + '/fields', {
    params: {
      fieldType: 1
    },
    headers: getHeaders(packageId)
  })
    .then(function (response: any) {
      dispatch(textFieldsFetched(TextFieldTransformer.transformList(response.data)));
    })
    .catch(function (error: any) {
      return formatError(error);
    });
};

export const fetchSignatureFields = (id: string): AppThunk => dispatch => {
  axiosInstance.get('/api/packages/' + id + '/signature_fields', {
    headers: getHeaders(id)
  })
    .then(function (response: any) {
      dispatch(signatureFieldsFetched(SignatureFieldTransformer.transformList(response.data)));
    })
    .catch(function (error: any) {
      return formatError(error);
    });
};

export const createSignatureField = createAsyncThunk(
  'signature_field/create',
  async (signatureField: { packageId: string, documentId: string, pageId: string, signerId: string, left: number, top: number, width: number, height: number }, thunkAPI) => {
    return await axiosInstance.post('/api/packages/' + signatureField.packageId + '/signature_fields', JSON.stringify(signatureField), {
      headers: getHeaders(signatureField.packageId)
    }).then((response: any) => {
      thunkAPI.dispatch(signatureFieldsCreated(response.data));
      return response.data;
    }).catch((error: any) => {
      return formatError(error);
    });
  }
);

export const updateSignatureField = (signatureField: { packageId: string, id: string, left?: number, top?: number, width?: number, height?: number , imageWidth?: number, imageHeight?: number}): AppThunk => dispatch => {
  axiosInstance.patch('/api/packages/' + signatureField.packageId + '/signature_fields/' + signatureField.id, JSON.stringify(signatureField), {
    headers: getHeaders(signatureField.packageId)
  }).then(function (response: any) {
    dispatch(signatureFieldsUpdated(signatureField));
  }).catch(function (error: any) {
    return formatError(error);
  });
};

export const createTextField = createAsyncThunk(
  'text_field/create',
  async (textField: { packageId: string, documentId: string, pageId: string, signerId: string, left: number, top: number, width: number, height: number, textBody?: string, placeholder?: string }, thunkAPI) => {
    return await axiosInstance.post('/api/packages/' + textField.packageId + '/documents/' + textField.documentId + '/text_fields', JSON.stringify(textField), {
      headers: getHeaders(textField.packageId)
    }).then((response: any) => {
      thunkAPI.dispatch(textFieldsCreated(response.data));
      return response.data;
    }).catch((error: any) => {
      return formatError(error);
    });
  }
);

export const updateTextField = createAsyncThunk(
  'text_fields/update',
  async (textField: { packageId: string, documentId: string, pageId: string, signerId: string, id: string, left: number, top: number, width: number, height: number, textBody?: string, placeholder?: string }, thunkAPI) => {
    return await axiosInstance.patch('/api/packages/' + textField.packageId + '/documents/' + textField.documentId + '/text_fields/' + textField.id, JSON.stringify(textField), {
      headers: getHeaders(textField.packageId)
    }).then(function (response: any) {
      thunkAPI.dispatch(textFieldsUpdated(TextFieldTransformer.transform(response.data)));
    }).catch(function (error: any) {
      return formatError(error);
    });
  }
);

export const deleteSignatureField = (packageId: string, id: string,): AppThunk => dispatch => {
  axiosInstance.delete('/api/packages/' + packageId + '/signature_fields/' + id, {
    headers: getHeaders(packageId)
  })
    .then(function (response: any) {
      dispatch(signatureFieldsDeleted(id));
    })
    .catch(function (error: any) {
      return formatError(error);
    });
};

export const deleteTextField = (packageId: string, documentId: string, id: string,): AppThunk => dispatch => {
  axiosInstance.delete('/api/packages/' + packageId + '/documents/' + documentId + '/fields/' + id, {
    headers: getHeaders(packageId)
  })
    .then(function (response: any) {
      dispatch(textFieldsDeleted(id));
    })
    .catch(function (error: any) {
      return formatError(error);
    });
};


export const fetchSigners = (id: string): AppThunk => dispatch => {
  axiosInstance.get('/api/packages/' + id + '/signers', {
    headers: getHeaders(id)
  })
    .then(function (response: any) {
      dispatch(signersFetched(SignerTransformer.transformList(response.data)));
    })
    .catch(function (error: any) {

      return formatError(error);
    });
};

export const createSigner = createAsyncThunk(
  'signer/create',
  async (signer: { packageId: string, firstName: string, lastName: string, email: string, phoneNumber?: string }, thunkAPI) => {
    return await axiosInstance.post('/api/packages/' + signer.packageId + '/signers', signer, {
      headers: getHeaders(signer.packageId)
    }).then((response: any) => {
      thunkAPI.dispatch(signerCreated(SignerTransformer.transform(response.data)));
      return response.data;
    }).catch((error: any) => {
      return formatError(error);
    });
  }
);

export const deleteSigner = (packageId: string, id: string, documentId: string): AppThunk => dispatch => {
  axiosInstance.delete('/api/packages/' + packageId + '/signers/' + id, {
    headers: getHeaders(packageId)
  })
    .then(function (response: any) {
      dispatch(signerDeleted(id));
      dispatch(fetchSignatureFields(packageId));
      dispatch(fetchTextFields(packageId, documentId));
      dispatch(unsetActiveField());
    })
    .catch(function (error: any) {
      return formatError(error);
    });
};

export const createSignature = createAsyncThunk(
  'signature/create',
  async (payload: { packageId: string, signatureFieldId: string, signature: string }, thunkAPI) => {
    return await axiosInstance.post('/api/packages/' + payload.packageId + '/signatures', payload, {
      headers: getHeaders(payload.packageId)
    }).then((response: any) => {
      const signature = SignatureTransformer.transform(response.data);
      thunkAPI.dispatch(signatureCreated(signature));
      thunkAPI.dispatch(signatureFieldsUpdated(signature.signatureField));
      return response.data;
    }).catch((error: any) => {
      return formatError(error);
    });
  }
);

export const deleteSignature = createAsyncThunk(
  'signature/create',
  async (payload: { packageId: string, signatureId: string }, thunkAPI) => {
    return await axiosInstance.delete('/api/packages/' + payload.packageId + '/signatures/' + payload.signatureId, {
      headers: getHeaders(payload.packageId)
    }).then((response: any) => {
      thunkAPI.dispatch(signatureDeleted(payload.signatureId));
      return response.data;
    }).catch((error: any) => {
      return formatError(error);
    });
  }
);

export const downloadSignature = createAsyncThunk(
  'signature/download',
  async (payload: { packageId: string, signatureId: string }, thunkAPI) => {
    const response = await axiosInstance.get('/api/packages/' + payload.packageId + '/signatures/' + payload.signatureId + '/download', {
      headers: getHeaders(payload.packageId)
    }).catch((error: any) => {
      return formatError(error);
    });

    return response.data ?? '';
  }
);

export const finishSigning = createAsyncThunk(
  'package/finish_signing',
  async (payload: { packageId: string }, thunkAPI) => {
    const response = await axiosInstance.post('/api/packages/' + payload.packageId + '/finish_signing', null, {
      headers: getHeaders(payload.packageId)
    }).catch((error: any) => {
      return formatError(error);
    });

    return response.data ?? '';
  }
);

export const sendVerification = createAsyncThunk(
  'package/send_verification',
  async (payload: { packageId: string }, thunkAPI) => {
    const response = await axiosInstance.post('/api/packages/' + payload.packageId + '/send_verification', null, {
      headers: getHeaders(payload.packageId)
    }).catch((error: any) => {
      return formatError(error);
    });

    return response.data ?? '';
  }
);

export const verifyCode = createAsyncThunk(
  'package/verify',
  async (payload: { packageId: string, code: number }, thunkAPI) => {
    const response = await axiosInstance.post('/api/packages/' + payload.packageId + '/verify', { code: payload.code }, {
      headers: getHeaders(payload.packageId)
    }).catch((error: any) => {
      return formatError(error);
    });

    return response ?? '';
  }
);

export const setActiveField = (id: string): AppThunk => dispatch => {
  dispatch(fieldActivated(id));
};

export const unsetActiveField = (): AppThunk => dispatch => {
  dispatch(fieldDeactivated());
};

export const setCurrentPage = (page: number): AppThunk => dispatch => {
  dispatch(currentPageChanged(page));
};


export const packageSlice = createSlice({
  name: 'packages',
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    packagesFetched: (state, action: PayloadAction<any[]>) => {
      state.packages = action.payload;
    },
    packageStatsFetched: (state, action: PayloadAction<PackageStats>) => {
      state.packageStats = action.payload;
    },
    packageFetched: (state, action: PayloadAction<any>) => {
      state.package = action.payload;
    },
    documentFetched: (state, action: PayloadAction<any>) => {
      state.document = action.payload;
    },
    documentCreated: (state, action: PayloadAction<any>) => {
      state.document = action.payload;
    },
    packageCreated: (state, action: PayloadAction<any>) => {
      state.package = action.payload;

      // when creating an anonymous package, the user uses a temporary token stored in local storage
      const tokenKey = '_packageToken.' + action.payload.id;
      localStorage.setItem(tokenKey, action.payload.anonymousToken);
    },
    packageUpdated: (state, action: PayloadAction<any>) => {
      // list
      const index = state.packages.findIndex(s => s.id === action.payload.id);
      state.packages[index] = {...state.packages[index], ...action.payload};

      // single
      state.package = action.payload;
    },
    packageDeleted: (state, action: PayloadAction<any>) => {
      const index = state.packages.findIndex(s => s.id === action.payload);
      state.packages = [
        ...state.packages.slice(0, index),
        ...state.packages.slice(index + 1)
      ]
    },
    packageCanceled: (state, action: PayloadAction<any>) => {
      const index = state.packages.findIndex(s => s.id === action.payload.id);
      state.packages[index] = {...state.packages[index], ...action.payload};
    },
    signatureFieldsFetched: (state, action: PayloadAction<any[]>) => {
      state.signatureFields = action.payload;
    },
    textFieldsFetched: (state, action: PayloadAction<any[]>) => {
      state.textFields = action.payload;
    },
    signatureFieldsCreated: (state, action: PayloadAction<any>) => {
      const index = state.signatureFields.findIndex(s => s.id === action.payload.id);
      state.signatureFields.push(action.payload);
    },
    textFieldsCreated: (state, action: PayloadAction<any>) => {
      const index = state.textFields.findIndex(s => s.id === action.payload.id);
      state.textFields.push(action.payload);
    },
    signatureFieldsUpdated: (state, action: PayloadAction<any>) => {
      const index = state.signatureFields.findIndex(s => s.id === action.payload.id);
      state.signatureFields[index] = {...state.signatureFields[index], ...action.payload};
    },
    textFieldsUpdated: (state, action: PayloadAction<any>) => {
      const index = state.textFields.findIndex(s => s.id === action.payload.id);
      state.textFields[index] = {...state.textFields[index], ...action.payload};
    },
    signatureFieldsDeleted: (state, action: PayloadAction<any>) => {
      const index = state.signatureFields.findIndex(s => s.id === action.payload);
      state.signatureFields = [
        ...state.signatureFields.slice(0, index),
        ...state.signatureFields.slice(index + 1)
      ]
    },
    textFieldsDeleted: (state, action: PayloadAction<any>) => {
      const index = state.textFields.findIndex(s => s.id === action.payload);
      state.textFields = [
        ...state.textFields.slice(0, index),
        ...state.textFields.slice(index + 1)
      ]
    },
    fieldActivated: (state, action: PayloadAction<string>) => {
      state.activeFieldId = action.payload;
    },
    fieldDeactivated: (state, action: PayloadAction<void>) => {
      state.activeFieldId = ""
    },
    currentPageChanged: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    signersFetched: (state, action: PayloadAction<any[]>) => {
      state.signers = action.payload;
    },
    signerCreated: (state, action: PayloadAction<any>) => {
      state.signers.push(action.payload);
    },
    signerDeleted: (state, action: PayloadAction<any>) => {
      const index = state.signers.findIndex(s => s.id === action.payload);
      state.signers = [
        ...state.signers.slice(0, index),
        ...state.signers.slice(index + 1)
      ]
    },
    signaturesFetched: (state, action: PayloadAction<any[]>) => {
      state.signatures = action.payload;
    },
    signatureCreated: (state, action: PayloadAction<any>) => {
      state.signatures.push(action.payload);
    },
    signatureDeleted: (state, action: PayloadAction<any>) => {
      const index = state.signatures.findIndex(s => s.id === action.payload);
      state.signatures = [
        ...state.signatures.slice(0, index),
        ...state.signatures.slice(index + 1)
      ]
    },
  },
  extraReducers: {
    [createDocument.pending.type]: (state, action) => {
      state.uploading = true;
    },
    [createDocument.fulfilled.type]: (state, action) => {
      state.uploading = false;
    },
    [sendPackageInvites.pending.type]: (state, action) => {
      state.sending = true;
    },
    [sendPackageInvites.fulfilled.type]: (state, action) => {
      state.sending = false;
    }
  }
});

export const {
  packagesFetched,
  packageStatsFetched,
  packageFetched,
  packageCreated,
  packageUpdated,
  packageDeleted,
  packageCanceled,
  documentFetched,
  documentCreated,
  signatureFieldsFetched,
  signatureFieldsUpdated,
  signatureFieldsCreated,
  signatureFieldsDeleted,
  textFieldsFetched,
  textFieldsUpdated,
  textFieldsCreated,
  textFieldsDeleted,
  signatureCreated,
  signatureDeleted,
  fieldActivated,
  fieldDeactivated,
  signersFetched,
  signerCreated,
  signerDeleted,
  signaturesFetched,
  currentPageChanged
} = packageSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.package.value)`
export const selectPackages = (state: RootState) => state.packages;
export const selectPackage = (state: RootState) => state.packages.package;
export const selectDocument = (state: RootState) => state.packages.document;

export const selectSigners = (state: RootState) => state.packages.signers;

export const selectSignatureFields = (state: RootState) => state.packages.signatureFields;
export const selectTextFields = (state: RootState) => state.packages.textFields;

export const selectSignatures = (state: RootState) => state.packages.signatures;

export default packageSlice.reducer;
