import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import styled from 'styled-components/macro';

import {
    fetchPackage,
    selectPackages,
} from '../packageSlice';

import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {FaCheck} from "react-icons/all";
import {Button} from "../../../components/Button";

export function SingingFinished() {
    const packagesState = useSelector(selectPackages);
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {id, token} = useParams();

    useEffect(() => {
        dispatch(fetchPackage({id: id}));
    }, [dispatch, id, token]);

    return (
        <Wrapper>
            <div className={"inner"}>
                <FaCheck className={"graphic"}/>
                <p>{t('thank_you_for_signing')}:</p>
                <p className={"packageName"}>{packagesState?.package?.name}</p>
                <p className={"checkmail"}>{t('you_will_recieve_an_email_with_document')}</p>
                <Button onClick={() => {
                    document.location.href = '/';
                }} className={"primary"} text={t('crosssell')}/>
            </div>
        </Wrapper>
    );
}


const Wrapper = styled.div`  
   font-size: 1.8rem;
   flex-direction: column;   
   text-align: center;
   display: flex;  
   flex: 1;
   align-items: center;
   justify-content: center;  
   
   .inner {
      padding:30px;
   }
   
   .graphic {
      margin-bottom: 40px;
      font-size: 100px;
      color: #B7DB57
   }
   
   .packageName {
      padding-top: 10px;
      font-size: 1.5rem;
      color: #8f8f8f;
   }
   
   .checkmail {
      padding-top: 20px;
      font-size: 1rem;
      max-width: 400px;
   }
   
   .button {
      margin-top: 60px;
      
        @media screen and (max-width: 1000px) {
          display: inline-block;
            max-width: 250px;  
          }
   }
`;
