import React, {memo, useEffect, useRef, useState} from 'react';
import styled from 'styled-components/macro';
import {useDispatch} from "react-redux";
import {requestModalRoute} from '../features/modal/modalSlice';
import {GrClose} from "react-icons/all";

type InputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement>;

interface Props extends InputProps {
    id?: string;
    className?: string;
    title?: string,
    hideClose?: boolean
}

export const Modal = memo(
    ({children, className, title, hideClose, ...restOf}: Props) => {

        const [isVisible, setIsVisible] = useState(false);
        const dispatch = useDispatch();

        useEffect(() => {
            setTimeout(() => {
                setIsVisible(true);
            }, 1);
        }, [])

        const handleContentClick = (e: any) => {
            e.stopPropagation();
        }

        const handleCloseClick = (e: any) => {
            setIsVisible(false);
            setTimeout(() => {
                dispatch(requestModalRoute(null, {}));
            }, 300)
        }

        return (
            <Wrapper className={`${className} ${isVisible ? 'visible' : ''}`}>
                <Content onClick={handleContentClick}>
                    <Header>
                        {title && <h3>{title}</h3>}
                        {!hideClose &&
                            <a className={"close"} onClick={handleCloseClick}>
                                <div className={"background"}></div>
                                <div className={"icon"}><GrClose/></div>
                            </a>
                        }
                    </Header>
                    <Inner>
                        {children}
                    </Inner>
                </Content>
            </Wrapper>
        );
    },
);

const Wrapper = styled.div`
  display: flex;
  background-color: rgb(0,0,0,0.8);
  position: fixed;
  width: 100%;
  height: 100%;  
  left: 0;
  top: 0;
  z-index: 10;
  justify-content: center;
  align-items: center;    
  color: #333;  
  opacity: 0;
  flex-direction: column;
  transition: opacity .3s ease-in-out;  
  
  &.visible {
    opacity: 1;
    animation: bounce .3s ease-in-out;
  }
  
  .close {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all .3s ease-in-out;
    text-align: right;    
    font-size: 18px;
    font-weight: bold;
    background-image: linear-gradient(to right, rgb(53, 42, 180), rgb(98, 84, 255));
    color: white;
    width: 48px;
    height: 48px;
    border-radius: 16px;
    margin-right: -45px;
    margin-top: -45px;
    margin-left: auto;
    fill: white;
    position: relative;
    overflow: hidden;
    
    @media screen and (max-width: 1000px) {
      margin-right: -25px;
      margin-top: -25px;
    }
    
    .background {
      position: absolute;      
      width: 100%;
      height: 100%;
      z-index: 0;
      opacity: 0;
      transition: all .3s ease-in-out;
      background-image: linear-gradient(to left, rgb(53, 42, 180), rgb(98, 84, 255));
    }
    
  
    .icon {
      display: flex;
      flex: 1;
      padding: 15px;
      z-index: 2;  
    }
    
    &:hover {
      @media screen and (min-width: 1000px) {
          .background {
            opacity: 1;
          }
      }
    }
    
    path {
      stroke: white;
    }
    
   
  }
`;

const Header = styled.div`
  font-size: 18px;
  padding: 30px;
  display: flex;
  flex-direction: row;
  
  @media screen and (max-width: 1000px) {
    padding: 15px;
  }
  
  h3 {
    display: flex;
    flex: 1;
  }
`;

const Content = styled.div`
  background-color: white;  
  border-radius: 16px;
  align-items: center;
  justify-content: center;
  flex-direction: column;       
  
  @media screen and (max-width: 1000px) {
    width: 90vw;
  }  
`;

const Inner = styled.div`
    min-width: 500px;
    padding: 0 30px;
    
    @media screen and (max-width: 1000px) {
      min-width: auto;
      
      padding: 0 15px;
    }
`