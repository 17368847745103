import {AxiosError} from "axios";

export const humanFileSize = ((bytes: number, si = true, dp = 1) => {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }

    const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 ** dp;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

    return bytes.toFixed(dp) + ' ' + units[u];
});

export const getRelevantToken  = (packageId?: string) => {
    let tokenKey = '_packageToken.' + packageId

    // force user token when exists, package should be bound to user by now...
    if (localStorage.getItem('_token')) {
        tokenKey = '_token'
    }

    // force signing token for sign url
    if (document.location.href.indexOf('/sign/') !== -1) {
        tokenKey = '_signingToken.' + packageId
    }

    // force signing token for download url
    if (document.location.href.indexOf('/download/') !== -1) {
        tokenKey = '_signingToken.' + packageId
    }

    return localStorage.getItem(tokenKey);
}

export const getHeaders = (packageId?: string, noAuth?: boolean) => {
    let headers = {
        'Content-Type': 'application/json',
    }

    if (!noAuth) {
        const token = getRelevantToken(packageId)

        if (token) {
            return {...headers, Authorization: 'Bearer ' + token}
        }
    }

    return headers;
}

const zeroPrefix = (val: number) => {
    return ("0" + val).slice(-2)
}

export const utcToLocal = (date: any) => {
    let timeOffsetInMS: number = date.getTimezoneOffset() * 60000;
    date.setTime(date.getTime() - timeOffsetInMS);
    return date;
}

export const formatDateForInput = (date: any) => {
    const dateObj = utcToLocal(new Date(date));
    return `${dateObj.getFullYear()}-${zeroPrefix(dateObj.getMonth() + 1)}-${zeroPrefix(dateObj.getDate())}`
}

export const formatDateTime = (date: any) => {
    const dateObj = utcToLocal(new Date(date));
    return `${zeroPrefix(dateObj.getDate())}-${zeroPrefix(dateObj.getMonth() + 1)}-${dateObj.getFullYear().toString().substr(2)} om ${zeroPrefix(dateObj.getHours())}:${zeroPrefix(dateObj.getMinutes())}`
}

export const formatDate = (date: any) => {
    if (!date) {
        return "";
    }
    const dateObj = utcToLocal(new Date(date));
    return `${zeroPrefix(dateObj.getDate())}-${zeroPrefix(dateObj.getMonth() + 1)}-${dateObj.getFullYear().toString().substr(2)}`
}

export const formatError = (error: any) => {

    // validation errors
    if (error?.response?.data?.errors ?? null) {
        return {error: 'validation_error', validation_errors: error.response.data.errors, code: error.response.status}
    }

    // other errors
    if (error?.response?.data?.error ?? null) {
        return {error: error.response.data.error, validation_errors: {}, code: error.response.status}
    }

    // unknown errors
    return {error: error?.response?.status, validation_errors: {}}
}

export const decodeJwt = (token: string) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
}

export const isMobile = (threashold: number = 1000) => {
    return window.outerWidth < threashold;
}
