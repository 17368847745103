import React, {useEffect, useState} from 'react';
import styled from 'styled-components';


interface Props {
    className?: string;
    progress: number;
    label: string
}

export const ProgressBar = (props: Props) => {

    const [width, setWidth] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            setWidth(props.progress);
        })
    }, [props.progress]);

    return (
        <Wrapper className={props.className}>
            <div className={"label"}>{props.label ?? `${props.progress}%`}</div>
            <div className={"progress"} style={{width: `${width}%`}}>
            </div>
        </Wrapper>
    );
}

const Wrapper = styled.div`
  display: flex;
  background-color: #5D5C5C;
  color:white;  
  text-align: center;
  border-radius: 50px;
  overflow: hidden;
  position: relative;  
  height: 25px;
  font-size:12px;
  
  .progress {
    background-color: #6254FF;
    padding: 5px 10px 5px 10px;
    position: absolute;
    height: 100%;
    
    transition: width .6s ease-in-out;   
  }
  
  .label {
    position: relative;
    z-index: 1;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
