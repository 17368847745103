export default class BaseTransformer {

    public static transform(data: any): any {
        // should be overridden in sub class
        return { }
    }

    // makes data object predictable by only transforming values inside sub class transform function
    public static transformList(list: any) {
        if(!list || list.length === 0) {
            return [];
        }
        const transformed: any = [];
        list.map((o: any) => {
            return transformed.push(this.transform(o));
        });
        return transformed;
    }
}