import React, {memo} from 'react';
import styled from 'styled-components/macro';
import {useDispatch, useSelector} from "react-redux";
import {logout, selectUser} from "../features/user/userSlice";
import {modalRoutes, requestModalRoute} from "../features/modal/modalSlice";
import {FaUserCircle} from "react-icons/all";

interface Props {
    id: string;
    className?: string;
}

export const ProfileAvatar = memo(({id, className, ...restOf}: Props) => {
    const userState = useSelector(selectUser);
    const dispatch = useDispatch();

    const handleMenuClick = () => {
        dispatch(requestModalRoute(modalRoutes.userMenu, {hideClose: false}));
    }

    const handleChangePasswordClick = () => {
        dispatch(requestModalRoute(modalRoutes.setPassword, {hideClose: false}));
    }

    const handleCreateLongLivedTokenClick = () => {
        dispatch(requestModalRoute(modalRoutes.createLongLivedToken, {hideClose: false}));
    }

    const handleRegisterClick = (e: any) => {
        e.preventDefault();
        dispatch(requestModalRoute(modalRoutes.register, {}));
    }

    const handleLogoutClick = () => {
        dispatch(logout());
        document.location.href = '/';
    }

    return <Wrapper className={className}>
        {userState.currentUser ? <>
                <span className={"avatar"} onClick={handleMenuClick}>
                    {userState?.currentUser?.firstName.substr(0, 1)}
                    {userState?.currentUser?.lastName.substr(0, 1)}
                </span>
                {/*
                <div className={"menu"}>
                    <h3>{userState?.currentUser?.firstName} {userState?.currentUser?.lastName}</h3>
                    <ul>
                        <li onClick={handleLogoutClick}>Uitloggen</li>
                        <li onClick={handleCreateLongLivedTokenClick}>Token aanmaken</li>
                        <li onClick={handleChangePasswordClick}>Wachtwoord wijzigen</li>
                    </ul>
                </div>
                */}
        </> : <FaUserCircle className={"register"} onClick={handleRegisterClick} />}
    </Wrapper>;
});

const Wrapper = styled.div` 

  .avatar {
      background-color: #6254FF;
      color: white;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      border-radius: 50%;
      width: 36px;
      height: 36px;  
      margin: 0 0 0 20px;
      cursor:pointer;
   }
 
  
  .menu {
      position: absolute;
      background-color: white;
      visibility: hidden;
      opacity: 0;      
      width: 200px;
      margin-left: -190px;
      margin-top: -40px;
      border-radius: 16px;
      font-size: 1rem;
      color: #333;
      border: 1px solid white;
      transition: all .3s ease-in-out;
      z-index: 10;
      
      h3 {
        font-size: 1rem;
        font-weight: bold;
        padding: 10px 10px 0 10px;
      }
      
      ul, li {
        list-style: none;
        margin:0;
        padding:10px 5px 10px 5px;        
        cursor: pointer;               
      }
      
      li {
        &:hover {
          color:#bbb;
        }
      }
  }
  
  /*
  &:hover {
    .menu {
      opacity: 1;
      visibility: visible;
      transform: translate(-10px, 0);
    }
  }
  */
  
  .register {
    font-size: 1.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 15px;
    cursor: pointer;
    
    color: #6254FF;
  }
`;
