import React, {memo, useEffect, useState} from 'react';
import styled from 'styled-components/macro';
import {useTranslation} from "react-i18next";
import 'react-phone-number-input/style.css'
import PhoneInputWithCountrySelect from "react-phone-number-input";
import nl from './phone_input_translations/nl.json';

type InputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement>;

interface Props extends InputProps {
    className?: string;
    placeholder?: string;
    value?: string;
    validationError?: string,
    onChange: (value: any) => any;
    onReturn?: () => any;
    showValidationErrors?: boolean;
}

export const PhoneInput = memo(
    ({className, onChange, placeholder, value, onReturn, validationError, showValidationErrors, ...restOf}: Props) => {

        const {t} = useTranslation();
        const [valueState, setValueState] = useState(value ?? '');

      useEffect(() => {
        onChange("+31");
      }, [])

        const onKeyDown = (e: any) => {
            if (e.which === 13) {
                typeof onReturn === "function" && onReturn();
            }
        }

        const handleChange = (value: string) => {
            setValueState(value);
            onChange(value)
        }

        if (showValidationErrors === undefined) {
            showValidationErrors = true;
        }

        return (
            <Wrapper className={validationError ? `${className} has-error` : className}>
                  <div className={"inner"}>
                    <div className={"spacer"}/>
                    <PhoneInputWithCountrySelect
                      labels={nl}
                      onChange={handleChange}
                      international={true}
                      withCountryCallingCode={true}
                      placeholder={placeholder}
                      defaultCountry={"NL"}
                      countryCallingCodeEditable={false}
                    />
                  </div>
                {(validationError && showValidationErrors) && <div className={"error"}>{t(validationError)}</div>}
            </Wrapper>
        );
    },
);

const Wrapper = styled.div`
   input:focus, textarea:focus, select:focus{
        outline: none;        
    }

  .inner {
      display: flex;
      flex: 1;
      background-color: rgb(245, 245, 245);
      border: 1px solid rgb(221, 221, 221);
      border-radius: 50px;
      
      .icon {
        display:flex;
        flex: 0 0 40px;
        align-items: center;
        justify-content: center;    
        z-index: 2;
        color: #333;
      }
      
      .spacer {
        display:flex;
        flex: 0 0 10px;
      }
     
      input {   
        padding: 10px;
        color: rgb(33, 33, 33);    
        line-height: 24px;    
        box-shadow: none;
        outline: none;
        font-size:.9rem;
        background-color:transparent;
        border: 0;
        flex: 1;
      }
      
      input[type=number]::-webkit-inner-spin-button, 
        input[type=number]::-webkit-outer-spin-button {  
        
           opacity: 1;
        
        }
      
        input:-webkit-autofill,
        input:-webkit-autofill:hover, 
        input:-webkit-autofill:focus, 
        input:-webkit-autofill:active  {
            -webkit-box-shadow: 0 0 0 30px rgb(245, 245, 245) inset !important;
        }
  }
    
  &.has-error {
    .inner {
      border:1px solid red;
      background-color: #ffc9c9;
    }
  }
   
  .error {
    padding-top: 5px;
    display: block;
    color: #f52e2e;
  }
  
  .PhoneInput {
    width: 100%;
    .PhoneInputCountry {
      margin-left: 4px;
      
      .PhoneInputCountrySelectArrow {
        margin-left: 10px;
      }
    }
  }
`;
