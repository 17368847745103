import React, {useState} from 'react';
import styled from 'styled-components/macro';

import {useSelector} from "react-redux";
import {
    selectPackages,
    createSigner,
    createSignatureField,
    fetchSignatureFields, fieldActivated, createTextField, fetchTextFields
} from "../../packageSlice";

import {IconInput} from "../../../../components/IconInput";
import * as constants from "../../../../constants";
import {useTranslation} from "react-i18next";
import {Button} from "../../../../components/Button";
import {unwrapResult} from "@reduxjs/toolkit";
import {useAppDispatch} from "../../../../app/store";
import {useToasts} from "react-toast-notifications";
import {modalRoutes, requestModalRoute, selectModal} from "../../../modal/modalSlice";
import {selectUser} from "../../../user/userSlice";
import {fieldAction} from "./AddSigner";
import {PhoneInput} from "../../../../components/PhoneInput";

export function CreateSignerForm() {
    const packagesState = useSelector(selectPackages);
    const userState = useSelector(selectUser);
    const modalState = useSelector(selectModal);
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const {addToast} = useToasts();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");

    const initialValidationState = {
        firstName: [],
        lastName: [],
        email: [],
        phoneNumber: [],
    };
    const [validationErrors, setValidationErrors] = useState(initialValidationState);

    const handleFirstNameChange = (e: any) => {
        setFirstName(e.target.value);
    }

    const handleLastNameChange = (e: any) => {
        setLastName(e.target.value);
    }

    const handleEmailChange = (e: any) => {
        setEmail(e.target.value);
    }

    const handlePhoneChange = (value: string) => {
        setPhone(value);
    }

    const handleSubmit = () => {
        const signer = {
            packageId: packagesState.package.id,
            firstName: firstName,
            lastName: lastName,
            email: email,
            phoneNumber: phone ? phone : undefined,
        };
        // create the signer
        dispatch(createSigner(signer)).then(unwrapResult).then(result => {
            if (result.error) {
                result.error === 'validation_error' ? setValidationErrors({...initialValidationState, ...result.validation_errors}) :
                    addToast(t(result.error), {appearance: 'error', autoDismiss: true});
                return;
            }

            setValidationErrors(initialValidationState);

            // create the signature field
            if (modalState.modalRouteParams.action === fieldAction.sign) {
                dispatch(createSignatureField({
                    packageId: packagesState.package.id,
                    documentId: packagesState.document.id,
                    signerId: result.id,
                    top: modalState.modalRouteParams.top,
                    left: modalState.modalRouteParams.left,
                    pageId: modalState.modalRouteParams.pageId,
                    width: constants.signatureFieldWidth,
                    height: constants.signatureFieldHeight
                })).then(unwrapResult).then(result => {
                    dispatch(fetchSignatureFields(packagesState.package.id));
                    dispatch(fieldActivated(result.id));
                })
            } else {
                return dispatch(requestModalRoute(modalRoutes.write, {
                    packageId: packagesState.package.id,
                    documentId: packagesState.document.id,
                    pageId: modalState.modalRouteParams.pageId,
                    signerId: result.id,
                    top: modalState.modalRouteParams.top,
                    left: modalState.modalRouteParams.left,
                    isCreator: true,
                    width: constants.textFieldWidth,
                    height: constants.textFieldHeight,
                    field: modalState.modalRouteParams.field
                }));
            }


            dispatch(requestModalRoute(null, null));
        })
    }

    const emailMatchesCurrentUser = () => {
        return email.trim().toLowerCase() === userState?.currentUser?.email?.trim().toLocaleLowerCase()
    }

    return (
        <Wrappper>
            <div className={"name"}>
                <div className={"formgroup"}>
                    <IconInput className={"firstname"} required={true} value={firstName}
                               validationError={validationErrors.firstName[0]}
                               placeholder={t('first_name')}
                               showValidationErrors={true}
                               onChange={handleFirstNameChange}/>
                </div>
                <div className={"formgroup"}>
                    <IconInput className={"lastname"} required={true} value={lastName}
                               validationError={validationErrors.lastName[0]}
                               showValidationErrors={true}
                               placeholder={t('last_name')}
                               onChange={handleLastNameChange}/>
                </div>
            </div>
            <div className={"email"}>
                <div className={"formgroup"}>
                    <IconInput className={"email"} value={email} type={"email"}
                               validationError={validationErrors.email[0]}
                               showValidationErrors={true}
                               placeholder={t('emailaddress')}
                               onChange={handleEmailChange}/>
                </div>
                {emailMatchesCurrentUser() &&
                <div className={"help"}>{t('email_matches_current_user')}</div>
                }
            </div>
            {packagesState.package.phoneVerificationRequired &&
              <div className={"phone"}>
                  <div className={"formgroup"}>
                      <PhoneInput
                        onChange={handlePhoneChange}
                        className={"phone"}
                        value={phone}
                        validationError={validationErrors.phoneNumber[0]}
                        showValidationErrors={true}
                        placeholder={t('phone_number')}
                      />
                  </div>
              </div>
            }
            <div className={"actions"}>
                {!emailMatchesCurrentUser() &&
                <Button text={t('Toevoegen')} className={"button primary"} onClick={handleSubmit}/>
                }
            </div>
        </Wrappper>
    );
}


const Wrappper = styled.div`      
    flex: 0 0 450px;    
    padding-bottom: 20px;    
    min-width: 600px;
    
    @media screen and (max-width: 1000px) {
      min-width: auto;
    }
    
    h1 {      
      padding-bottom: 15px;
      font-size: 22px;
      font-weight: normal;      
      margin-top: -30px;
    }
  
  .help {
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff;
    
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem;
  }
  
  .name {
    display: flex;
    flex-direction: row;
   
    
    .formgroup {
      &:first-child {
        padding-right: 5px;
      }
      &:last-child {
        padding-left: 10px;
      }
    }
    
    
    @media screen and (max-width: 1000px) {
      flex-direction: column;
      
      .formgroup {
        &:first-child {
          padding-right: 0;
        }
        &:last-child {
          padding-left: 0;
        }
      }
    }
  }
  
  
  .formgroup {      
      flex: 1;  
      padding-bottom: 20px;
  }
  
  label {
    font-size: 0.9rem;
    padding-bottom: 4px;
    display: inline-block;
  }
    
  h5 {
    font-size: 0.9rem;
    padding-bottom: 10px;
  }
  
  .actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    
    
    .button {
      flex: 0 0 calc(50% - 20px);
    }
  }
`;
