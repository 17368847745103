import React, {memo, useEffect, useState} from 'react';
import styled from 'styled-components/macro';
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {packageStatuses} from "../features/package/packageStatuses";
import {useAppDispatch} from "../app/store";
import {cancelPackage, deletePackage, fetchPackage} from "../features/package/packageSlice";
import {unwrapResult} from "@reduxjs/toolkit";
import {ProgressBar} from "../components/ProgressBar"
import {Button} from "./Button";
import {FaPause, GoTrashcan, GrStopFill} from "react-icons/all";
import {config} from "../app/config";
import {formatDateTime} from "../helpers";

interface Props {
  pkg: any;
}

export const PackageRow = memo(
  ({pkg, ...restOf}: Props) => {

    const history = useHistory();
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const [progress, setProgress] = useState(0)
    const [totalRemaining, setTotalRemaining] = useState(0)

    useEffect(() => {
      setProgress(((pkg.signatureCount + pkg.textBodyCount) / (pkg.signatureFieldCount + pkg.textFieldCount)) * 100);
      setTotalRemaining((pkg.signatureFieldCount + pkg.textFieldCount) - (pkg.signatureCount + pkg.textBodyCount));
    }, [pkg])

    const handleClick = () => {
      if (pkg.documentCount === 0) {
        return;
      }

      dispatch(fetchPackage({id: pkg.id}))
        .then(unwrapResult)
        .then(result => {
          if (result?.error) {
            if (result.code === 401) {
              history.go(0);
            }
          } else if (pkg.status === packageStatuses.Draft) {
            history.push(`/packages/${pkg.id}/documents/${result.documents[0].id}/compose`);
          } else {
            history.push(`/packages/${pkg.id}/documents/${result.documents[0].id}/details`);
          }
        });
    }

    const handleDeleteClick = () => {
      dispatch(deletePackage(pkg.id))
    }


    const handleStopClick = () => {
      dispatch(cancelPackage(pkg.id))
    }

    const previewUrl = config.api_base_url + "/api/packages/" + pkg.id + "/preview?token=" + pkg.previewToken;

    return (
      <Wrapper onClick={() => handleClick()} className={"package"}>
        <div className={"preview"}>
          <img src={previewUrl}/>
        </div>

        <div className={"details"}>
          <div className={"top"}>
            <div className={"left"}>
              <h2 className={"name"}>{pkg.name}</h2>
              <div className={"document"}>
                {/*<div>{pkg.documentCount} documenten</div>*/}
                <div className={"signerCount"}>{pkg.signerCount} ondertekenaars</div>
                <div className={"created"}><span>{t('creation_date')}</span> {formatDateTime(pkg.createdAt)}</div>
              </div>
            </div>
            <div className={"actions"}>
              <Button
                className={"button action delete"}
                confirm={true}
                onClick={pkg.status === packageStatuses.InProgress ? handleStopClick : handleDeleteClick}
                icon={pkg.status === packageStatuses.InProgress ? <GrStopFill/> : <GoTrashcan/>}
                confirmQuestion={pkg.status === packageStatuses.InProgress ? t('confirm_stop_package') : t('confirm_delete_package')}
                confirmText={t('delete')}
                cancelText={t('cancel')}
              />
            </div>
          </div>
          {(pkg.textFieldCount > 0 || pkg.signatureFieldCount > 0) &&
              <ProgressBar className={"progressbar"}
                           label={(pkg.status === packageStatuses.Signed || pkg.status === packageStatuses.Completed) ? t('package_finished') : totalRemaining === 0 ? t('waiting_for_submit') : t('signatures_remaining', {count: totalRemaining})}
                           progress={progress}/>
          }
        </div>
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`  
  display: flex;  
  cursor:pointer;
  background-color: rgba(55,55,55,0.7);
  padding: 15px;  
  border-radius: 16px;
  transition: all .3s ease-in-out;
  flex-direction: row;
  color:white;
  
  @media screen and (max-width: 1000px) {
    .signerCount {
      display: none;
    }
    .created {
        @media screen and (max-width: 1000px) {
          font-size: 12px;
        }
      span {
        display: none;
      }
    }
  }
  
  .actions {
    transition: opacity .2s ease-in-out;
    opacity: 0;
  }
  
  @media screen and (min-width: 1000px) {
      &:hover {
         background-color: rgba(69,69,69,0.8);
         
         .actions {
            opacity: 1;
         }
      }
  }
  
  .preview {
    img {
      background-color: white;
      width: 100px;
      height: 140px;
      border-radius: 8px;
      
      @media screen and (max-width: 1000px) {
        width: 60px;
        height: 90px;
      }
    }
  }
  
  .details {
    display: flex;
    flex: 1;
    flex-direction: column;    
    color:black;
    padding-left: 20px;
    justify-content: center;
    
    .top {
      display: flex;
      flex-direction: row;
      
      .left {
        flex: 1;
      }
      
      .actions {
        margin-top: -25px;
      }
    }
    
    .name {
        font-weight: bold;        
        font-size: 18px;
        padding-bottom: 6px;
        color:white;        
        
        @media screen and (max-width: 1000px) {
          font-size: 16px;
        }
    }
    
    .document {
      color: #c8c7c7;
    } 
    
    .progressbar {
      margin-top: 10px;
    }
  }
  
  .actions {
    .button {      
      border-radius: 50px;
      width: 40px;
      
      .inner {
        padding: 10px;
      }
      
      .icon {      
        font-size: 20px;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        
        path {
          stroke: white;
          fill: white;
        }
      }
      
      background-color: rgba(114,114,114,.5);
      border: 0;
    }
  } 
`;
