import React from 'react';

import {useTranslation} from "react-i18next";
import {useAppDispatch} from "../../../../app/store";
import {useSelector} from "react-redux";
import {createSignatureField, createTextField, selectPackages} from "../../packageSlice";
import {selectUser} from "../../../user/userSlice";
import {SearchBar} from "../../../../components";
import {modalRoutes, requestModalRoute, selectModal} from "../../../modal/modalSlice";
import {FaPlus, FaUser} from "react-icons/all";
import styled from "styled-components/macro";
import * as constants from "../../../../constants";

export const fieldAction = {
    sign: 'sign',
    text: 'text'
}

export function AddSigner() {
    const dispatch = useAppDispatch();
    const modalState = useSelector(selectModal);
    const packagesState = useSelector(selectPackages);
    const userState = useSelector(selectUser);
    const {t} = useTranslation();

    const signatureFieldWidth = constants.signatureFieldWidth;
    const signatureFieldHeight = constants.signatureFieldHeight;

    const textFieldWidth = constants.textFieldWidth;
    const textFieldHeight = constants.textFieldHeight;

    const handleSearchChange = () => {

    }

    const handleAddNewPersonClick = () => {
        // pass the top and left params to next modal
        dispatch(requestModalRoute(modalRoutes.createSigner, {
            top: modalState.modalRouteParams.top,
            left: modalState.modalRouteParams.left,
            pageId: modalState.modalRouteParams.pageId,
            action: modalState.modalRouteParams.action,
        }))
    }

    const handleAddPersonClick = (id: string) => {

        const params = {
            packageId: packagesState.package.id,
            documentId: packagesState.document.id,
            pageId: modalState.modalRouteParams.pageId,
            signerId: id,
            top: modalState.modalRouteParams.top,
            left: modalState.modalRouteParams.left,
            imageWidth: modalState.modalRouteParams.imageWidth,
            imageHeight: modalState.modalRouteParams.imageHeight,
            isCreator: true,
            isMe: false
        }

        if (modalState.modalRouteParams.action === fieldAction.sign) {
            // create the signature field
            dispatch(createSignatureField({
                ...params,
                width: signatureFieldWidth,
                height: signatureFieldHeight,
            }));
        } else {
            return dispatch(requestModalRoute(modalRoutes.write, {
                ...params,
                width: textFieldWidth,
                height: textFieldHeight,
                field: modalState.modalRouteParams.field
            }));
        }

        dispatch(requestModalRoute(null, null));
    }

    const handleAddMeClick = () => {
        const params = {
            packageId: packagesState.package.id,
            documentId: packagesState.document.id,
            pageId: modalState.modalRouteParams.pageId,
            top: modalState.modalRouteParams.top,
            left: modalState.modalRouteParams.left,
            isCreator: true,
            isMe: true
        }

        if (modalState.modalRouteParams.action === fieldAction.sign) {
            dispatch(requestModalRoute(modalRoutes.sign, {
                ...params,
                width: signatureFieldWidth,
                height: signatureFieldHeight,
            }));
        } else {
            dispatch(requestModalRoute(modalRoutes.write, {
                ...params,
                width: textFieldWidth,
                height: textFieldHeight,
                field: modalState.modalRouteParams.field
            }));
        }
    }

    return (
        <Wrapper>
            <SearchBar id="searchbar" placeholder={t("search_for_documents")} onChange={handleSearchChange}/>

            <div className={"options"}>
                <div className={"option"} onClick={handleAddNewPersonClick}>
                    <FaPlus className={"icon"}/>
                    <p>Persoon&nbsp;</p>
                    <p>toevoegen</p>
                </div>

                {userState?.currentUser?.id ?
                    <div className={"option"} onClick={() => handleAddMeClick()}>
                        <FaUser className={"icon"}/>
                        <p>Ik zelf</p>
                        <p>{userState?.currentUser?.firstName && `(${userState.currentUser.firstName})`}</p>
                    </div>
                    : null}

                {packagesState.signers.map((signer: any) => {
                    // filter out the user. User must use the SignForm
                    if (signer.email === userState?.currentUser?.email) {
                        return null;
                    }
                    return (
                        <div
                            onClick={() => handleAddPersonClick(signer.id)}
                            className={"option"}
                            key={signer.id}
                        >
                            <FaUser className={"icon"}/>
                            <p>{signer.firstName}</p>
                            <p>{signer.lastName}</p>
                        </div>
                    )
                })}
            </div>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    flex: 0 0 450px;   
    min-width: 600px;
    padding-bottom: 10px;
    
    @media screen and (max-width: 1000px) {
      min-width: auto;
    }

    h1 {
      padding-bottom: 15px;
      font-size: 22px;
      font-weight: normal;
      margin-top: -30px;
    }

    .options {
      display: grid;
      grid-template-columns: repeat(auto-fill,minmax(200px, 1fr));
      margin: 10px -15px 0px -15px;
      
      @media screen and (max-width: 1000px) {
          max-height: 50vh;
          overflow-y: scroll;
      }

      .option {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        background-color: #eee;
        height: 100px;
        margin: 15px;
        font-size: 18px;
        padding: 15px;
        cursor: pointer;
        transition: all .2s ease-in-out;
             
        &:hover {
          background-color: #ddd;
        }


        .icon {
          margin-bottom: 20px;
        }
        
        @media screen and (max-width: 1000px) {
          flex-direction: row;
          height: 25px;
          margin-bottom: 5px;
          justify-content: flex-start;
          
          .icon {
            margin: 0 10px 0 0;
          }
        }

      }
    }

`;
