import React, {memo, useEffect, useState} from 'react';
import styled from 'styled-components/macro';
import {useTranslation} from "react-i18next";
import {FaCheckCircle, FaDownload, FaUserCircle, HiRefresh} from "react-icons/all";
import {fetchPackage, selectPackages} from "../features/package/packageSlice";
import {useSelector} from "react-redux";
import {useAppDispatch} from "../app/store";
import {ProgressBar} from "./ProgressBar";
import {packageStatuses} from "../features/package/packageStatuses";
import {config} from "../app/config";
import {Button} from "./Button";
import {useParams} from "react-router-dom";
import {modalRoutes, requestModalRoute} from "../features/modal/modalSlice";

type InputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement>;

interface Props extends InputProps {
    className?: string;
}

export const PackageDetailPanel = memo(
    ({className, ...restOf}: Props) => {

        const packagesState = useSelector(selectPackages);
        const dispatch = useAppDispatch();
        const {t} = useTranslation();
        const [progress, setProgress] = useState(0)
        const [totalRemaining, setTotalRemaining] = useState(0)

        useEffect(() => {
          const signatureCount = packagesState?.signatures?.length;
          const textFieldFilledCount = packagesState?.textFields?.filter((f: any) => {
            return f.textBody != null;
          })?.length;
          const signatureFieldCount = packagesState?.signatureFields?.length;
          const textFieldCount = packagesState?.textFields?.length;

            setProgress(((signatureCount + textFieldFilledCount) / (signatureFieldCount + textFieldCount)) * 100);
            setTotalRemaining((signatureFieldCount + textFieldCount) - (signatureCount + textFieldFilledCount));

        }, [packagesState.signatures, packagesState.signatureFields, packagesState.textFields])

        const handleDownload = (e: any) => {
            e.preventDefault();
            if(packagesState.package.status !== packageStatuses.Completed) {
                renderStillGeneratingModal();
                dispatch(fetchPackage({id: packagesState.package.id}));
                return;
            }
            const url = config.api_base_url + "/api/packages/" + packagesState.package.id + "/documents/" +
                packagesState.document.id + "/download?token=" + packagesState.document.previewToken;
            window.open(url)
        }

        const renderStillGeneratingModal = () => {
            dispatch(requestModalRoute(modalRoutes.customButtons, {
                description: t('document_still_generating'),
                buttons: [
                    {
                        text: 'OK',
                        onClick: () => {
                            dispatch(requestModalRoute(null, null));
                        }
                    },
                ]
            }));
        }

        const handleDownloadAudit = (e: any) => {
            e.preventDefault();
            if(packagesState.package.status !== packageStatuses.Completed) {
                renderStillGeneratingModal();
                dispatch(fetchPackage({id: packagesState.package.id}));
                return;
            }

            const url = config.api_base_url + "/api/packages/" + packagesState.package.id + "/audit?token=" + packagesState.package.packageObjectToken;
            window.open(url)
        }

        const signaturesRemaining = (signerId: string): any => {
            const fieldCount = packagesState?.signatureFields?.reduce((n, v) => {
                return n + (v.signer.id === signerId);
            }, 0);
            const signatureCount = packagesState?.signatures?.reduce((n, v) => {
                return n + (v.signer.id === signerId);
            }, 0);
            const textFieldCount = packagesState?.textFields.reduce((n, v) => {
              return n + (v.textBody === null && v.signer.id === signerId);
            }, 0)

            return (fieldCount - signatureCount) + textFieldCount;
        }

        return (
            <Wrapper className={className}>
                {(packagesState?.package?.status !== packageStatuses.Completed && packagesState?.package?.status !== packageStatuses.Signed) &&
                <div className={"status"}>
                    <div className={"field title"}>
                        <label>{t('status')}</label>
                    </div>
                    <ProgressBar className={"progressbar"} label={t('signatures_remaining', {count: totalRemaining})}
                                 progress={progress}/>
                </div>
                }
                <div className={"signers"}>
                    {packagesState?.signers.map((signer: any) => {
                        const remaining = signaturesRemaining(signer.id);

                        return (<div className={"signer"} key={signer.id}>
                            <div className={"icon"}>{signer.signedAt ? <FaCheckCircle className={"signed"}/> :
                                <FaUserCircle/>}
                            </div>
                            <div className={"info"}>
                                <div className={"name"}>{signer.firstName} {signer.lastName}</div>
                                <div className={"status"}>
                                    {remaining > 0
                                        ? <>{t('signatures_remaining', {remaining})}</>
                                        : <>
                                            <div className={"text"}>{t('all_signatures_placed')}</div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>)
                    })}
                </div>

                <div className={"details"}>
                    <div className={"field title"}>
                        <label>{t('details')}</label>
                    </div>
                    <div className={"field"}>
                        <label>{t('expiration_date')}:</label>
                        <span>{new Date(packagesState?.package?.expiresAt).toLocaleDateString()}</span>
                    </div>
                    <div className={"field"}>
                        <label>{t('creation_date')}:</label>
                        <span>{new Date(packagesState?.package?.createdAt).toLocaleDateString()}</span>
                    </div>
                    {packagesState?.package?.canceledAt &&
                        <div className={"field"}>
                            <label>{t('cancel_date')}:</label>
                            <span>{new Date(packagesState?.package?.canceledAt).toLocaleDateString()}</span>
                        </div>
                    }
                </div>

                {(packagesState?.package?.status === packageStatuses.Completed || packagesState?.package?.status === packageStatuses.Signed) &&
                    <div className={"actions"}>
                        <Button icon={<FaDownload/>} className={"primary"} onClick={handleDownload} text={t('download')}/>
                        <br/>
                        <Button icon={<FaDownload/>}  onClick={handleDownloadAudit} text={t('transactionlog')}/>
                    </div>
                }


            </Wrapper>
        );
    },
);

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  
   
  .title {
    padding: 0 0 15px 0 !important;
    
    label {
        font-weight: bold;            
    }
  }
  
  >.status {
    padding-bottom: 20px;
  }
  
  .signers {
      padding: 0 0 20px 0;    
      
      @media screen and (max-width: 1000px) {
        display: none;  
      }
  
      .signer {
          background-color: rgba(74,74,74,.5);
          border-radius: 8px;
          padding: 15px;
          margin-bottom: 15px;
          
          display: flex;
          flex: 1;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          
          .icon {
            font-size: 40px;
            padding: 0 16px 0 0;
            display: flex;
            align-items: center;
            justify-content: center;            
          }                 
          .info {
            flex: 1;
            line-height: 1.2rem;
            
            .name {
              font-weight: bold;
            }
          }  
          .status {
              display: flex;
              align-items: center;     
          }
          
      }
  }
 
  
  .details {
      background-color: rgba(74,74,74,.5);
      border-radius: 8px;
      padding: 15px; 
      
      @media screen and (max-width: 1000px) {
        display: none;    
      }
  }
  

  .field {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0 10px 0;
    
    &:last-child {
      padding: 0;
    }
    
    label {
      display: flex;
      flex: 1;
    }
    
    span {
      flex: 0 0 50%;
    }       
  }
  
  .actions {
    padding-top: 30px;
    
    @media screen and (max-width: 1000px) {
      flex-direction: row;
      display: flex;
      overflow: hidden;
      padding-top: 15px;
      
      .button {
        .icon {
          display: none;
        }
        .text {
          margin: auto;
        }
      }
      
      .button:first-child {
        margin-right:8px;
      }
      .button:last-child {
        margin-left:8px;
      }
    }
    
    .help {
      background: white;
      border-radius: 8px;
      margin: 0 0 20px 0;
      padding:15px;
      color:#333;
    }
  }
`;
