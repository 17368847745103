import React, {useEffect, useState} from 'react';
import styled from 'styled-components/macro';
import {SignatureField} from './SignatureField';
import {useSelector, useDispatch} from "react-redux";
import {
  deleteSignature,
  deleteSignatureField,
  deleteTextField,
  selectPackages,
  updateSignatureField
} from "../../packageSlice";
import {modalRoutes, requestModalRoute} from '../../../modal/modalSlice';
import {setActiveField} from '../../packageSlice';
import {isMobile} from "../../../../helpers";
import {Page as PdfPage} from "react-pdf";
import {TextField} from "./TextField";
import {fieldAction} from "./AddSigner";

interface Props {
  mode: string,
  page: any,
  width: number,
  height: number,
  renderPreview: boolean,
  marginBottom: number,
  scale: number,
  signatureFields: any[],
  textFields: any[],

  boundsChanged(id: string, width: number, height: number, left: number, top: number, action: string, pageId?: string, textData?: string, signerId?: string, imageWidth?: number, imageHeight?: number): any;
}

export function Page(props: Props) {
  const packagesState = useSelector(selectPackages);
  const dispatch = useDispatch();
  const [previewLoaded, setPreviewLoaded] = useState(false);
  const [mouseDownPosition, setMouseDownPosition] = useState({x: 0, y: 0});

  useEffect(() => {
    if (props.renderPreview) {
      setPreviewLoaded(true);
    }
  }, [props.renderPreview])

  const handleBoundsChanged = (id: string, width: number, height: number, left: number, top: number, action: string, textData?: string, signerId?: string, imageWidth?: number, imageHeight?: number) => {
    props.boundsChanged(
      id,
      width / props.scale / props.width * 100,
      height / props.scale / props.height * 100,
      left / props.scale / props.width * 100,
      top / props.scale / props.height * 100,
      action,
      props.page.id,
      textData,
      signerId,
      imageWidth ? imageWidth / props.scale / props.width * 100 : undefined,
      imageHeight ? imageHeight / props.scale / props.height * 100 : undefined
    );
  }

  const handleImageLoaded = (field: any, imageWidth: number, imageHeight: number) => {
    dispatch(updateSignatureField({
      packageId: packagesState.package.id,
      id: field.id,
      width: field.width,
      height: field.height,
      top: field.top,
      left: field.left,
      imageWidth: imageWidth / props.scale / props.width * 100,
      imageHeight: imageHeight / props.scale / props.height * 100
    }));
  }

  const handleFieldClick = (id: string) => {
    dispatch(setActiveField(id));
  }

  const handleOnClick = (e: any) => {
    if (packagesState.package.phoneVerificationRequired === null) {
      return dispatch(requestModalRoute(modalRoutes.phoneVerification, {hideClose: false}));
    }

    const target = e.target;
    const rect = target.getBoundingClientRect();

    // Mouse position
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    // in percentages
    const top = y / rect.height * 100;
    const left = x / rect.width * 100;

    dispatch(requestModalRoute(modalRoutes.addField, {top: top, left: left, pageId: props.page.id}));
  }

  const handleDeleteFieldClick = async (id: string, documentId?: string, action?: string) => {
    if (!action || action === fieldAction.sign) {
      const oldSignature = packagesState.signatures.find(s => s.signatureField.id === packagesState.activeFieldId);
      if (oldSignature) {
        await dispatch(deleteSignature({packageId: packagesState.package.id, signatureId: oldSignature.id}))
      }
      dispatch(deleteSignatureField(packagesState.package.id, id));
    } else if (action === fieldAction.text && documentId) {
      await dispatch(deleteTextField(packagesState.package.id, documentId, id));
    }
  }

  const handleMouseDown = (e: any) => {
    setMouseDownPosition({x: e.clientX, y: e.clientY});
  }

  const handleMouseUp = (e: any) => {
    // prevent click while dragging
    if (e.clientX === mouseDownPosition.x && e.clientY === mouseDownPosition.y) {
      handleOnClick(e);
    }
  }

  const pageStyle = {
    width: props.width * props.scale,
    height: props.height * props.scale,
    marginBottom: props.marginBottom,
  };

  return (
    <Wrapper style={pageStyle} onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}>
      {(props.renderPreview || previewLoaded) &&
          <PdfPage
              pageNumber={props.page.pageNr + 1}
              width={pageStyle.width}
              height={pageStyle.height}
              key={props.page.pageNr}
              className={"page"}
              renderTextLayer={false}
              renderMode={"canvas"}
          />
      }

      {props.signatureFields.map((field: any) => {
          return (
            <SignatureField
              key={field.id}
              field={field}
              mode={props.mode}
              active={field.id === packagesState.activeFieldId}
              width={props.width / 100 * (field.width * props.scale)}
              height={props.height / 100 * (field.height * props.scale)}
              left={props.width / 100 * (field.left * props.scale)}
              top={props.height / 100 * (field.top * props.scale)}
              boundsChanged={handleBoundsChanged}
              imageLoaded={handleImageLoaded}
              onClick={handleFieldClick}
              showDelete={!isMobile()}
              onDeleteClick={handleDeleteFieldClick}
              imageHeight={field.imageWidth}
              imageWidth={field.imageHeight}
            />
          );
        }
      )}

      {props.textFields.map((field: any) => {
          return (
            <TextField
              key={field.id}
              field={field}
              mode={props.mode}
              active={field.id === packagesState.activeFieldId}
              width={props.width / 100 * (field.width * props.scale)}
              height={props.height / 100 * (field.height * props.scale)}
              left={props.width / 100 * (field.left * props.scale)}
              top={props.height / 100 * (field.top * props.scale)}
              boundsChanged={handleBoundsChanged}
              onClick={handleFieldClick}
              showDelete={!isMobile()}
              onDeleteClick={handleDeleteFieldClick}
            />
          );
        }
      )}
    </Wrapper>
  );
}


const Wrapper = styled.div`    
  position: relative;
  margin: 0 auto;   
  overflow:hidden;
  -webkit-box-shadow: 0 0 10px -3px rgba(0,0,0,0.59); 
  box-shadow: 0 0 10px -3px rgba(0,0,0,0.59);
  background-color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  cursor: crosshair;
  
  .page, canvas {
      width: 100%;
      height: 100%;
  }
  
  img.background-image {
    width:100%;
    -webkit-user-drag: none;
 
      -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
           -moz-user-select: none; /* Old versions of Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
                user-select: none; /
  }
`;
