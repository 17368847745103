import React, {memo} from 'react';
import styled from 'styled-components/macro';
import {useTranslation} from 'react-i18next';

import {ProfileAvatar} from '.'
import {useSelector} from "react-redux";
import {selectUser} from "../features/user/userSlice";
import {Logo} from "../components/Logo";
import {modalRoutes, requestModalRoute} from "../features/modal/modalSlice";
import {useAppDispatch} from "../app/store";
import {Link, useHistory} from "react-router-dom";

interface Props {
    className?: string;
}

export const TopBar = memo(({className, ...restOf}: Props) => {
    const {t} = useTranslation();
    const userState = useSelector(selectUser);
    const dispatch = useAppDispatch();
    const history = useHistory();

    const handleLoginClick = (e: any) => {
        e.preventDefault();
        dispatch(requestModalRoute(modalRoutes.login, {}));
    }

    return (
        <Wrapper className={className}>
            <Logo/>
            <Actions>
                <a className={"action hidemobile"} onClick={() => {
                    history.push('/about-us')
                }}>{t('Over ons')}</a>
                <a className={"action hidemobile"} onClick={() => {
                    history.push('/developers')
                }}>{t('Developers')}</a>

                {userState.currentUser ?
                    <Link className={"action"} to="/packages">{t('my_packages')}</Link>
                    :
                    <a href={""} className={"action"} onClick={handleLoginClick}>{t('login')}</a>
                }

            </Actions>
            <ProfileAvatar id="profile-avatar"/>
        </Wrapper>
    );
});

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    padding: 0 0 20px 0;
    align-items: center;
    //position: fixed;
    //width: calc(100vw - 80px);    

    @media screen and (max-width: 1000px) {
        padding: 0 0 10px 0;
    }
`;


const Actions = styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 15px 0 15px 15px;

    a {
        cursor: pointer;
        transition: color .2s ease-in-out;

        :hover {
            color: #d2d0d0;
        }
    }


    .action {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 1rem;
        font-weight: bold;
        margin-left: 30px;

        @media screen and (max-width: 1000px) {
            &.hidemobile {
                display: none;
            }
        }
    }

    .register {
        color: rgb(98, 84, 255);
        font-size: 2.25rem;
        cursor: pointer;
        margin-left: 20px;

        &:hover {
            animation: pulse 2s infinite;
            animation-timing-function: linear;
        }
    }
`;

