import React, {useState} from 'react';
import styled from 'styled-components/macro';

import {TopBar} from "../../../components/TopBar";

import {DropZone} from "../../../components/DropZone";
import {isMobile} from "../../../helpers";
import {Footer} from "../../../components/Footer";
import {useSelector} from "react-redux";
import {selectUser} from "../../user/userSlice";
import {Button} from "../../../components/Button";

export function Home() {
    const [uploading, setUploading] = useState(false);



    return (
        <Wrapper>
            <TopBar/>

            <Middle>
                <MegaTron className={uploading && !isMobile() ? 'uploading' : ''}>
                    <h1>Stap over op de nieuwe Legalsign</h1>
                    <br/>
                    <p>
                        Deze versie van Legalsign is binnenkort niet meer beschikbaar. <br/>
                        Om gebruik te blijven maken van Legalsign, dien je overstappen naar de nieuwe versie.<br/>
                        De nieuwe Legalsign zit vol met nieuwe features en verbeteringen.<br/>
                        <br/>

                        <Button text={"Stap nu over"} className={"primary"} onClick={() => window.location.href = "https://app.legalsign.app"}/>

                    </p>
                </MegaTron>
            </Middle>
            <Footer/>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 20px 30px 20px 30px;
    
    @media screen and (max-width: 1000px) {
      padding: 10px 15px 5px 15px;
    }
`;

const Middle = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: center;
    align-items: center;   
    padding: 0 30px 0 30px; 
    
    @media screen and (max-width: 1000px) {
      flex-direction: column;
      padding: 0;
      
      .dropzoneWrapper {
        width: 250px;
        height: 350px;
      }
    }
`;

const MegaTron = styled.div`
    display: flex;    
    flex-direction: column;    
    justify-content: center;
    padding: 25px 75px;
    transition: all .3s ease-in-out;
    width: 800px;    
    text-align: center;
    
    @media screen and (max-width: 1000px) {
      width: initial;          
      overflow: visible;
      white-space: initial;            
      text-align: center;
      
    }
    
    h1 {
      font-size: 1.5rem;
    }
    
    p {
      font-size: 18px;
      
      @media screen and (max-width: 1000px) {
        display: none;
      }
    }
    
    &.uploading {      
      animation: fadeToRight 1s ease-in-out forwards;          
    }
`;
