import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import translationEN from "./locales/en/translation.json";
import translationNL from "./locales/nl/translation.json";
// the translations
// (tip move them in a JSON file and import them)
const resources = {
    en: {
        translation: translationEN
    },
    nl: {
        translation: translationNL
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "nl",
        fallbackLng: "en",
        debug: false,

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
