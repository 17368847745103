import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppThunk, RootState} from '../../app/store';
import UserTransformer from "../../transformers/UserTransformer";
import {config} from "../../app/config";
import {formatError, getHeaders} from '../../helpers';
import SignerTransformer from "../../transformers/SignerTransformer";

const axios = require('axios').default;

interface Customer {
    id: string;
}

interface User {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    customer: Customer | null;
    validatedAt: string;
    totalFreePackages: number;
    createdAt: string;
    updatedAt: string;
}

interface Signer {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    signedAt: string;
    phoneVerifiedAt?: string;
    phoneNumber?: string;
}

interface UserState {
    currentUser: User | null;
    currentSigner: Signer | null; // @todo: move to separate state or packages state?
    registerSuccess: boolean,
    token: string | null;
    email: string;
}

const initialState: UserState = {
    currentUser: null,
    currentSigner: null,
    registerSuccess: false,
    token: null,
    email: '',
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        currentUserFetched: (state, action: PayloadAction<any>) => {
            state.currentUser = action.payload; // @todo pass trough transformer
        },
        currentUserDeleted: (state, action: PayloadAction<any>) => {
            state.currentUser = null;
        },
        currentSignerFetched: (state, action: PayloadAction<any>) => {
            state.currentSigner = action.payload;  // @todo pass trough transformer
        },
        userRegistered: (state, action: PayloadAction<any>) => {
            state.email = action.payload.email;
        },
        userValidated: (state, action: PayloadAction<any>) => {
          if (action.payload.token) {
            localStorage.setItem('_token', action.payload.token);
          }
        },
        tokenFetched: (state, action: PayloadAction<any>) => {
            if (action.payload.token) {
              localStorage.setItem('_token', action.payload.token);
            }
          },
        validateRequestRequested: (state, action: PayloadAction<any>) => {
            state.email = action.payload;
        },
        userLoggedOut: (state, action: PayloadAction<any>) => {
            state.currentUser = action.payload;
            // clean local storage
            for (const key in localStorage) {
                localStorage.removeItem(key)
            }
        },
    },
});

export const {currentUserFetched} = userSlice.actions;
export const {currentUserDeleted} = userSlice.actions;
export const {currentSignerFetched} = userSlice.actions;
export const {userRegistered} = userSlice.actions;
export const {userValidated} = userSlice.actions;
export const {tokenFetched} = userSlice.actions;
export const {validateRequestRequested} = userSlice.actions;
export const {userLoggedOut} = userSlice.actions;


// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched


const axiosInstance = axios.create({
    baseURL: config.api_base_url,
    timeout: 20000,
    headers: getHeaders()
});

const axiosNoAuthInstance = axios.create({
  baseURL: config.api_base_url,
  timeout: 20000,
  headers: getHeaders(undefined, true)
});

export const fetchCurrentUser = createAsyncThunk(
    'user/me',
    async (payload: void, thunkAPI) => {
        return await axiosInstance.get('/api/auth/me', {
            headers: getHeaders()
        }).then((response: any) => {
            thunkAPI.dispatch(currentUserFetched(UserTransformer.transform(response.data)));
            return response.data;
        }).catch((error: any) => {
            return false;
        });
    }
);

export const deleteCurrentUser = createAsyncThunk(
    'user/delete_current',
    async (payload: void, thunkAPI) => {
        thunkAPI.dispatch(currentUserDeleted);
        return true;
    }
);

export const fetchCurrentSigner = createAsyncThunk(
    'user/me',
    async (payload: { packageId: string }, thunkAPI) => {
        return await axiosInstance.get('/api/packages/' + payload.packageId + '/signers/me', {
            headers: getHeaders(payload.packageId)
        }).then((response: any) => {
            thunkAPI.dispatch(currentSignerFetched(SignerTransformer.transform(response.data)));
            return response.data;
        }).catch((error: any) => {
            return formatError(error);
        });
    }
);


export const registerUser = createAsyncThunk(
    'user/register',
    async (payload: { firstName: string, lastName: string, email: string }, thunkAPI) => {
        return await axiosInstance.post('/api/auth/register', payload).then((response: any) => {
            thunkAPI.dispatch(userRegistered(UserTransformer.transform(response.data)));
            return response.data;
        }).catch((error: any) => {
            return formatError(error);
        });
    }
);

export const validateUser = createAsyncThunk(
    'user/validate',
    async (payload: { email: string, validationCode: string }, thunkAPI) => {
        return axiosInstance.post('/api/auth/validate', payload).then((response: any) => {
            thunkAPI.dispatch(userValidated(response.data));
            return response.data;
        }).catch((error: any) => {
            return formatError(error);
        });
    }
);

export const validateRequestUser = createAsyncThunk(
    'user/validate_request',
    async (payload: { email: string }, thunkAPI) => {
        return axiosInstance.post('/api/auth/validate_request', payload).then((response: any) => {
            thunkAPI.dispatch(validateRequestRequested(payload.email));
            return response.data;
        }).catch((error: any) => {
            return formatError(error);
        });
    }
);

export const updateUser = createAsyncThunk(
    'user/update',
    async (payload: { id: string, password?: string, firstName?: string, lastName?: string }, thunkAPI) => {
        return await axiosInstance.patch('/api/users/' + payload.id, payload, {
            headers: getHeaders()
        }).then((response: any) => {
            return response.data;
        }).catch((error: any) => {
            return formatError(error);
        });
    }
);


export const fetchToken = createAsyncThunk(
    'user/token',
    async (payload: { email: string, password: string, trustedClient: boolean }, thunkAPI) => {
        return await axiosNoAuthInstance.post('/api/auth/token', payload).then((response: any) => {
            thunkAPI.dispatch(tokenFetched(response.data));
            return response.data;
        }).catch((error: any) => {
            return formatError(error);
        });
    }
);


export const logout = (): AppThunk => dispatch => {
    dispatch(userLoggedOut(null));

    Object.keys(localStorage).map((key: string) => {
        return localStorage.getItem(key);
    });
};

export const fetchLongLivedToken = createAsyncThunk(
  'user/long_lived_token',
  async () => {
    return await axiosInstance.get('/api/auth/long_lived_token', {
      headers: getHeaders()
    }).then((response: any) => {
      // thunkAPI.dispatch(tokenFetched(response.data));
      return response.data;
    }).catch((error: any) => {
      return formatError(error);
    });
  }
);



// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.package.value)`
export const selectUser = (state: RootState) => state.user;

export const selectCurrentUser = (state: RootState) => state.user.currentUser;

export default userSlice.reducer;
