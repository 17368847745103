import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components/macro';

import {selectUser, validateUser} from '../userSlice';
import {modalRoutes, requestModalRoute} from '../../modal/modalSlice';
import {DigitInput} from "../../../components/DigitInput";
import {useTranslation} from "react-i18next";
import {useAppDispatch} from "../../../app/store";
import {unwrapResult} from "@reduxjs/toolkit";
import {useToasts} from "react-toast-notifications";
import {bindPackage, selectPackages} from "../../package/packageSlice";

export function ValidateForm() {
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const userState = useSelector(selectUser);
    const packagesState = useSelector(selectPackages);
    const {addToast} = useToasts();

    const initialValidationState = {
        code: [],
    };

    const [validationErrors, setValidationErrors] = useState(initialValidationState);
    const [code, setCode] = useState('');


    const handleCodeChange = (value : any) => {
        setCode(value);

        if(value.trim().length !== 6) {
            return;
        }

        dispatch(validateUser({
            email: userState.email,
            validationCode: value.trim(),
        })).then(unwrapResult).then(result => {
            if (result.error) {
                setCode('');
                result.error === 'validation_error' ? setValidationErrors({...initialValidationState, ...result.validation_errors}) :
                    addToast(t(result.error), {appearance: 'error', autoDismiss: true});
            } else {
                // claim anonymous package
                if(packagesState?.package?.id) {
                    dispatch(bindPackage({
                        id: packagesState.package.id,
                        anonymousToken: localStorage.getItem('_packageToken.' + packagesState.package.id) ?? ''
                    }));
                }

                dispatch(requestModalRoute(modalRoutes.setPassword, {hideClose: true}))
            }
        })

    };

    return (
        <Wrapper>
            <p>{t('validation_help')}</p>

            <form>
                <div className={"code"}>
                    <DigitInput onChange={handleCodeChange} value={code}/>
                    {validationErrors.code[0] && <div className={"error"}>{t(validationErrors.code[0])}</div>}
                </div>
            </form>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    flex: 0 0 300px;
    @media screen and (max-width: 1000px) {
      flex: 1;    
    }
    padding-bottom: 15px;
  
    .code {
      padding:20px 0 0 0;
    }
    
    .error {
        padding-top: 5px;
        display: block;
        color: #f52e2e;
        text-align: center;
        font-size: 1rem;
      }  
`;
