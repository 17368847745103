import React, {memo} from 'react';
import styled from "styled-components/macro";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";

export const Footer = memo(({}) => {
    const {t} = useTranslation();
    const history = useHistory();

    return (
        <>
            <StyledFooter>
                Copyright &copy; {new Date().getFullYear()} - Legalsign
                <Dot />
                <StyledLink onClick={() => {history.push('/terms-of-service')}}>{t('Terms of Service')}</StyledLink>
                <Dot />
                <StyledLink onClick={() => {history.push('/privacy-policy')}}>{t('Privacy Policy')}</StyledLink>
            </StyledFooter>
        </>
    );
});

const StyledFooter = styled.div`    
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;        
    padding: 30px 0;
    font-size: .8rem;
`;

const Dot = styled.div`
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  margin-left: 7px;
  margin-top: 2px;
  background-color: white;
`;

const StyledLink = styled.a `
  margin-left: 7px;
  cursor: pointer;
  font-size: .88rem;
`;
