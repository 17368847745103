import React, {memo, useEffect, useRef} from 'react';
import styled from 'styled-components/macro';

import * as THREE from "three";

interface Props {
    className: string;
    boost: number;
}

export const HyperSpace = memo(({className, boost, ...restOf}: Props) => {

    const canvasRef = useRef(null);

    useEffect(() => {
        let scene: any;
        let camera: any;
        let renderer: any;
        let stars: any;
        let starGeo: any;

        function init() {

            scene = new THREE.Scene();

            camera = new THREE.PerspectiveCamera(60, window.innerWidth / window.innerHeight, 1, 1000);
            camera.position.z = 1;
            camera.rotation.x = Math.PI / 2;

            renderer = new THREE.WebGLRenderer({
                antialias: false,
                canvas: canvasRef.current ?? new HTMLCanvasElement(),
                alpha: true
            });
            renderer.setSize(window.innerWidth, window.innerHeight);
            //document.body.appendChild(renderer.domElement);

            starGeo = new THREE.Geometry();
            for (let i = 0; i < 6000; i++) {
                let star : any = new THREE.Vector3(
                    Math.random() * 600 - 300,
                    Math.random() * 600 - 300,
                    Math.random() * 600 - 300
                );
                starGeo.vertices.push(star);
            }

            let sprite = new THREE.TextureLoader().load('/img/star.png');
            let starMaterial = new THREE.PointsMaterial({
                color: 0xaaaaaa,
                size: 0.4,
                map: sprite
            });

            stars = new THREE.Points(starGeo, starMaterial);
            scene.add(stars);

            window.addEventListener("resize", onWindowResize, false);

            animate();
        }

        function onWindowResize() {
            camera.aspect = window.innerWidth / window.innerHeight;
            camera.updateProjectionMatrix();
            renderer.setSize(window.innerWidth, window.innerHeight);
        }

        function animate() {
            starGeo.vertices.forEach((p : any)  => {
                p.y -= 1 * boost;

                if (p.y < -200) {
                    p.y = 200;
                }
            });
            starGeo.verticesNeedUpdate = true;
            stars.rotation.y += 0.002;


            renderer.render(scene, camera);
            requestAnimationFrame(animate);
        }

        init();
    });

    // useEffect(() => {
    //
    //     /**
    //      * Utility function for returning a random integer in a given range
    //      * @param {Int} max
    //      * @param {Int} min
    //      */
    //     const randomInRange = (max, min) =>
    //         Math.floor(Math.random() * (max - min + 1)) + min
    //     const BASE_SIZE = .8
    //     const VELOCITY_INC = 1.01
    //     const VELOCITY_INIT_INC = 1.025
    //
    //     const SIZE_INC = 1.005
    //     const RAD = Math.PI / 180
    //     const WARP_COLORS = [
    //         [197, 239, 247],
    //         [25, 181, 254],
    //         [77, 5, 232],
    //         [165, 55, 253],
    //         [255, 255, 255],
    //     ]
    //     /**
    //      * Class for storing the particle metadata
    //      * position, size, length, speed etc.
    //      */
    //     class Star {
    //         STATE = {
    //             alpha: Math.random(),
    //             angle: randomInRange(0, 360) * RAD,
    //         }
    //         reset = () => {
    //             const angle = randomInRange(0, 360) * (Math.PI / 180)
    //             const vX = Math.cos(angle)
    //             const vY = Math.sin(angle)
    //             const travelled =
    //                 Math.random() > 0.5
    //                     ? Math.random() * Math.max(window.innerWidth, window.innerHeight) + (Math.random() * (window.innerWidth * 0.24))
    //                     : Math.random() * (window.innerWidth * 0.25)
    //             this.STATE = {
    //                 ...this.STATE,
    //                 iX: undefined,
    //                 iY: undefined,
    //                 active: travelled ? true : false,
    //                 x: Math.floor(vX * travelled) + window.innerWidth / 2,
    //                 vX,
    //                 y: Math.floor(vY * travelled) + window.innerHeight / 2,
    //                 vY,
    //                 size: BASE_SIZE,
    //             }
    //         }
    //         constructor() {
    //             this.reset()
    //         }
    //     }
    //
    //     const generateStarPool = size => new Array(size).fill().map(() => new Star())
    //
    //     class JumpToHyperspace {
    //         STATE = {
    //             stars: generateStarPool(300),
    //             bgAlpha: 0,
    //             sizeInc: SIZE_INC,
    //             velocity: VELOCITY_INC
    //         }
    //         canvas = document.getElementById('hyperspace')
    //         context = this.canvas.getContext('2d')
    //         constructor() {
    //             this.setup();
    //             this.render();
    //         }
    //         render = () => {
    //             const {
    //                 STATE: {
    //                     bgAlpha,
    //                     velocity,
    //                     sizeInc,
    //                     initiating,
    //                     stars,
    //                 },
    //                 context,
    //                 render
    //             } = this
    //             // Clear the canvas
    //             context.clearRect(0, 0, window.innerWidth, window.innerHeight)
    //             if (bgAlpha > 0) {
    //                 context.fillStyle = `rgba(31, 58, 157, ${bgAlpha})`
    //                 context.fillRect(0, 0, window.innerWidth, window.innerHeight)
    //             }
    //             // 1. Shall we add a new star
    //             const nonActive = stars.filter(s => !s.STATE.active)
    //             if (!initiating && nonActive.length > 0) {
    //                 // Introduce a star
    //                 nonActive[0].STATE.active = true
    //             }
    //             // 2. Update the stars and draw them.
    //             for (const star of stars.filter(s => s.STATE.active)) {
    //                 const { active, x, y, iX, iY, iVX, iVY, size, vX, vY } = star.STATE
    //                 // Check if the star needs deactivating
    //                 if (
    //                     ((iX || x) < 0 ||
    //                         (iX || x) > window.innerWidth ||
    //                         (iY || y) < 0 ||
    //                         (iY || y) > window.innerHeight) &&
    //                     active &&
    //                     !initiating
    //                 ) {
    //                     star.reset(true)
    //                 } else if (active) {
    //                     const newIX = initiating ? iX : iX + iVX
    //                     const newIY = initiating ? iY : iY + iVY
    //                     const newX = x + vX
    //                     const newY = y + vY
    //                     // Just need to work out if it overtakes the original line that's all
    //                     const caught =
    //                         (vX < 0 && newIX < x) ||
    //                         (vX > 0 && newIX > x) ||
    //                         (vY < 0 && newIY < y) ||
    //                         (vY > 0 && newIY > y)
    //                     star.STATE = {
    //                         ...star.STATE,
    //                         iX: caught ? undefined : newIX,
    //                         iY: caught ? undefined : newIY,
    //                         iVX: caught ? undefined : iVX * VELOCITY_INIT_INC,
    //                         iVY: caught ? undefined : iVY * VELOCITY_INIT_INC,
    //                         x: newX,
    //                         vX: star.STATE.vX * velocity,
    //                         y: newY,
    //                         vY: star.STATE.vY * velocity,
    //                         size: initiating ? size : size * (iX || iY ? SIZE_INC : sizeInc),
    //                     }
    //                     context.strokeStyle = `rgba(255, 255, 255, ${star.STATE.alpha})`
    //                     context.lineWidth = size
    //                     context.beginPath()
    //                     context.moveTo(star.STATE.iX || x, star.STATE.iY || y)
    //                     context.lineTo(star.STATE.x, star.STATE.y)
    //                     context.stroke()
    //                 }
    //             }
    //             requestAnimationFrame(render)
    //         }
    //         setup = () => {
    //             this.context.lineCap = 'round'
    //             this.canvas.height = window.innerHeight
    //             this.canvas.width = window.innerWidth
    //         }
    //         reset = () => {
    //             this.STATE = {
    //                 ...this.STATE,
    //                 stars: generateStarPool(300)
    //             }
    //             this.setup()
    //         }
    //     }
    //     window.myJump = new JumpToHyperspace()
    //
    //     let resizeTimer = null;
    //
    //     window.addEventListener(
    //         'resize',
    //         () => {
    //             resizeTimer = setTimeout(() => {
    //                 clearTimeout(resizeTimer);
    //                 window.myJump.reset()
    //             }, 250);
    //         }
    //     );
    //
    // }, [])
    //

    return (<Wrapper>
        <canvas id={"hyperspace"} ref={canvasRef} className={className}></canvas>
    </Wrapper>);
});

const Wrapper = styled.div`
    canvas {
      position: absolute;
      top:0;
      left:0;
      z-index: -1;
    }
`;