import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components/macro';
import {useTranslation} from "react-i18next";
import {useAppDispatch} from "../../../../app/store";
import {fetchCurrentSigner, selectUser} from "../../../user/userSlice";
import {fetchPackage, finishSigning, selectPackages, sendVerification, verifyCode} from "../../packageSlice";
import {DigitInput} from "../../../../components/DigitInput";
import {Button} from "../../../../components/Button";
import {FaPaperPlane} from "react-icons/all";
import {unwrapResult} from "@reduxjs/toolkit";
import {requestModalRoute} from "../../../modal/modalSlice";
import {useToasts} from "react-toast-notifications";
import {useHistory} from "react-router-dom";

export function VerifyForm() {
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  const {addToast} = useToasts();
  const userState = useSelector(selectUser);
  const packagesState = useSelector(selectPackages);

  const initialValidationState = {
    code: [],
  };

  const [validationErrors, setValidationErrors] = useState(initialValidationState);
  const [code, setCode] = useState('');
  const [showButton, setShowButton] = useState(true);
  const [sending, setSending] = useState(false);
  const history = useHistory();

  const sendText = () => {
    setSending(true);

    dispatch(sendVerification({
      packageId: packagesState.package.id,
    })).then(unwrapResult).then(result => {
      setSending(false);
      if (!result.error) {
        setShowButton(false);
      }
    });
  }

  const handleCodeChange = (value : string) => {
    setCode(value);

    if(value.trim().length !== 6) {
      return;
    }

    dispatch(verifyCode({
      packageId: packagesState.package.id,
      code: parseInt(value),
    })).then(unwrapResult).then(result => {
      if (result.error) {
        addToast(t(result.error), {appearance: 'error', autoDismiss: true});
        return;
      }

      dispatch(fetchCurrentSigner({
        packageId: packagesState.package.id,
      }));

      dispatch(requestModalRoute(null, {hideClose: true}));

      if (isFinished()) {
        dispatch(finishSigning({
          packageId: packagesState.package.id,
        })).then(unwrapResult).then(result => {
          history.push('/packages/' + packagesState.package.id + '/finished/' + localStorage.getItem('_signingToken'));
        });
        return;
      }
    });
  }

  const isFinished = () => {
    return packagesState.signatures.length === packagesState.signatureFields.length && packagesState?.textFields?.filter((f: any) => {
      return f.textBody === null && f.signerId === userState.currentSigner?.id;
    }).length === 0;
  }

  return (
    <Wrapper>
      <HelpWrapper>
        <p>{t('verify_help')}</p>
        {showButton &&
            <Button
                icon={<FaPaperPlane/>}
                className={"primary"}
                onClick={sendText}
                text={(t('send_text') + " " + userState.currentSigner?.phoneNumber)}
                disabled={sending}
            />
        }
      </HelpWrapper>
      <form>
        <div className={"code"}>
          <DigitInput onChange={handleCodeChange} value={code}/>
          {validationErrors.code[0] && <div className={"error"}>{t(validationErrors.code[0])}</div>}
        </div>
      </form>
    </Wrapper>
  );
}

const HelpWrapper = styled.div`
    justify-content: center;
    align-items: center;
    flex: 1;
    display: flex;
    flex-direction: column;
    
    p {
      margin-bottom: 15px;
    }
    
    .button {
      display: inline-flex;
      
      .inner .text {
        margin-left: 10px;
        margin-right: 30px;
      }
    }
`;

const Wrapper = styled.div`
    flex: 0 0 300px;
    @media screen and (max-width: 1000px) {
      flex: 1;    
    }
    padding-bottom: 15px;
  
    .code {
      padding:20px 0 0 0;
    }
    
    .error {
        padding-top: 5px;
        display: block;
        color: #f52e2e;
        text-align: center;
        font-size: 1rem;
      }  
`;
