import BaseTransformer from "./BaseTransformer";

export default class SignerTransformer extends BaseTransformer {
    public static transform(data: any): any {
        return {
            id: data.id,
            packageId: data.packageId,
            firstName: data.firstName,
            signedAt: data.signedAt ?? null,
            lastName: data.lastName,
            email: data.email,
            phoneNumber: data.phoneNumber,
            phoneVerifiedAt: data.phoneVerifiedAt ?? null,
        }
    }
}