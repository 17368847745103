import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import styled from 'styled-components/macro';

import {
    fetchPackages, fetchPackageStats,
    selectPackages,
} from '../packageSlice';

import {PackageRow, SearchBar, TopBar, UploadButton} from '../../../components'
import {PackageFilter} from "./PackageFilter";
import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router-dom";

export function PackageList() {
    const packagesState = useSelector(selectPackages);
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {status} = useParams();
    const history = useHistory();

    useEffect(() => {
        dispatch(fetchPackages({status: status}))
        dispatch(fetchPackageStats())
    }, [dispatch, status]);


    const handleSearchChange = (query: string) => {
        dispatch(fetchPackages({status: status ?? '', query: query ?? ''}))
    }

    return (
        <Wrapper>
            <TopBar/>

            <MidSection>

                <Details>

                    <Title>
                        <h1>{t('my_documents')}</h1>
                        <SearchBar id="searchbar" placeholder={t("search_for_documents")} onChange={handleSearchChange}/>
                        <UploadButton loading={packagesState.uploading}/>
                    </Title>

                    <PackageFilter className={"filter"}/>

                    {packagesState.packages.length ?
                        <PackageRowList>
                            <div className={"inner"}>

                                {packagesState.packages.map((p) =>
                                    <PackageRow key={p.id} pkg={p}/>
                                )}


                            </div>
                        </PackageRowList> :
                        <NoPackages>
                            {status === undefined ? t('no_packages_yet') : t('no_packages_by_filter')}
                        </NoPackages>
                    }


                </Details>
            </MidSection>
        </Wrapper>
    );
}

const Wrapper = styled.div` 
  display:flex;
  flex-direction: column;
  flex: 1;
  padding: 20px 30px 20px 30px;
  
  @media screen and (max-width: 1000px) {
    padding: 10px 15px 5px 15px;    
    
    #searchbar {
      display: flex;
      flex: 1;
      
      width: 100%;
      >div {
        width:100%;
      }
      
      .inner {
        width: 100%;
      }
    }
  }
  
  #sidenav {
    @media screen and (max-width: 1000px) {
      display: none;
    }
  }
  
  .filter {
    padding-top: 20px;
    
    @media screen and (max-width: 1000px) {
      display: none;
    }
  }

`;

const MidSection = styled.div`
  display:flex;
  flex-direction:row;
  flex: 1;
  padding-top: 25px;
  
  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
`;

const LeftPanel = styled.div`
  display: flex;  
  padding: 0 30px 0 0;
  flex-direction: column;
  
   @media screen and (max-width: 1000px) {
      display: none;
   }
   
   .top {
      flex: 1;
   }
  
  .account {
    display: flex;
    flex: 1;
    flex-direction: column;
    
    .progressbar {
      margin: 15px 0 10px 0; 
    }
    
    font-size: 12px;
  }
  .bottom {
    line-height: 24px;
  } 
`;


const Title = styled.div`
  display: flex;   
  align-items: center;
  flex-direction: row;
  
  
  @media screen and (max-width: 1000px) {
    .button {
       margin-left: 20px;     
    }
  }
  
   h1 {       
    color:white;    
    display: flex;
    flex: 1;
    
   @media screen and (max-width: 1000px) {        
      display: none;
   }
  }
  
  input {
    transition: width 0.3s ease-in-out;
    width: 200px;
    
    @media screen and (max-width: 1000px) {
      width: auto;
    }
  }
  
  input:focus {
      width: 400px;
      
      @media screen and (max-width: 1000px) {
        width: auto;
      }
  }
`;

const Details = styled.div`
  display:flex;
  flex-direction: column;
  flex: 1;  
  background-color: rgba(50,50,50,.5);
  padding: 30px;  
  border-radius: 32px;
  color:white;
  
  @media screen and (max-width: 1000px) {
    padding: 15px;
  }
`;

const PackageRowList = styled.div`
  flex: 1;
  margin-top:20px;
  overflow-y: auto; 
  padding-right: 20px;
  
  @media screen and (max-width: 1000px) {
    padding-right: 0;  
  }
    
    /* width */
    ::-webkit-scrollbar {
      width: 10px;
    }
    
    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #888;
    }
    
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
    
  
  .inner {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: start;
    grid-column-gap: 30px;
    grid-row-gap: 30px;   
    
    @media screen and (max-width: 1300px) {
      grid-template-columns: 1fr;
    }
  }
`;


const NoPackages = styled.div`
  font-size: 1.2rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  color: #bbb;
  padding: 20px;
`;

const StyledLink = styled.a `
  cursor: pointer;
`;

const Dot = styled.div`
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  margin-left: 7px;
  margin-top: 2px;
  background-color: white;
`;