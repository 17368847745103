import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components/macro';

import {useParams} from "react-router-dom";

import {
    fetchPackage,
    finishSigning,
    selectPackages,
    fetchSignatureFields,
    unsetActiveField,
    fetchSigners,
    bindPackage,
    fetchTextFields,
    selectSigners,
    selectSignatureFields,
    selectTextFields,
    selectPackage, selectDocument
} from '../packageSlice';

import {useTranslation} from "react-i18next";
import {Signers} from "./components/Signers";
import {Button} from "../../../components/Button";
import {BackButton} from "../../../components/BackButton";
import {FaDownload, RiSendPlaneFill} from "react-icons/all";
import {modalRoutes, requestModalRoute} from "../../modal/modalSlice";

import {DocumentFieldEditor, modes} from "./components/DocumentFieldEditor";
import {useAppDispatch} from "../../../app/store";
import {unwrapResult} from "@reduxjs/toolkit";
import {useHistory} from "react-router";
import {humanFileSize} from "../../../helpers";
import {TopBar} from "../../../components";
import {selectCurrentUser, selectUser} from "../../user/userSlice";
import {Settings} from "./components/Settings";

export function ComposePackage() {
    const packagesState = useSelector(selectPackages);
    const packageObject = useSelector(selectPackage);
    const documentObject = useSelector(selectDocument);
    const signers = useSelector(selectSigners);
    const signatureFields = useSelector(selectSignatureFields);
    const textFields = useSelector(selectTextFields);
    const currentUser = useSelector(selectCurrentUser);
    const history = useHistory();
    const dispatch = useAppDispatch();
    const {id, documentId} = useParams();
    const {t} = useTranslation();

    useEffect(() => {
        getPackage().then((res: any) => {
            if (res.payload?.error) {
                dispatch(requestModalRoute(modalRoutes.login, {}));
            } else {
                dispatch(fetchSignatureFields(id));
                dispatch(fetchTextFields(id, documentId));
                // dispatch(fetchDocument(id, documentId));
                dispatch(fetchSigners(id));
            }
        });
    }, [dispatch, id, documentId, currentUser]);

    const getPackage = async () => {
        return await dispatch(fetchPackage({id: id}));
    }

    const handleInviteClick = () => {
        dispatch(unsetActiveField());

        if (localStorage.getItem('_token')) {
            if (!packageObject.user) {
                // claim package
                dispatch(bindPackage({
                    id: packageObject.id,
                    anonymousToken: localStorage.getItem('_packageToken.' + packageObject.id) ?? ''
                }));
            }

            dispatch(requestModalRoute(modalRoutes.packageSettings, {}));
        } else {
            dispatch(requestModalRoute(modalRoutes.register, {}));
        }
    }

    const handleDownloadClick = () => {
        dispatch(unsetActiveField());

        dispatch(finishSigning({packageId: id})).then(unwrapResult).then(result => {
            history.push('/packages/' + id + '/documents/' + documentId + '/details');
        });
    }

    const handleBack = (e: any) => {
        history.goBack();
    }

    const allSignersHaveFields = (): any => {
        if (signers.length === 0) {
            return false;
        }

        // all signers should have field
        for (const signer of signers) {
            const found = signatureFields.find(f => {
                return f.signerId === signer.id;
            }) !== undefined || textFields.find(f => {
                return f.signerId === signer.id;
            }) !== undefined;

            if (!found) {
                return false;
            }
        }

        return true;
    }

    const userIsOnlySigner = () => {
        const unsignedFieldFound = signatureFields.find(f => {
            return f.signedAt === null;
        }) !== undefined || textFields.find(f => {
            return f.textBody === null;
        }) !== undefined;

        return signers.length === 1 && signers[0].email.trim().toLowerCase() === currentUser?.email.trim().toLowerCase() && !unsignedFieldFound;
    }

    return (
        <Wrapper>
            <TopBar/>

            <Title className={"title"}>
                <BackButton onClick={handleBack}/>
                <PackageInfo>
                    <div className={"name"}>{packageObject?.name}</div>
                    <div className={"size"}>{humanFileSize(documentObject?.size)}</div>
                </PackageInfo>
            </Title>

            <Middle>
                <Left>
                    <DocumentFieldEditor mode={modes.compose} className={"fieldEditor"}/>
                </Left>
                <Right>
                    {signers.length === 0 &&
                    <Instructions>
                        <strong>Hoe voeg ik een handtekening veld toe?</strong>
                        <p>
                            Klik op het document op de locatie waar je een handtekeningveld wil toevoegen.
                        </p>
                    </Instructions>
                    }

                    <Signers/>
                    <Settings/>

                    {allSignersHaveFields() > 0 &&
                    <>
                        <Instructions className={"addMore"}>
                            <p>
                                Nog iemand toevoegen?
                                Klik opnieuw op het document op de locatie waar je een handtekeningveld wil toevoegen.
                            </p>
                        </Instructions>
                        <Invite>
                            {userIsOnlySigner() ?
                                <Button text={t('complete_document')} icon={<FaDownload/>} className={"button primary"}
                                        disabled={packagesState.sending}
                                        onClick={handleDownloadClick}/>
                                :
                                <Button text={t('send_invites')} icon={<RiSendPlaneFill/>} className={"button primary"}
                                        disabled={packagesState.sending}
                                        onClick={handleInviteClick}/>
                            }
                        </Invite>
                    </>
                    }
                </Right>
            </Middle>
        </Wrapper>
    );
}

const Wrapper = styled.div`
   padding: 20px 30px 20px 30px;
   display: flex;
   flex: 1;
   flex-direction: column;
   
  @media screen and (max-width: 1000px) {
   padding: 10px 15px 5px 15px; 
  }
`;

const Title = styled.div`
    display:flex;
    padding: 0 0 15px 0;
        
    .size {
      font-size: 0.8rem;
      color: #888;
    }
    
    @media screen and (max-width: 1000px) {
      display: none;
    }
`;

const PackageInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  
  .name {
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const Middle = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  min-height: 0;  
  
  @media screen and (max-width: 1000px) {
    flex-direction: column;            
  }
`;

const Left = styled.div`   
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
    
  .fieldEditor {
    width: 100%
  }
`;

const Right = styled.div`  
  display: flex;
  flex-direction: column;  
  padding: 0 0 0 30px;
  flex: 0 0 400px;
  
  @media screen and (max-width: 1000px) {
    padding: 20px 0 0 0;
    flex: 0 0 160px;
  }
`;

const Instructions = styled.div`
  line-height: 22px;
  padding-bottom: 40px;
  
  &.addMore {
      @media screen and (max-width: 1000px) {
        display: none;
      }  
  }
`;

const Invite = styled.div`
  flex: 0 0 50px;    
  padding: 10px 0 0 0;
  color:white;
`;
