import BaseTransformer from "./BaseTransformer";
import SignerTransformer from "./SignerTransformer";

export default class SignatureFieldTransformer extends BaseTransformer {
    public static transform(data: any): any {
        return {
            id: data.id,
            signerId: data.signerId,
            documentId: data.documentId,
            top: data.top,
            left: data.left,
            height: data.height,
            width: data.width,
            pageId: data.pageId,
            signer: data.signer ? SignerTransformer.transform(data.signer) : null,
            signedAt: data.signedAt ?? null,
            imageWidth: data.imageWidth,
            imageHeight: data.imageHeight,
        }
    }
}