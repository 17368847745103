import React, {useEffect, useRef, useState} from 'react';
import {FaSignature} from "react-icons/all";
import styled from 'styled-components/macro';
import {useTranslation} from "react-i18next";
import {downloadSignature, selectPackages} from "../../features/package/packageSlice";
import {useSelector} from "react-redux";
import {useAppDispatch} from "../../app/store";
import {unwrapResult} from "@reduxjs/toolkit";
import {isMobile} from "../../helpers";
import {fieldAction} from "../../features/package/compose/components/AddSigner";

interface Props {
    field: any,
    active: boolean,
    left: number,
    top: number,
    width: number,
    height: number,
    readonly: boolean
    scale: number,

    onClick(id: string, action: string, pageId?: string, field?: any): any;
}

export function SigningField(props: Props) {

    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const packagesState = useSelector(selectPackages);
    const [signatureData, setSignatureData] = useState();

    useEffect(() => {
        const signature = packagesState.signatures.find(s => s.signatureField.id === props.field.id);
        if (!signature) {
            return;
        }
        dispatch(downloadSignature({
            packageId: packagesState.package.id,
            signatureId: signature.id
        })).then(unwrapResult).then(result => {
            setSignatureData(result);
        })
    }, [dispatch, packagesState.package.id, props.field.id, packagesState.signatures])

    const handleClick = () => {
        props.onClick(props.field.id, fieldAction.sign)
    }

    const _isMobile = isMobile(1000);

    const fieldStyle = {
        width: props.width,
        height: props.height,
        top: props.top,
        left: props.left,
        fontSize: (props.scale / (_isMobile ? 2 : .6)) + 'rem'
    };

    //
    // const signed = packagesState.signatures.find(s => {
    //     return s.signatureField.id === props.field.id
    // });

    return (
      <Wrappper
        id={props.field.id}
        style={fieldStyle}
        className={`signing-field  ${props.active ? 'active' : ''} ${props.readonly ? 'readonly' : ''} ${signatureData ? 'signed' : ''}`}
        onClick={handleClick}
      >
        {signatureData ?
            <>
                <span className={"email"}>{props.field.signer.email}</span>
                {signatureData && <img alt={""} src={`data:image/svg+xml;base64,${signatureData}`}/>}
            </>
            :
            <>
                {props.readonly ?
                  <span className={"email"}>{props.active ? t('click_to_sign') : props.field.signer.email}</span>
                  :
                    <>
                        <FaSignature className={"signature"}/> {t('click_to_sign')}
                    </>
                }
            </>
        }
    </Wrappper>)
}


const Wrappper = styled.div`      
    cursor:pointer;
    width:100%;
    height: 100%;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content: center;
    // overflow:hidden;
    position: absolute;
    background-color: rgba(0,85,255,0.2);
    border-radius: 4px;
    color: black;
    transition: all .2s ease-in-out;
  
    .email {
        height: 20px;
        display: none;
        text-align: center;
        padding: 4px 10px 2px;
        background-image: linear-gradient(to right,rgb(53,42,180),rgb(98,84,255));
        border-radius: 13px;
        margin-top: 0;
        color: #fff;
        position: absolute;
        top: -40px;
    }
    
    &:hover {
        .email {
            display: block;
        }
    }
    
    img {
      height: 100%;
    }
    
    &.active {
        border:1px solid rgb(0,85,255);
        height:100%;
        background-color: rgba(0,85,255,0.4);
        
        .email {
            display: block;
        }
    }
    
    &.readonly {
      cursor: default;
    }
    
    .signature {
        display:flex;
        flex: 0 0 40px;
        font-size:1.5rem;
        
        path, stroke {            
            fill:black;
        }
    }
    
`;
