import React, {memo, useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components/macro';

import {
  fetchPackage, finishSigning, packageStatsFetched,
  selectPackages, setActiveField
} from '../packageSlice';

import {useHistory, useParams} from "react-router-dom";

import {DocumentViewer} from "../../../components/DocumentViewer/DocumentViewer";
import {Button} from "../../../components/Button";
import {useAppDispatch} from "../../../app/store";
import {useTranslation} from "react-i18next";
import {unwrapResult} from "@reduxjs/toolkit";
import {Logo} from "../../../components/Logo";
import {ProgressBar} from "../../../components/ProgressBar";
import {fetchCurrentSigner, selectUser} from "../../user/userSlice";
import {modalRoutes, requestModalRoute} from "../../modal/modalSlice";
import {FaPlus} from "react-icons/all";

export const SignPackage = memo(
  () => {
    const packagesState = useSelector(selectPackages);
    const userState = useSelector(selectUser);
    const dispatch = useAppDispatch();
    const {id, token} = useParams();
    const history = useHistory();
    const {t} = useTranslation();

    const [progress, setProgress] = useState(0)
    const [totalRemaining, setTotalRemaining] = useState(0)
    const [signClicks, setSignClicks] = useState(0);
    const [showCloseSendReminder, setShowCloseSendReminder] = useState(true);

    useEffect(() => {
      localStorage.setItem('_signingToken.' + id, token);

      dispatch(fetchPackage({id: id}));
      dispatch(fetchCurrentSigner({packageId: id}));
    }, [dispatch, id, token]);

    useEffect(() => {
      const signatureCount = packagesState?.signatures?.length;
      const textFieldFilledCount = packagesState?.textFields?.filter((f: any) => {
        return f.textBody && f.signer.id === userState?.currentSigner?.id;
      })?.length;
      const signatureFieldCount = packagesState?.signatureFields?.length;
      const textFieldCount = packagesState?.textFields?.filter((f: any) => {
        return f.signer.id === userState?.currentSigner?.id
      })?.length;

      setProgress((
        (signatureCount + textFieldFilledCount) / (signatureFieldCount + textFieldCount)
      ) * 100);
      setTotalRemaining((signatureFieldCount + textFieldCount) - (signatureCount + textFieldFilledCount));
    }, [userState, packagesState]);

    const handleSigningFieldClick = (id: string) => {
      dispatch(requestModalRoute(modalRoutes.sign, {
        packageId: packagesState.package.id,
        signatureFieldId: id,
        token: token
      }));
    }

    const handleTextFieldClick = (id: string, pageId?: string, field?: any) => {
      dispatch(requestModalRoute(modalRoutes.write, {
        packageId: packagesState.package.id,
        signatureFieldId: id,
        token: token,
        documentId: packagesState.document.id,
        pageId: pageId,
        field: field
      }));
    }

    const getNextUnsignedFieldId= () => {
      if (!packagesState.package) {
        return null;
      }

      // get first unsigned field (fields are ordered by page, top, left)
      const emptyFields = packagesState.textFields.filter((field) => {
        return !field.textBody && field.signer.id === userState?.currentSigner?.id;
      });

      if (emptyFields.length) {
        return emptyFields[0].id;
      }

      const emptySignatures = packagesState.signatureFields.filter((field) => {
        const isSigned = packagesState.signatures.find(s => {
          return s.signatureField.id === field.id && field.signedAt === null;
        });
        return !isSigned && field.signer.id === userState?.currentSigner?.id;
      });

      if (emptySignatures.length) {
        return emptySignatures[0].id;
      }

      return null;
    }

    const scrollToSignatureField = (id: string) => {
      dispatch(setActiveField(id));

      // scroll to field
      const field = document.getElementById(id);
      const pages = document.getElementById('pages');
      if (pages) {
        pages.scrollTop = (field?.offsetTop ?? 0) + (field?.parentElement?.offsetTop ?? 0) - window.outerHeight / 4;
      }
    }

    const verifyPhoneNumber = () => {
      dispatch(requestModalRoute(modalRoutes.verify, {
        packageId: packagesState.package.id,
        token: token
      }));
    };

    const gotoNextSignatureField = (e: any) => {
      e.preventDefault();
      if (packagesState.signatureFields.length === 0) {
        return;
      }

      let nextFieldId = getNextUnsignedFieldId();

      // all fields signed?
      if (isFinished()) {
        if (isVerified()) {
          dispatch(finishSigning({
            packageId: packagesState.package.id,
          })).then(unwrapResult).then(result => {
            history.push('/packages/' + packagesState.package.id + '/finished/' + localStorage.getItem('_signingToken'));
          });
          return;
        }

        verifyPhoneNumber();
        return;
      }

      if (!nextFieldId) {
        return;
      }

      scrollToSignatureField(nextFieldId);
      if (signClicks > 0) {
        const textField = packagesState.textFields.find((field) => {
          return field.id === nextFieldId;
        });

        if (textField) {
          handleTextFieldClick(textField.id, textField.pageId, textField);
          return;
        }

        const myField = packagesState.signatureFields.find((field) => {
          return field.signerId === userState.currentSigner?.id
        });

        if (myField) {
          handleSigningFieldClick(myField.id);
        }
      }
      setSignClicks(signClicks + 1);
    }

    const isVerified = () => {
      return packagesState.package?.phoneVerificationRequired ?
          userState.currentSigner?.phoneVerifiedAt !== null : true;
    }

    const isFinished = () => {
      return packagesState.signatures.length === packagesState.signatureFields.length && packagesState?.textFields?.filter((f: any) => {
        return !f.textBody && f.signerId === userState.currentSigner?.id;
      }).length === 0;
    }

    const getTodoButtonText = () => {
      return isFinished() ?
        (isVerified() ? t('finish_and_send') : t('verify_phone_number')) :
        (packagesState.signatures.length > 0 ? t('show_next_field') : t('start_signing'))
    }

    const closeSendReminderKey = "send-reminder-closed";
    const highlightSendReminder = packagesState.package && showCloseSendReminder && !localStorage.getItem(closeSendReminderKey) && isFinished() && isVerified();
    const closeSendReminder = () => {
      if (!localStorage.getItem(closeSendReminderKey)) {
        setShowCloseSendReminder(false);
        localStorage.setItem(closeSendReminderKey, "closed");
      }
    }

    return (
      <Wrapper>
        <Header>
          <Logo/>
          <Progress>
            <ProgressBar className={"progressbar"}
                         label={totalRemaining === 0 ? t('all_signatures_placed') : t('signatures_remaining', {count: totalRemaining})}
                         progress={progress}/>
          </Progress>
          {(packagesState?.package?.expiresAt && Date.parse(packagesState?.package?.expiresAt) < Date.now() && (
            <SignatureNavigator>
              <Button text={"Verlopen"} className={"disabled"} />
            </SignatureNavigator>
          )) || (userState?.currentSigner?.signedAt == null &&
              <>
                <SignatureNavigator>
                  <Button
                      id={"start_signing"}
                      text={getTodoButtonText()}
                      className={`primary ${highlightSendReminder ? "animate" : ""}`}
                      onClick={gotoNextSignatureField}
                  />
                </SignatureNavigator>
                {highlightSendReminder &&
                  <SendReminder>
                    Vergeet niet het document te versturen om het ondertekenen volledig af te ronden
                    <SendReminderClose onClick={closeSendReminder}><FaPlus className={"button"} /></SendReminderClose>
                  </SendReminder>
                }
              </>
          )}
        </Header>
        <Middle>
          <Left>
            <Details>
              <div className={"intro"}>
                <strong>{packagesState.package?.user?.firstName} {packagesState.package?.user?.lastName}</strong> heeft
                je een document gestuurd dat ondertekend dient te
                worden.
              </div>
              {packagesState?.package?.welcomeMessage &&
                  <div className={"message"}>
                      <div className={"name"}>
                          Bericht van {packagesState.package?.user?.firstName}
                      </div>
                      <div className={"text"}>
                        {packagesState?.package?.welcomeMessage}
                      </div>
                  </div>
              }

            </Details>
          </Left>
          <Right>
            <DocumentViewer mode={"sign"} className={"documentViewer"}
                            onSigningFieldClick={handleSigningFieldClick}
                            onTextFieldClick={handleTextFieldClick}/>
          </Right>

        </Middle>
      </Wrapper>
    )
  });


const Wrapper = styled.div`
  display:flex;
  flex-direction: column;
  flex: 1;
  padding: 20px 30px 20px 30px;

  @media screen and (max-width: 1000px) {
    padding: 20px 15px 20px 15px;  
  }

  .pages {
    height: calc(100vh - 100px);
    scroll-behavior: smooth;

    .signing-field, .text-field {       
      cursor: pointer;

      &.active {
         border: 1px solid rgba(0,85,255,.5);
         animation: pulse 1s infinite;
      }

      &.signed {
        animation: none;
        border: 1px solid rgb(38,195,23);
        background-color:  rgba(172,255,163,.5);
      }
    }
  }   
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;    
  align-items: center;
  padding: 0 0 20px 0;

  @media screen and (max-width: 1000px) {
     .logo {
        flex:1;

        img {
          width: 100px;
        }
     }     
    }
`;

const Progress = styled.div`
    display: flex;    
    padding: 15px;
    flex: 1;
    align-items: center;
    justify-content: center;
    flex-direction: row;

    .progressbar {
      flex: 0 0 300px;
    }    

    @media screen and (max-width: 1000px) {
      display: none;     
    }
`;

const SignatureNavigator = styled.div`
    display: flex;
    align-items: center;

    .progress {
      display: block;
      padding-right: 15px;
    }
`;

const Middle = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  min-height: 0;  

  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
`;


const Left = styled.div`   
  flex: 0 0 400px;
  display: flex;
  flex-direction: column;  
  padding: 0 40px 0 0;

  @media screen and (max-width: 1000px) {
    // @todo: convert to modal
    display: none;
  }
`;

const Right = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  .documentViewer {
    width: 100%
  }
`;

const Details = styled.div`
    .intro {
      font-size: 1rem;
    }

    .name {
      border-bottom: 1px solid #a2a2a2;
      padding: 0 0 10px 0;
      margin-bottom: 10px;
    }

    .message {
      background-color: rgba(74,74,74,.5);
      border-radius: 8px;
      padding: 15px;
      margin-top: 20px;
    }
`;

const SendReminder = styled.div`
  display: flex;
  position: absolute;
  right: 30px;
  top: 100px;
  border-radius: 50px;
  background: red;
  padding: 5px 10px 5px 10px;
  z-index: 3;
  font-size: 12px;
  align-items: center;
`;

const SendReminderClose = styled.div`
  cursor: pointer;

  .button {
    transform: rotate(45deg);
    margin-left: 5px;
    margin-bottom: -2px;
  }
`;
