import React from 'react';
import styled from 'styled-components/macro';
import {requestModalRoute, selectModal} from '../../modal/modalSlice';
import {Button} from "../../../components/Button";
import {useTranslation} from "react-i18next";
import {useAppDispatch} from "../../../app/store";
import {useSelector} from "react-redux";

export function CustomButtons() {
    const dispatch = useAppDispatch();
    const modalState = useSelector(selectModal);
    const {t} = useTranslation();

    const routeParams = modalState.modalRouteParams;

    return (
        <Wrapper>
            <Description>
                {routeParams.description}
            </Description>
            <Buttons>
                {routeParams.cancelButton ?
                  <Button
                    key={`custom-button-cancel`}
                    onClick={() => dispatch(requestModalRoute(null, null))}
                    className={'button action delete'}
                    text={routeParams.cancelButtonText ? routeParams.cancelButtonText : t('cancel')}
                    style={{marginRight: 15}}
                  />
                : null}
                {routeParams.buttons.map((button: any, index: number) => (
                  <Button
                    key={`custom-button-${index}`}
                    onClick={button.onClick}
                    className={button.className ? button.className : 'primary'}
                    text={button.text}
                  />
                ))}
            </Buttons>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
    flex: 0 0 450px;
    flex-direction: column;
    padding-bottom: 15px;
    
    @media screen and (max-width: 1000px) {
      flex: 1;    
    }
`;

const Buttons = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
`;

const Description = styled.div`
    display: flex;
    padding-bottom: 30px;
`;
