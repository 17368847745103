import BaseTransformer from "./BaseTransformer";

export default class PageTransformer extends BaseTransformer {
    public static transform(data: any): any {
        return {
            id: data.id,
            pageNr: data.pageNr,
            ratio: data.ratio
        }
    }
}