import BaseTransformer from "./BaseTransformer";
import SignatureFieldTransformer from "./SignatureFieldTransformer";
import SignerTransformer from "./SignerTransformer";

export default class SignatureTransformer extends BaseTransformer {
    public static transform(data: any): any {
        return {
            id: data.id,
            signatureField: SignatureFieldTransformer.transform(data.signatureField),
            signer: SignerTransformer.transform(data.signer),
            createdAt: data.createdAt ?? null,
            updatedAt: data.updatedAt ?? null,
        }
    }
}