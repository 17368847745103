import React, {useState} from 'react';
import styled from 'styled-components/macro';

import {validateRequestUser} from '../userSlice';
import {modalRoutes, requestModalRoute} from '../../modal/modalSlice';
import {IconInput} from "../../../components/IconInput";
import {Button} from "../../../components/Button";
import {useTranslation} from "react-i18next";
import {useAppDispatch} from "../../../app/store";
import {FaEnvelope} from "react-icons/all";
import {unwrapResult} from "@reduxjs/toolkit";
import {useToasts} from 'react-toast-notifications';
import {ModalFooter} from "../../../components/ModalFooter";

export function RequestPasswordResetForm() {
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const {addToast} = useToasts();

    const initialValidationState = {
        password: [],
        email: []
    };

    const [validationErrors, setValidationErrors] = useState(initialValidationState);
    const [email, setEmail] = useState('');

    const handleEmailChange = (e: any) => {
        setEmail(e.target.value);
    }


    const handleSubmit = (e: any) => {
        e.preventDefault();
        dispatch(validateRequestUser({email: email})).then(unwrapResult).then(result => {
            if (result.error) {
                result.error === 'validation_error' ? setValidationErrors(result.validation_errors) :
                    addToast(t(result.error), {appearance: 'error', autoDismiss: true});
            } else {
                dispatch(requestModalRoute(modalRoutes.validate, {}));
            }
        });
    }

    const handleLoginClick = () => {
        dispatch(requestModalRoute(modalRoutes.login, {}));
    }

    const handleRegisterClick = () => {
        dispatch(requestModalRoute(modalRoutes.register, {}));
    }


    return (
        <Wrapper>
            <form>
                <div className={"email"}>
                    <div className={"formgroup"}>
                        <IconInput className={"email"} placeholder={t('emailaddress')}
                                   validationError={validationErrors.email[0]} type={"email"}
                                   icon={<FaEnvelope/>}
                                   onChange={handleEmailChange}/>
                    </div>
                </div>
                <div className={"actions"}>
                    <Button text={t('proceed')} className={"button primary"} onClick={handleSubmit}/>
                    <br/>
                    <span><a href="#" onClick={handleLoginClick}>{t('i_know_my_password')}</a></span>


                </div>
            </form>
            <ModalFooter>
                <span><a href="#" onClick={handleRegisterClick}>{t('no_account')} {t('register')}</a></span>
            </ModalFooter>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    flex: 0 0 450px;
    @media screen and (max-width: 1000px) {
      flex: 1;    
    }
    
    
    .formgroup, .actions {
      padding: 10px 0 10px 0;
      
      label {
        margin-bottom: 5px;
      }
    }
    
     
    .actions {
      text-align: center;
      margin-top: 10px;
      
      .primary {
        margin-bottom: 20px;
      }
      
      a {
        color: #666;
      }
    }
`;
