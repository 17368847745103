import React, {useState} from 'react';
import styled from "styled-components/macro";
import {useDispatch, useSelector} from "react-redux";
import {logout, selectUser} from "../userSlice";
import {formatDate} from "../../../helpers";
import {FaPowerOff, FaRegUserCircle} from 'react-icons/fa';
import {FaLock} from "react-icons/all";
import {modalRoutes, requestModalRoute} from "../../modal/modalSlice";

export function UserMenu() {
  const dispatch = useDispatch();
  const userState = useSelector(selectUser);

  const handleEditUserClick = () => {
    dispatch(requestModalRoute(modalRoutes.editUser, {hideClose: false}));
  }

  const handleChangePasswordClick = () => {
    dispatch(requestModalRoute(modalRoutes.setPassword, {hideClose: false}));
  }

  const handleCreateLongLivedTokenClick = () => {
    dispatch(requestModalRoute(modalRoutes.createLongLivedToken, {hideClose: false}));
  }

  const handleLogoutClick = () => {
    dispatch(logout());
    document.location.href = '/';
  }

  return (
    <Wrappper>
      <div className={"header"}>
        <div className={"avatar"}><FaRegUserCircle /></div>
        <div className={"user"}>
          <div className={"name"}>
            {userState?.currentUser?.firstName} {userState?.currentUser?.lastName}
          </div>
          <div className={"email"}>
            {userState?.currentUser?.email}
          </div>
        </div>
        <div className={"info"}>
          <div className={"info_row"}>
            <span className={"info_title"}>Actief sinds:</span>
            {formatDate(userState?.currentUser?.validatedAt)}
          </div>
          <div className={"info_row"}>
            <span className={"info_title"}>Aantal documenten:</span>
            {userState?.currentUser?.totalFreePackages}
          </div>
        </div>
      </div>

      <div className={"items"}>
        <div className={"item"}>
          <div className={"icon"}>
            <FaRegUserCircle />
          </div>
          <div className={"title_holder"} onClick={handleEditUserClick}>
            <div className={"title"}>Mijn gegevens wijzigen</div>
            <div className={"subtitle"}>Verander gegevens zoals je naam</div>
          </div>
        </div>
        <div className={"item"} onClick={handleChangePasswordClick}>
          <div className={"icon"}>
            <FaLock />
          </div>
          <div className={"title_holder"}>
            <div className={"title"}>Wachtwoord wijzigen</div>
            <div className={"subtitle"}>Verander regelmatig, wel zo veilig</div>
          </div>
        </div>
        <div className={"item"} onClick={handleCreateLongLivedTokenClick}>
          <div className={"icon"}>
            <FaRegUserCircle />
          </div>
          <div className={"title_holder"}>
            <div className={"title"}>API token opvragen</div>
            <div className={"subtitle"}>Legalsign koppelen aan andere software?</div>
          </div>
        </div>
        <div className={"item"} onClick={handleLogoutClick}>
          <div className={"icon"}>
            <FaPowerOff />
          </div>
          <div className={"title_holder"}>
            <div className={"title"}>Uitloggen</div>
            <div className={"subtitle"}>Log uit met je gebruiker</div>
          </div>
        </div>
        {/*
        <div className={"item"}>
          <div className={"icon"}>
            <FaRegUserCircle />
          </div>
          <div className={"title_holder"}>
            <div className={"title"}>Mijn account verwijderen</div>
            <div className={"subtitle"}>Wil je dat we al je gegevens verwijderen?</div>
          </div>
        </div>
        */}
      </div>

    </Wrappper>
  );
}

const Wrappper = styled.div` 

  .header {
    display: flex;
    align-items: center;
    margin-top: -45px;
  }
  
  .avatar {
    font-size: 40px;
    margin-top: 10px;
    margin-right: 10px;
  }
  
  .user {
    border-right: 2px solid #6254ff;
    padding-right: 15px;
    margin-right: 15px;
  }
  
  .name {
    font-size: 1.2rem;
    font-weight: bold;
  }
  
  .email {
    color: #a09f9f;
  }  
  
  .info_title {
    font-weight: bold;
    margin-right: 4px;
  }
  
  .items {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    grid-auto-rows: minmax(auto, auto);
    margin-top: 15px;
    margin-bottom: 30px;
  }
  
  .item {
    transition: background-color 250ms;
    background-color: #ededed;
    border-radius: 10px;
    display: flex;
    padding: 15px;
    cursor: pointer;
    
    &:hover {
      background-color: #d2d2d2;
    }
  }
  
  .icon {
    font-size: 35px;
    color: #6254ff;
    margin-right: 15px;
    margin-bottom: -5px;
  }
  
  .title {
    font-weight: bold;
    font-size: 1.2rem;
  }
  
  .subtitle {
    color: #a09f9f;
  }

`;
