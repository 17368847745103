import BaseTransformer from "./BaseTransformer";
import PageTransformer from "./PageTransformer";

export default class DocumentTransformer extends BaseTransformer {
    public static transform(data: any): any {
        return {
            id: data.id,
            filename: data.filename,
            name: data.name,
            size: data.size,
            pageCount: data.pageCount,
            pages: PageTransformer.transformList(data.pages),
            previewToken: data.previewToken ?? null,
        }
    }
}