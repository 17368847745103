import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import styled from 'styled-components/macro';
import {
    deleteSignature,
    deleteSignatureField, fetchPackage, fetchTextFields,
    selectPackages, setCurrentPage, unsetActiveField, updateSignatureField, updateTextField,
} from '../../packageSlice'

import ReactTooltip from "react-tooltip";
import {Page} from "./Page";
import {ToolBar} from "./ToolBar";
import {isMobile} from "../../../../helpers";
import {LoadingSpinner} from "../../../../components/LoadingSpinner";

import {config} from "../../../../app/config";
import {Document} from "react-pdf"
import {pdfjs} from 'react-pdf'
import {fieldAction} from "./AddSigner";

pdfjs.GlobalWorkerOptions.workerSrc = `/pdf.worker.min.js`;

export const modes = {
    compose: 'compose',
    sign: 'sign',
    view: 'view',
}

interface Props {
    mode: string,
    className: string
}

export function DocumentFieldEditor(props: Props) {
    const packagesState = useSelector(selectPackages);
    const dispatch = useDispatch();

    const [scale, setScale] = useState(isMobile() ? .8 : .5);

    const maxScale = 3;
    const minScale = .3
    const pageWidth = window.innerWidth;
    const pageMarginBottom = 20;

    useEffect(() => {
        document.addEventListener('keydown', handleDeleteKey, false);
        return () => {
            document.removeEventListener('keydown', handleDeleteKey, false);
        }
    }, [packagesState.package, packagesState.activeFieldId])


    const handleDeleteKey = (e: any) => {
        if (!packagesState.activeFieldId) {
            return;
        }
        if (e.which === 46 || e.which === 8) {
            handleDeleteField();
        }
    }

    const handleZoomIn = () => {
        if (scale < maxScale) {
            setScale(scale + .05);
        }
    }

    const handleZoomOut = () => {
        if (scale > minScale) {
            setScale(scale - .05);
        }
    }

    const handleDeleteField = async () => {
        const oldSignature = packagesState.signatures.find(s => s.signatureField.id === packagesState.activeFieldId);
        if (oldSignature) {
            await dispatch(deleteSignature({packageId: packagesState.package.id, signatureId: oldSignature.id}))
        }

        dispatch(deleteSignatureField(
            packagesState.package.id,
            packagesState.activeFieldId,
        ));
        dispatch(unsetActiveField());
    }

    const handleBoundsChanged = (id: string, width: number, height: number, left: number, top: number, action: string, pageId?: string, textData?: string, signerId?: string, imageWidth?: number, imageHeight?: number) => {
        if (action === fieldAction.sign) {
            dispatch(updateSignatureField({
                packageId: packagesState.package.id,
                id: id,
                width: width,
                height: height,
                left: left,
                top: top,
                imageWidth: imageWidth,
                imageHeight: imageHeight
            }));
        } else {
            dispatch(updateTextField({
                packageId: packagesState.package.id,
                documentId: packagesState.document.id,
                pageId: pageId ? pageId : '',
                signerId: signerId ? signerId : '',
                id: id,
                width: width,
                height: height,
                left: left,
                top: top,
                textBody: textData
            }));
        }
    }

    const hasParentWithClass = (element: any, classname: string): boolean => {
        if (element.className && element.className.split !== undefined && element.className.split(' ').indexOf(classname) >= 0) return true;
        return element.parentNode && hasParentWithClass(element.parentNode, classname);
    }


    const handleScroll = (e: any) => {
        // const height = parseInt(e.target.children[0].style.height.replace('px', ''));
        const height = 1684;
        const page = Math.floor(e.target.scrollTop / (height + pageMarginBottom));

        setTimeout(() => {
            dispatch(setCurrentPage(page));
        }, 300);
    }

    const handlePagesClick = (e: any) => {
        if (!hasParentWithClass(e.target, 'react-draggable')) {
            dispatch(unsetActiveField());
        }
    }

    const renderPages = () => {

        if (!packagesState.package || !packagesState.signatureFields || !packagesState.document) {
            return;
        }

        const pages: any = [];
        const document = packagesState.package?.documents[0];
        document?.pages.map((p: any) => {
            const pageHeight = pageWidth * parseFloat(p.ratio);

            pages.push(<Page
                key={p.pageNr}
                page={p}
                width={pageWidth}
                mode={props.mode}
                height={pageHeight}
                renderPreview={true}
                marginBottom={pageMarginBottom}
                scale={scale}
                signatureFields={packagesState?.signatureFields?.filter((f: any) => {
                    return f.pageId === p.id && f.documentId === document.id
                })}
                textFields={packagesState?.textFields?.filter((f: any) => {
                    return f.pageId === p.id && f.documentId === document.id
                })}
                boundsChanged={handleBoundsChanged}
            />);
        });

        return pages;
    }

    const url = config.api_base_url + "/api/packages/" + packagesState?.package?.id + "/documents/" +
        packagesState?.document?.id + "/download?token=" + packagesState?.document?.previewToken;

    return (
        <Wrapper className={props.className}>
            <ToolBar
                activeFieldId={packagesState.activeFieldId}
                onZoomIn={handleZoomIn}
                onZoomOut={handleZoomOut}
                onDelete={handleDeleteField}
                showDelete={isMobile()}
            />

            {packagesState?.document?.id &&
            <Pages onScroll={handleScroll} onClick={handlePagesClick} className={"pages"}>
                <Document
                    file={url}
                    loading={<Loading><LoadingSpinner/></Loading>}
                    onLoadProgress={(e) => {
                    }}
                >
                    {renderPages()}
                </Document>
            </Pages>}

            {/*<Controls className={"controls"}>*/}

            {/*</Controls>*/}
            {isMobile() || <ReactTooltip place="top" type="dark" effect="solid"/>}
        </Wrapper>
    );
}

const Wrapper = styled.div`
  &:hover {
    .controls {
        opacity: 1;
    }
  }
  display: flex;  
  flex-direction: column;
  position: relative;
  overflow: hidden;
  border-radius: 32px;    
  background-color:rgba(151,151,151,.33);  
`;

const Loading = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  min-height: 500px;
`;

const Pages = styled.div`         
  padding: 70px 0 25px 0;
  overflow: auto;
  
`;
