import React, {useEffect, useState} from 'react';
import styled from 'styled-components/macro';

import {ImageLoader} from "../ImageLoader";

import {useDispatch, useSelector} from "react-redux";
import {selectPackages, setActiveField} from "../../features/package/packageSlice";

import {config} from "../../app/config";
import {SigningField} from "./SigningField";
import {packageStatuses} from "../../features/package/packageStatuses";

import {Page as PdfPage} from "react-pdf";
import {getRelevantToken} from "../../helpers";
import {TextField} from "./TextField";
import {fieldAction} from "../../features/package/compose/components/AddSigner";


interface Props {
    page: any,
    width: number,
    height: number,
    renderPreview: boolean,
    marginBottom: number,
    scale: number,
    signatureFields: any[],
    textFields: any[],

    onSigningFieldClick(id: string): any;
    onTextFieldClick(id: string, pageId?: string, field?: any): any;
}

export function DocumentViewerPage(props: Props) {
    const packagesState = useSelector(selectPackages);
    const dispatch = useDispatch();
    const [previewLoaded, setPreviewLoaded] = useState(false);

    useEffect(() => {
        if(props.renderPreview) {
            setPreviewLoaded(true);
        }
    }, [props.renderPreview])

    const pageStyle = {
        width: props.width * props.scale,
        height: props.height * props.scale,
        marginBottom: props.marginBottom,
    };

    const handleFieldClick = (id: string, action: string, pageId?: string, field?: any) => {
        if(packagesState.package.status === packageStatuses.Completed) {
            return;
        }

        dispatch(setActiveField(id));
        if (action === fieldAction.sign) {
            props.onSigningFieldClick(id);
        } else {
            props.onTextFieldClick(id, pageId, field);
        }
    }

    return (
        <Wrapper style={pageStyle}>
            {(props.renderPreview || previewLoaded) && <PdfPage
                pageNumber={props.page.pageNr + 1}
                width={pageStyle.width}
                height={pageStyle.height}
                key={props.page.pageNr}
                className={"page"}
                renderTextLayer={false}
                renderMode={"canvas"}
            />}

            {props.signatureFields.map((field: any) => {
                // const width = props.width / 100 * (field.width * props.scale);
                return (
                    <SigningField
                        key={field.id}
                        field={field}
                        scale={props.scale}
                        readonly={packagesState.package.status !== packageStatuses.Draft}
                        active={field.id === packagesState.activeFieldId}
                        width={props.width / 100 * (field.width * props.scale)}
                        // height={width / 1.7777}
                        height={props.height / 100 * (field.height * props.scale)}
                        left={props.width / 100 * (field.left * props.scale)}
                        top={props.height / 100 * (field.top * props.scale)}
                        onClick={handleFieldClick}
                    />
                );
            })}

            {props.textFields.map((field: any) => {
                return (
                    <TextField
                        key={field.id}
                        field={field}
                        scale={props.scale}
                        readonly={packagesState.package.status !== packageStatuses.Draft}
                        active={field.id === packagesState.activeFieldId}
                        width={props.width / 100 * (field.width * props.scale)}
                        height={props.height / 100 * (field.height * props.scale)}
                        left={props.width / 100 * (field.left * props.scale)}
                        top={props.height / 100 * (field.top * props.scale)}
                        onClick={handleFieldClick}
                    />
                );
            })}
        </Wrapper>
    );
}


const Wrapper = styled.div`    
  position: relative;
  margin: 0 auto;   
  overflow:hidden;
  background-color: white;
  -webkit-box-shadow: 0 0 10px -3px rgba(0,0,0,0.59); 
  box-shadow: 0 0 10px -3px rgba(0,0,0,0.59);
  transition: all .2s ease-in-out;

  img.background-image {
    width:100%;   
    -webkit-user-drag: none;    
      -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
           -moz-user-select: none; /* Old versions of Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
                user-select: none; /
  }
`;
