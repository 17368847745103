import React, {useEffect} from 'react';
// import logo from './logo.svg';
import {PackageList} from './features/package/list/PackageList';
import {ComposePackage} from './features/package/compose/ComposePackage';
import {fetchCurrentUser, deleteCurrentUser, selectUser} from './features/user/userSlice';
import {modalRoutes, requestModalRoute, selectModal} from './features/modal/modalSlice';
import {BrowserRouter as Router, Route} from "react-router-dom";

import {useSelector} from 'react-redux'

import {Modal} from './components/Modal';
import PrivateRoute from './components/PrivateRoute';
import {RegisterForm} from './features/user/register/RegisterForm';
import {ValidateForm} from './features/user/register/ValidateForm';
import {SignForm} from './features/package/sign/components/SignForm';
import {AddSigner, fieldAction} from './features/package/compose/components/AddSigner';
import {CreateSignerForm} from './features/package/compose/components/CreateSignerForm';
import {RequestPasswordResetForm} from './features/user/passrecover/RequestPasswordResetForm';
import {PackageDetails} from "./features/package/details/PackageDetails";
import {LoginForm} from "./features/user/login/LoginForm";
import {SignPackage} from "./features/package/sign/SignPackage";
import {useAppDispatch} from "./app/store";
import {ToastProvider} from 'react-toast-notifications';
import {SetPasswordForm} from "./features/user/register/SetPasswordForm";
import {decodeJwt} from "./helpers";
import {Home} from './features/pages/home/Home';
import {PackageDownload} from "./features/package/download/PackageDownload";
import {SingingFinished} from "./features/package/finished/SigningFinished";
import {useTranslation} from "react-i18next";
import {Developers} from "./features/pages/home/Developers";
import {CustomButtons} from "./features/user/custombuttons/CustomButtons";
import {unwrapResult} from "@reduxjs/toolkit";
import {PackageSettings} from "./features/package/compose/components/PackageSettings";
import {selectPackages} from "./features/package/packageSlice";
import {AboutUs} from "./features/pages/home/AboutUs";
import {PrivacyPolicy} from "./features/pages/home/PrivacyPolicy";
import {TermsOfService} from "./features/pages/home/TermsOfService";
import {AddField} from "./features/package/compose/components/AddField";
import {WriteForm} from "./features/package/sign/components/WriteForm";
import {CreateLongLivedToken} from "./features/user/createLongLivedToken/CreateLongLivedToken";
import {VerifyForm} from "./features/package/sign/components/VerifyForm";
import {UserMenu} from "./features/user/userMenu/UserMenu";
import {PhoneVerification} from "./features/package/phoneVerification/phoneVerification";
import {EditUser} from "./features/user/editUser/EditUser";
import styled from "styled-components/macro";

function App() {

    const packagesState = useSelector(selectPackages);
    const userState = useSelector(selectUser);
    const modalState = useSelector(selectModal);
    const dispatch = useAppDispatch();
    const {t} = useTranslation();

    const publicRoutes = [
        '/',
        '/developers'
    ];

    useEffect(() => {
        startTokenCheck('_token');
        startTokenCheck('_signerToken');

        // prevent user token conflicting in signing session token
        if (document.location.href.indexOf('/sign/') !== -1) {
            return;
        }

        if (!localStorage.getItem('_token')) {
            return;
        }

        dispatch(fetchCurrentUser()).then(unwrapResult).then(result => {
            if (result === false) {
                if (publicRoutes.findIndex(r => r === document.location.pathname) === -1) {
                    dispatch(requestModalRoute(modalRoutes.login, {}));
                }
            }
        });

    }, [dispatch]);

    // remove expired tokens from storage
    function startTokenCheck(storageKey: string) {
        wipeExpiredToken(storageKey);
        setInterval(() => {
            wipeExpiredToken(storageKey);
        }, 5 * 60 * 1000); // every 30 seconds
    }

    const wipeExpiredToken = (storageKey: string) => {
        const token = localStorage.getItem(storageKey);

        if (!token) {
            return;
        }

        if (token === "undefined"  || decodeJwt(token).exp < Math.floor(Date.now() / 1000)) {
            localStorage.removeItem(storageKey);
            // @todo: logout user
            dispatch(deleteCurrentUser());
        }
    }

    document.addEventListener('gesturestart', e => e.preventDefault())
    document.addEventListener('gesturechange', e => e.preventDefault())

    const isLoggedIn = userState.currentUser
    // //const isLoggedIn = false;

    return (
        <>
            {userState.currentUser && <EolNotice>
                Deze versie van Legalsign is binnenkort niet meer beschikbaar. <a href="https://app.legalsign.app">Klik
                hier</a> om naar de nieuwe versie te gaan.
            </EolNotice>}

            <ToastProvider>
                <Router>

                    {modalState.modalRoute &&
                    <>
                        {modalState.modalRoute === modalRoutes.register &&
                            <Modal title={packagesState.package ? t('need_your_info') : t('register_header')}><RegisterForm/></Modal>}
                        {modalState.modalRoute === modalRoutes.validate &&
                            <Modal title={t('check_your_mailbox')}><ValidateForm/></Modal>}
                        {modalState.modalRoute === modalRoutes.login &&
                            <Modal title={t('login')}><LoginForm/></Modal>}
                        {modalState.modalRoute === modalRoutes.requestPasswordReset &&
                            <Modal title={t('recover_password')}><RequestPasswordResetForm/></Modal>}
                        {modalState.modalRoute === modalRoutes.setPassword &&
                            <Modal title={t('set_password')} hideClose={modalState.modalRouteParams.hideClose}><SetPasswordForm/></Modal>}
                        {modalState.modalRoute === modalRoutes.addField &&
                          <Modal title={t('what_has_to_happen')}><AddField/></Modal>}
                        {modalState.modalRoute === modalRoutes.addSigner &&
                            <Modal title={modalState.modalRouteParams.action === fieldAction.sign ? t('who_has_to_sign') : t('who_has_to_write')}><AddSigner/></Modal>}
                        {modalState.modalRoute === modalRoutes.createSigner &&
                            <Modal title={modalState.modalRouteParams.action === fieldAction.sign ? t('who_has_to_sign') : t('who_has_to_write')}><CreateSignerForm/></Modal>}
                        {modalState.modalRoute === modalRoutes.sign &&
                            <Modal title={t('sign_here')}><SignForm/></Modal>}
                        {modalState.modalRoute === modalRoutes.write &&
                          <Modal title={t('write_here')}><WriteForm/></Modal>}
                        {modalState.modalRoute === modalRoutes.verify &&
                          <Modal title={t('verify_phone_number')}><VerifyForm/></Modal>}
                        {modalState.modalRoute === modalRoutes.customButtons &&
                            <Modal title={t('confirm')}><CustomButtons/></Modal>}
                        {modalState.modalRoute === modalRoutes.packageSettings &&
                            <Modal title={t('personalize_invite')}><PackageSettings/></Modal>}
                        {modalState.modalRoute === modalRoutes.createLongLivedToken &&
                            <Modal title={t('create_token')}><CreateLongLivedToken/></Modal>}
                        {modalState.modalRoute === modalRoutes.userMenu &&
                            <Modal><UserMenu/></Modal>}
                        {modalState.modalRoute === modalRoutes.phoneVerification &&
                          <Modal title={t('phone_verification')}><PhoneVerification/></Modal>}
                        {modalState.modalRoute === modalRoutes.editUser &&
                            <Modal title={t('edit_my_info')} ><EditUser /></Modal>}
                    </>
                    }


                    <Route path="/" exact component={Home}/>
                    <Route path="/developers" exact component={Developers}/>
                    <Route path="/about-us" exact component={AboutUs}/>
                    <Route path="/privacy-policy" exact component={PrivacyPolicy}/>
                    <Route path="/terms-of-service" exact component={TermsOfService}/>

                    <Route path="/packages/:id/documents/:documentId/compose" exact component={ComposePackage}/>
                    <Route path="/packages/:id/sign/:token" exact component={SignPackage}/>
                    <Route path="/packages/:id/finished/:token" exact component={SingingFinished}/>
                    <Route path="/packages/:id/download/:token" exact component={PackageDownload}/>

                    <>

                        <PrivateRoute path="/packages/:status?" exact component={PackageList}/>
                        <PrivateRoute path="/packages/:id/documents/:documentId/details" exact
                                      component={PackageDetails}/>
                    </>
                </Router>
            </ToastProvider>

        </>

    );
}


const EolNotice = styled.div`
    background-color: #e52d2d;
    color: #fff;
    padding: 10px;
    font-size: 1rem;
    text-align: center;
    
    
    a {
        color: #fff;
        text-decoration: underline;
    }
`;


export default App;
