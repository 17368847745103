import React, {useState, useEffect} from 'react';
import styled from 'styled-components/macro';

import {useSelector} from "react-redux";
import {
    selectPackages,
    fetchSigners,
    deleteSigner, selectPackage, selectSignatures, selectSignatureFields, selectDocument, selectSigners
} from "../../packageSlice";

import {FaCheck, FaUser, GoTrashcan, GrDrag} from "react-icons/all";
import {useParams} from "react-router-dom";
import {useAppDispatch} from "../../../../app/store";

export function Signers() {
    const packageObject = useSelector(selectPackage);
    const documentObject = useSelector(selectDocument);
    const signers = useSelector(selectSigners);
    const signatureFields = useSelector(selectSignatureFields);
    const dispatch = useAppDispatch();
    const {id} = useParams();

    // useEffect(() => {
    //     dispatch(fetchSigners(id));
    // }, [id, dispatch]);

    const handleDeleteClick = (e: any, id: string) => {
        e.preventDefault();
        e.stopPropagation();

        dispatch(deleteSigner(
            packageObject.id,
            id,
            documentObject.id,
        ));
    }

    return (
        <Wrappper>

            {signers?.map((signer: any) => {
                return (
                    <Signer key={signer.id}>
                        <div>
                            <FaUser/>
                            <div className={"signer"}>{signer.firstName} {signer.lastName}</div>
                            <div className={"has-field"}>{signatureFields.find((f: any) => {
                                return f.signerId === signer.id
                            }) ? <div className={"icon"}><FaCheck/></div> : ''}</div>
                            <div className={"delete"} onClick={(e: any) => handleDeleteClick(e, signer.id)}>
                                <div className={"icon"}><GoTrashcan/></div>
                            </div>
                        </div>
                    </Signer>);
            })}

        </Wrappper>
    );
}


const Wrappper = styled.div`        
  flex-direction: column;  
  overflow-y: auto;
  
  @media screen and (max-width: 1000px) {
    height: 95px;
  }
  
`;

const Signer = styled.div`
  background-color: rgba(74, 74, 74, .5);  
  display: flex;  
  align-items: center;
  padding: 15px 15px 15px 15px;
  margin-bottom: 20px;
  border-radius: 8px;
  flex: 0 0 16px;
  
  @media screen and (max-width: 1000px) {
    padding: 8px 15px 8px 15px;  
    margin-bottom: 10px;
  }
  
  &:hover {
    -webkit-box-shadow: 0 0 10 -3px rgba(0,0,0,0.59); 
    box-shadow: 0 0 10 -3px rgba(0,0,0,0.59);    
  }
  
  div {    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    flex: 1;
    
      
    &.signer {
    display: flex;
      padding-left: 10px;      
      overflow: hidden;
      flex:1;       
    }
    
    .has-field, .delete {
      flex: 0 0 32px;
      display: flex;
    }

    .delete {
        .icon {
          cursor:pointer;
          color: white;                 
          align-items: center;
          justify-content: center;                     
          width: 32px;
          height: 32px;
          background: rgba(190,190,190,.5);
          border-radius: 50px;
          transition: all .3s ease-in-out;
          
          &:hover {
            background: rgba(190,190,190,.2);
          }
        }
    }
  }
`;

