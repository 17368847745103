import React, {memo} from 'react';
import styled from 'styled-components/macro';
import {modalRoutes, requestModalRoute} from "../features/modal/modalSlice";
import {useAppDispatch} from "../app/store";
import {useTranslation} from "react-i18next";

type InputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement>;

interface Props extends InputProps {
    id?: string;
    className?: string;
    text?: string;
    onClick?: (e: any) => any;
    icon?: any;
    loading?: boolean;
    confirm?: boolean;
    confirmQuestion?: string;
    confirmText?: string;
    cancelText?: string;
}

export const Button = memo(({
  style,
  id,
  className,
  loading,
  onClick,
  confirm,
  text,
  icon,
  confirmQuestion,
  confirmText,
  cancelText
}: Props) => {
        const dispatch = useAppDispatch();
        const {t} = useTranslation();

      const handleClick = (e: any) => {
            e.preventDefault();
            e.stopPropagation()

            if (loading) {
                return;
            }

            if (confirm) {
              e.preventDefault();
              dispatch(requestModalRoute(modalRoutes.customButtons, {
                description: confirmQuestion,
                cancelButton: true,
                cancelButtonText: cancelText,
                buttons: [
                  {
                    text: confirmText,
                    onClick: () => {
                      if (onClick) onClick(e);
                      dispatch(requestModalRoute(null, null));
                    }
                  },
                ]
              }));
            } else {
                if (onClick) onClick(e);
            }
        }

        return (
            <Wrapper id={id} className={`button ${className}`} style={style} onClick={handleClick}>
                <div className={"background"}></div>

                <div className={`inner ${icon ? 'hasIcon' : null}`}>
                    {icon ? <span className={"icon"}>{icon}</span> : null}
                    {text ? <span className={"text"}>{loading ? t('loading') : text}</span> : null}
                </div>

            </Wrapper>
        );
    },
);

const Wrapper = styled.div`
  display: flex;
  border-radius: 50px;
  cursor:pointer;  
  align-items: center;  
  min-width: 16px;  
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;  
  flex: 1;        
  position: relative;
  color:#333;
  
  .inner {
    display: flex;
    flex: 1;
    padding: 15px;
    z-index: 2;  
    
     
    &.hasIcon {
      .text {
        margin-left: -32px;
      }
    }
  }
  
  .background {
    z-index: 1;
    width: 100%;
    height: 100%;
    position: absolute;    
    transition: all .3s ease-in-out;
  }
  
  .sweet-alert {
    width: 32em;
  }
  
  .icon {
    font-size: 1rem;
    display:flex;
    flex: 0 0 32px;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  
  input {
    display:none;
  }
  
  span {
    flex: 1;
    text-align: center;
    display: block;   
  }
  
  background-color: rgb(250, 250, 250);
  border: 1px solid rgb(221, 221, 221);
  
   &:hover {
    background-color: rgb(245,244,244);
  }
  
  @keyframes animateButton {
    0% {
      box-shadow: 0 0 0 #fff;
    }
    25% {
      box-shadow: 0px 0px 25px #fff;
    }
    50% {
      box-shadow: 0 0 0 #fff;
    }
    75% {
      box-shadow: 0px 0px 25px #fff;
    }
  }
  
  &.primary {      
      background-image: linear-gradient(to right, rgb(53,42,180), rgb(98,84,255));
      color:white;
      border: 0;
      
      .background {
        opacity: 0;
        background-image: linear-gradient(to left, rgb(53, 42, 180), rgb(98, 84, 255));  
      }
      
      @media screen and (min-width: 1000px) {
          &:hover {
            .background {
              opacity: 1;  
            }
          }
      }
  } 
  
  &.animate {
    animation: animateButton 3s linear infinite;
  }
  
  &.disabled {
      background-image: linear-gradient(to right, rgb(148, 148, 146), darken(rgb(148, 148, 146), 10%));
      border: 0;
      cursor: default; 
      
      &:hover { 
        background-image: linear-gradient(to right, rgb(148, 148, 146), darken(rgb(148, 148, 146), 10%));
      }
  }
`;
