import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components/macro';
import {fetchPackage, fetchSigners, selectPackages} from '../packageSlice';
import {useHistory, useParams} from "react-router-dom";
import {humanFileSize} from "../../../helpers";
import {DocumentViewer} from "../../../components/DocumentViewer/DocumentViewer";
import {useAppDispatch} from "../../../app/store";
import {PackageDetailPanel} from "../../../components/PackageDetailPanel";
import {TopBar, BackButton} from "../../../components";

export function PackageDetails() {
    const packagesState = useSelector(selectPackages);
    const dispatch = useAppDispatch();
    const {id, documentId} = useParams();
    const history = useHistory()
    
    useEffect(() => {
        dispatch(fetchPackage({id: id}));
        dispatch(fetchSigners(id));
    }, [dispatch, id, documentId]);

    const handleBack = (e: any) => {
        history.push('/packages');
    }

    return (
        <Wrapper>
            <TopBar/>
            <Header>
                <BackButton onClick={handleBack} />
                <Title className={"title"}>
                    <div>{packagesState?.package?.name}</div>
                    <div className={"size"}>{humanFileSize(packagesState?.document?.size)}</div>
                </Title>
            </Header>
            <Middle>
                <Left>
                    <DocumentViewer mode="view" onSigningFieldClick={() => {}} onTextFieldClick={() => {}} className={"documentViewer"} />
                </Left>
                <Right>
                    <PackageDetailPanel/>
                </Right>
            </Middle>
        </Wrapper>
    );
}


const Wrapper = styled.div`
  display:flex;
  flex-direction: column;
  flex: 1;
  padding: 20px 30px 20px 30px;
        
   .pages {
     height: calc(100vh - 100px);
   }
  
   @media screen and (max-width: 1000px) {
    padding: 10px 15px 20px 15px;  
  }
 
`;


const Middle = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  min-height: 0;  
  
  
  
  @media screen and (max-width: 1000px) {
      flex-direction: column;        
  }
`;

const Left = styled.div`   
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
    
  .documentViewer {
    width: 100%
  }
`;

const Right = styled.div`
  flex: 0 0 400px;
  display: flex;
  flex-direction: column;  
  padding: 0 0 40px 30px;
  
  @media screen and (max-width: 1000px) {
    padding: 0;  
    flex: 0 0 55px;
  }
`;


const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Title = styled.div`
    display:flex;
    flex: 1;    
    flex-direction: column;
    padding: 15px;
    
    .size {
      font-size: 0.8rem;
      color: #888;
    }
`;