import React, {useState} from 'react';
import styled from 'styled-components/macro';
import {fetchLongLivedToken} from '../userSlice';
import {Button} from "../../../components/Button";
import {useTranslation} from "react-i18next";
import {useAppDispatch} from "../../../app/store";
import {unwrapResult} from "@reduxjs/toolkit";

export function CreateLongLivedToken() {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const [token, setToken] = useState();

    function handleCreate() {
      dispatch(fetchLongLivedToken()).then(unwrapResult).then(result => {
        setToken(result.token);
      });
    }

    return (
      <Wrapper>
        <div className={"actions"}>
          <div className={"token_info"}>
            {!token && (
              <span>Via de knop aanmaken maakt u een token aan die u kunt gebruiken om een koppeling tussen een extern pakket en Legalsign aan te leggen.</span>
            )}
            {token && (
              <>
                <span>Kopieer onderstaande code en plaatst deze in het externe pakket om een koppeling tussen beide pakketen aan te leggen.</span>
                <textarea className={"token_info_textarea"} value={token} readOnly />
              </>
            )}
          </div>
          <Button text={t(token ? 'create_again' : 'create')} className={"button primary"} onClick={handleCreate} />
        </div>
      </Wrapper>
    );
}

const Wrapper = styled.div`
    flex: 0 0 450px;
    @media screen and (max-width: 1000px) {
      flex: 1;    
    }
    
    .token_info {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
    }
    
    .token_info_textarea {
      margin-top: 20px;
      border-radius: 25px;
      padding: 20px;
      height: 75px;
      overflow: hidden;
      resize: none;
    }
    
    .actions {
      margin-bottom: 30px;
    }
`;
