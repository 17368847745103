import React, {useEffect, useState} from 'react';
import styled from 'styled-components/macro';
import {useSelector} from "react-redux";
import {fetchPackage, selectPackages, updatePackage} from "../../packageSlice";
import {useAppDispatch} from "../../../../app/store";
import {FaLock} from "react-icons/all";

export function Settings() {
    const packagesState = useSelector(selectPackages);
    const dispatch = useAppDispatch();
    const [phoneRequired, setPhoneRequired] = useState(packagesState.package?.phoneVerificationRequired);

    useEffect(() => {
      setPhoneRequired(packagesState.package?.phoneVerificationRequired);
    }, [packagesState])

    const handlePhoneRequiredToggle = () => {
        const newRequired = !phoneRequired;
        setPhoneRequired(newRequired);
        dispatch(updatePackage({
            id: packagesState.package.id,
            phoneVerificationRequired: newRequired,
            welcomeMessage: packagesState.package.welcomeMessage,
            reminderInterval: packagesState.package.reminderInterval,
            expiresAt: packagesState.package.expiresAt,
        })).then(() => {
            dispatch(fetchPackage({id: packagesState.package.id}));
        });
    }

    return (
        <Wrappper>
            <div>
                <div>
                    <h4>
                        <span>Activeer telefoonverificatie</span>
                        {packagesState?.signers.length == 0 &&
                          <Toggler className={phoneRequired ? "checked" : ""}>
                              <TogglerInner
                                type="checkbox"
                                onChange={handlePhoneRequiredToggle}
                                checked={phoneRequired} />
                          </Toggler>
                        }
                        {packagesState?.signers.length > 0 &&
                          <Toggler className={phoneRequired ? "checked disabled" : "disabled"}>
                              <TogglerInner
                                type="checkbox"
                                onChange={handlePhoneRequiredToggle}
                                checked={phoneRequired}
                                disabled />
                              <FaLock className={"icon"}/>
                          </Toggler>
                        }
                    </h4>
                    <p>
                        Wilt u tijdens het onderteken proces een extra verificatie stap toevoegen?
                        Wanneer deze instelling aan staat, moet u bij het aanmaken van een ondertekenaar het telefoonnummer opgeven,
                        dan verifiëren wij tijdens het onderteken proces dat de ondertekenaar ook daadwerkelijk in bezit is van het telefoonnummer.
                        Let op: deze instelling is alleen aan te passen voordat ondertekenaars toegevoegd zijn.
                    </p>
                </div>
            </div>
        </Wrappper>
    );
}

const Wrappper = styled.div`        
  flex-direction: column;
  
  @media screen and (max-width: 1000px) {
    height: 95px;
  }
  
  h3 {
    margin-top: 10px;
    margin-bottom: 15px;
  }
  
  h4 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
`;

const Toggler = styled.label`
    width: 50px;
    height: 25px;
    border-radius: 25px;
    display: inline-block;
    background-color: #b9b9b9;
    padding: 2px;
    position: relative;
    
    &:before {
        border-radius: 25px;
        content: "";
        position: absolute;
        left: -1px;
        top: -1px;
        right: -1px;
        bottom: -1px;
        background-image: linear-gradient(to right, rgb(53, 42, 180), rgb(98, 84, 255), rgb(53, 42, 180));
        transition: opacity .3s ease-in-out;
        opacity: 0;
        display: inline-block;
    }
    
    &.checked:before {
        opacity: 1;
    }
    
    .icon {
        width: 15px;
        height: 15px;
        z-index: 5;
        color: #7a7a7a;
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 7px;
    }
    
    &.checked .icon {
        left: 31px;
    }
    
    &:after {
        content: "";
        display: inline-block;
        width: 25px;
        height: 25px;
        border-radius: 25px;
        background-color: #f2f2f2;
        position: absolute;
        left: 3px;
        top: 50%;
        transform: translateY(-50%);
        transition: left .2s ease-in-out;
    }
    
    &.disabled:after {
        background-color: #f2f2f2;
    }
    
    &.checked:after {
        left: 26px;
    }
`

const TogglerInner = styled.input`
    opacity: 0;
`;
