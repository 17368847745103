import {Action, configureStore, getDefaultMiddleware, ThunkAction} from '@reduxjs/toolkit';
import packagesReducer from '../features/package/packageSlice';
import userReducer from '../features/user/userSlice';
import modalReducer from '../features/modal/modalSlice';
import {useDispatch} from "react-redux";

export const store = configureStore({
  reducer: {
    packages: packagesReducer,
    user: userReducer,
    modal: modalReducer
  },
  middleware: [
    ...getDefaultMiddleware({
      serializableCheck: false
    })
  ]
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
