import React, {useState} from 'react';
import styled from "styled-components/macro";
import {IconInput} from "../../../components/IconInput";
import {Button} from "../../../components/Button";
import {useToasts} from "react-toast-notifications";
import {useTranslation} from "react-i18next";
import {useAppDispatch} from "../../../app/store";
import {useSelector} from "react-redux";
import {fetchCurrentUser, selectUser, updateUser} from "../userSlice";
import {unwrapResult} from "@reduxjs/toolkit";
import {requestModalRoute} from "../../modal/modalSlice";

export function EditUser() {
  const dispatch = useAppDispatch();
  const userState = useSelector(selectUser) as any;
  const {t} = useTranslation();
  const [firstName, setFirstName] = useState(userState?.currentUser?.firstName);
  const [lastName, setLastName] = useState(userState?.currentUser?.lastName);

  const initialValidationState = {
    first_name: [] as string[],
    last_name: [] as string[],
  };

  const [validationErrors, setValidationErrors] = useState(initialValidationState);
  const {addToast} = useToasts();

  const handleFirstNameChange = (e: any) => {
    setFirstName(e.target.value);
  }

  const handleLastNameChange = (e: any) => {
    setLastName(e.target.value);
  }

  const handleSubmit = async () => {

    let errors = {};
    if (!firstName) errors = { ...errors, first_name: ['Geen voornaam ingevuld'] };
    if (!lastName) errors = { ...errors, last_name: ['Geen achternaam ingevuld'] };

    if (Object.keys(errors).length > 0) {
      setValidationErrors({...initialValidationState, ...errors});
      return;
    }

    dispatch(updateUser({
      id: userState?.currentUser?.id,
      firstName: firstName,
      lastName: lastName
    })).then(unwrapResult).then(result  => {
      if (result.error) {
        result.error === 'validation_error' ?
          setValidationErrors({...initialValidationState, ...result.validation_errors}) :
          addToast(t(result.error), {appearance: 'error', autoDismiss: true});
      } else {
        dispatch(fetchCurrentUser());
        dispatch(requestModalRoute(null, {}))
      }
    });
  }

  return (
    <Wrapper>
      <form>
        <div className={"password"}>
          <div className={"formgroup"}>
            <IconInput
              validationError={validationErrors.first_name[0]}
              placeholder={t('first_name')}
              onChange={handleFirstNameChange}
              value={firstName}
            />
          </div>
        </div>
        <div className={"password_repeat"}>
          <div className={"formgroup"}>
            <IconInput
              validationError={validationErrors.last_name[0]}
              placeholder={t('last_name')}
              onChange={handleLastNameChange}
              value={lastName}
            />
          </div>
        </div>
        <div className={"actions"}>
          <Button text={t('save')} className={"button primary"} onClick={handleSubmit}/>
        </div>
      </form>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  flex: 0 0 450px;
  @media screen and (max-width: 1000px) {
    flex: 1;    
  }
  margin-bottom: 15px;
    
  .formgroup, .actions {
      flex: 1;
      padding: 10px 0 10px 0;
      
      label {
        margin-bottom: 5px;
      }
           
      a {
          color: #5942ad;
          
          &:hover {
            text-decoration: underline;
          }
      }
    }
    
    .actions {
      text-align: center;
    }
`;