import React, {useState} from 'react';
import styled from 'styled-components/macro';

import {fetchCurrentUser, selectUser, updateUser} from '../userSlice';
import {requestModalRoute} from '../../modal/modalSlice';
import {IconInput} from "../../../components/IconInput";
import {FaLock} from "react-icons/all";
import {Button} from "../../../components/Button";
import {useTranslation} from "react-i18next";
import {unwrapResult} from "@reduxjs/toolkit";
import {useAppDispatch} from "../../../app/store";
import {useSelector} from "react-redux";
import {useToasts} from "react-toast-notifications";

export function SetPasswordForm() {
    const dispatch = useAppDispatch();
    const userState = useSelector(selectUser);
    const {t} = useTranslation();
    const [password, setPassword] = useState("");
    const [passwordRepeat, setPasswordRepeat] = useState("");

    const initialValidationState = {
        password: [] as string[],
        password_repeat: [] as string[],
    };

    const [validationErrors, setValidationErrors] = useState(initialValidationState);
    const {addToast} = useToasts();

    const handlePasswordChange = (e: any) => {
        setPassword(e.target.value);
    }

    const handlePasswordRepeatChange = (e: any) => {
        setPasswordRepeat(e.target.value);
    }

    const handleSubmit = async () => {
        if(password !== passwordRepeat) {
            const errors = { password_repeat: ['Passwords do not match.'] }
            setValidationErrors({...initialValidationState, ...errors});
            return;
        }

        // fetch fresh copy of user basd on token
        dispatch(fetchCurrentUser()).then(unwrapResult).then(user => {
            // set new password
            dispatch(updateUser({
                id: user.id,
                password: password
            })).then(unwrapResult).then(result  => {
                if (result.error) {
                    result.error === 'validation_error' ? setValidationErrors({...initialValidationState, ...result.validation_errors}) :
                        addToast(t(result.error), {appearance: 'error', autoDismiss: true});
                } else {
                    dispatch(fetchCurrentUser());
                    dispatch(requestModalRoute(null, {}))
                }

            })
        });
    }

    return (
        <Wrapper>
            <form>
                <div className={"password"}>
                    <div className={"formgroup"}>
                        <IconInput className={"password"} validationError={validationErrors.password[0]}  type={"password"} icon={<FaLock/>}
                                   placeholder={t('password')}
                                   onChange={handlePasswordChange}/>
                    </div>
                </div>
                <div className={"password_repeat"}>
                    <div className={"formgroup"}>
                        <IconInput className={"password"} validationError={validationErrors.password_repeat[0]}  type={"password"} icon={<FaLock/>}
                                   placeholder={t('password_repeat')}
                                   onChange={handlePasswordRepeatChange}/>
                    </div>
                </div>
                <div className={"actions"}>
                    <Button text={t('proceed')} className={"button primary"} onClick={handleSubmit}/>
                </div>
            </form>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    flex: 0 0 450px;
    @media screen and (max-width: 1000px) {
      flex: 1;    
    }
    margin-bottom: 15px;
    
    h1 {
      text-align: center;
      padding-bottom: 15px;
    }
    
    .name {
      display: flex;
      flex: 1;
      flex-direction: row;
      
      .formgroup:first-child {
        padding-right:10px;
      }
      .formgroup:last-child {
        padding-left:10px;
      }
    }
   
    
    .graphic {
      width:10rem;
      position: relative;
      margin: auto;
      
      img {       
        width:100%;
      }
    }
    
    .formgroup, .actions {
      flex: 1;
      padding: 10px 0 10px 0;
      
      label {
        margin-bottom: 5px;
      }
           
      a {
          color: #5942ad;
          
          &:hover {
            text-decoration: underline;
          }
      }
    }
    
    .actions {
      text-align: center;
    }
`;
