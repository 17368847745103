import React from 'react';

import styled from 'styled-components/macro';

import {TopBar} from "../../../components/TopBar";
import {Button} from "../../../components/Button";

import shuttle from "../../../assets/images/icon-rocket.svg";
import wrench from "../../../assets/images/icon-truck.svg";
import magnify from "../../../assets/images/icon-diver.svg";
import {
    AiOutlineSafetyCertificate, BiDevices,
    BiListCheck,
    CgPlayListSearch,
    FaFileContract,
    FaListAlt,
    FaLock,
    FaShieldAlt,
    FaSync, HiOutlineEmojiHappy
} from "react-icons/all";
import {Footer} from "../../../components/Footer";


export function AboutUs() {

    return (
        <Wrapper>

            <div className={"inner"}>

                <TopBar/>

                <Middle className={"odd"}>
                    <MegaTron>
                        <h1>Over Ons</h1>
                        <p>Legalsign is ontwikkeld vanuit de gedachte dat online ondertekenen veilig en gebruiksvriendelijk moet zijn. Hiertoe kiezen we voor eenvoudige integratie met bestaande CRM applicaties, de hoogste veiligheid standaarden en gemak van ondertekenen. Legalsign is onderdeel van de Digital Transformation Company en werkt met circa 60 software developers, consultants en dienstverlenende professionals aan het digitaliseren van organisaties.</p>
                    </MegaTron>

                    <Resources>
                        <div className={"resource"}>
                            <div className={"inner"}>
                                <h3>Gemakkelijk</h3>
                                <img src={shuttle} alt={"Gemakkelijk"}/>
                                <p>Eenvoudig ondertekenen via simpele drie stappen structuur op mobiel en web.</p>
                                {/*<Button text={"Get started"} onClick={() => {}}/>*/}
                            </div>
                        </div>

                        <div className={"resource"}>
                            <div className={"inner"}>
                                <h3>Veilig</h3>
                                <img src={wrench} alt={"Veilig"}/>
                                <p>Alle data wordt gehost op ISO 27001 gecertificeerde infrastructuur.</p>
                                {/*<Button text={"Get your library"} onClick={() => {}}/>*/}
                            </div>
                        </div>

                        <div className={"resource"}>
                            <div className={"inner"}>
                                <h3>Integreerbaar</h3>
                                <img src={magnify} alt={"Integreerbaar"}/>
                                <p>Eenvoudig te integreren met je CRM applicatie of andere sotware.</p>
                                {/*<Button text={"Visit the reference"} onClick={() => {}}/>*/}
                            </div>
                        </div>
                    </Resources>
                </Middle>

                <Middle className={"even"}>
                    <MegaTron>
                        <h1>Gemakkelijk</h1>
                        <p>Ons doel is om de eenvoudigste ondertekenservice beschikbaar te maken.</p>
                    </MegaTron>

                    <Resources>
                        <div className={"resource"}>
                            <div className={"inner dark"}>
                                <h3>Werkt op alle apparaten</h3>
                                <BiDevices className={"icon"}/>
                                <p>Werkt op alle apparaten zonder installatie van software.</p>
                            </div>
                        </div>

                        <div className={"resource"}>
                            <div className={"inner dark"}>
                                <h3>Onderteken-volgorde</h3>
                                <FaListAlt className={"icon"}/>
                                <p>Bepaal zelf de volgorde waarin ondertekenaars ondertekenen.</p>
                            </div>
                        </div>

                        <div className={"resource"}>
                            <div className={"inner dark"}>
                                <h3>Eenvoudig<br/> proces</h3>
                                <FaFileContract className={"icon"}/>
                                <p>Drie simpele stappen: bekijken, ondertekenen en ontvangen.</p>
                                {/*<Button text={"Visit the reference"} onClick={() => {}}/>*/}
                            </div>
                        </div>
                    </Resources>

                    <Resources>
                        <div className={"resource"}>
                            <div className={"inner dark"}>
                                <h3>Document synchronisatie</h3>
                                <FaSync className={"icon"}/>
                                <p>Documenten worden veilig opgeslagen in je CRM.</p>
                            </div>
                        </div>

                        <div className={"resource"}>
                            <div className={"inner dark"}>
                                <h3>Overzichtelijk activiteitenlog</h3>
                                <CgPlayListSearch className={"icon"}/>
                                <p>Krijg snel inzicht in de status van je onderteken verzoeken.</p>
                            </div>
                        </div>

                        <div className={"resource"}>
                            <div className={"inner dark"}>
                                <h3>Automatische herinneringen</h3>
                                <FaLock className={"icon"}/>
                                <p>Drie simpele stappen: bekijken, ondertekenen en ontvangen.</p>
                                {/*<Button text={"Visit the reference"} onClick={() => {}}/>*/}
                            </div>
                        </div>
                    </Resources>

                </Middle>

                <Middle className={"odd"}>
                    <MegaTron>
                        <h1>Veilig</h1>
                        <p>Safety first, je documenten staan veilig opgeborgen.</p>
                    </MegaTron>

                    <Resources>
                        <div className={"resource"}>
                            <div className={"inner dark"}>
                                <h3>ISO</h3>
                                <FaLock className={"icon"}/>
                                <p>Alle data wordt gehost op ISO 27001 gecertificeerde infrastructuur.</p>
                                {/*<Button text={"Get started"} onClick={() => {}}/>*/}
                            </div>
                        </div>

                        <div className={"resource"}>
                            <div className={"inner dark"}>
                                <h3>AVG</h3>
                                <BiListCheck className={"icon"}/>
                                <p>Download getekende documenten via een beveiligd portaal.</p>
                            </div>
                        </div>

                        <div className={"resource"}>
                            <div className={"inner dark"}>
                                <h3>Digitaal certificaat</h3>
                                <AiOutlineSafetyCertificate className={"icon"}/>
                                <p>Alle documenten worden getekend met een door Adobe gecertificeerd digitaal certificaat.</p>
                                {/*<Button text={"Visit the reference"} onClick={() => {}}/>*/}
                            </div>
                        </div>
                    </Resources>
                </Middle>


                <Footer />
            </div>
        </Wrapper>
    );
}

const Wrapper = styled.div`   
    width: 100vw;
    height: 100vh;
    overflow: auto;
    background: black;
    
  
    .inner {
      padding: 20px 30px 20px 30px;      
      position: relative;
      margin: 0 auto;
      z-index: 1;
      text-align: center;
      
      &.dark {
        background: black;
      }
      
      .icon {
        font-size: 5rem;
        margin: 20px 0 20px 0;
      }
    }
    
     .lightspeed {
      position: fixed;
      height: 100vh;
      width: 100vw;
      overflow: hidden;
      z-index: 0;
    }    
`;

const Middle = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;     
    padding: 30px; 
    border-radius: 16px;
    
    &.odd {
      background-image: url('/img/background.png');
      box-shadow:inset 0 0 0 2000px rgba(0, 0, 0, 0.4);
    }
    
`;

const MegaTron = styled.div`
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    position: relative;
    
    h1 {
      font-size: 4rem;
      margin-bottom: 30px;
    }
    
    p {
      font-size: 1.2rem;
      max-width: 1200px;
      line-height: 2rem;      
    }
    
    .button {
      margin-top: 30px;   
      max-width: 300px; 
    }
`;

const Resources = styled.div`
  display: flex;
  flex-direction: row;  
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  flex: 1;
  

  .resource {
      //background-color: rgba(74,74,74,.5);
      background-image: linear-gradient(to right, rgb(53, 42, 180), rgb(98, 84, 255));      
      margin: 20px;
      border-radius: 16px;
      padding: 3px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 0 0 250px;
      transition: all .3s ease-in-out;
      cursor: pointer;
      
      .inner {
       
        padding: 15px 20px 15px 20px;
        border-radius: 16px;
        
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex: 1;
      }
      
      &:hover {
        transform: scale(1.02);
      }
      
      img {
        height: 150px;
      }
      
      h3 {
        font-size: 1.5rem;
      }
      p {
        display: block;
        text-align: center;
        min-height: 50px;
      }
      
  }
`;
