import React, {memo} from 'react';
import useDigitInput from 'react-digit-input';
import styled from "styled-components";

interface Props {
    value: string;
    onChange: (value: string) => any
}

export const DigitInput = memo(({value, onChange, ...restOf}: Props) => {
    const digits = useDigitInput({
        acceptedCharacters: /^[0-9]$/,
        length: 6,
        value,
        onChange,
    });


    return (
        <Wrapper>
            <div className="input-group">
                <input inputMode="decimal" data-lpignore="true" autoFocus {...digits[0]} autoComplete={"off"} />
                <input inputMode="decimal" data-lpignore="true" {...digits[1]} autoComplete={"off"} />
                <input inputMode="decimal" data-lpignore="true" {...digits[2]} autoComplete={"off"} />
                <span className="hyphen"/>
                <input inputMode="decimal" data-lpignore="true" {...digits[3]} autoComplete={"off"} />
                <input inputMode="decimal" data-lpignore="true" {...digits[4]} autoComplete={"off"} />
                <input inputMode="decimal" data-lpignore="true" {...digits[5]} autoComplete={"off"} />
            </div>
        </Wrapper>
    );
});

const Wrapper = styled.div`
  .input-group {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 -10px 0 -10px;
    
    input {
        border: 2px solid #aaa;
        border-radius: 4px;
        width: 60px;
        height: 80px;
        margin: 10px;
        text-align: center;
        font-size: 30px;
    }
    
    .hyphen {
      height: 4px;
      background-color: #bbb;
      width: 20px;
    }
  }
`;
