import React, {memo} from 'react';
import styled from 'styled-components/macro';

type InputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement>;

interface Props extends InputProps {

}

export const ModalFooter = memo(
    ({children}: Props) => {
        return (
            <Wrapper>
                {children}
            </Wrapper>
        );
    },
);

const Wrapper = styled.div`
    background-color: #E2E2F3;
    margin-left: -30px;
    margin-right: -30px;
    padding: 30px;
    margin-top: 10px;
    text-align: center;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    
    @media screen and (max-width: 1000px) {
    margin-left: -15px;
      margin-right: -15px;
    }
    
        
      a {
        color: #333;
      }
`