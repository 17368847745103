import React, {useEffect, useRef} from 'react';
import styled from 'styled-components/macro';

import {useSelector} from "react-redux";

import {Button} from "../../../../components/Button";
import {isMobile} from "../../../../helpers";
import {fetchCurrentSigner, selectUser} from "../../../user/userSlice";
import SignaturePad from "signature_pad";
import {modalRoutes, requestModalRoute, selectModal} from "../../../modal/modalSlice";
import {
    createSignature,
    createSignatureField, createSigner, deleteSignature, fetchPackage, fetchSignatureFields,
    selectPackages,
    unsetActiveField
} from "../../packageSlice";
import {useAppDispatch} from "../../../../app/store";
import {unwrapResult} from "@reduxjs/toolkit";
import {FaTrash} from "react-icons/all";

export function SignForm() {
    const dispatch = useAppDispatch();
    const modalState = useSelector(selectModal);
    const userState = useSelector(selectUser);
    const packageState = useSelector(selectPackages);

    let canvas = useRef(null);
    let signaturePad = useRef(null);


    useEffect(() => {
        const routeParams = modalState.modalRouteParams;

        // when the signer is a user, there is no signer.
        if (!modalState.modalRouteParams.isCreator) {
            dispatch(fetchCurrentSigner({
                packageId: routeParams.packageId,
            }));
        }

        if (canvas.current) {
            // @ts-ignore
            signaturePad.current = new SignaturePad(canvas.current, {
                backgroundColor: 'rgb(255, 255, 255)',
                minWidth: 2,
                maxWidth: 4,
                onEnd: (event: any) => {
                    // @ts-ignore
                    localStorage.setItem('signature_svg', signaturePad.current.toDataURL('image/svg+xml'));
                    // @ts-ignore
                    localStorage.setItem('signature', JSON.stringify(signaturePad.current.toData()));
                },
                width: 1000,
                height: 1000
            });
            const storedSignature = localStorage.getItem('signature');
            if (storedSignature) {
                //@ts-ignore
                signaturePad.current.fromData(JSON.parse(storedSignature))
            }

        }
    }, [dispatch, canvas]);


    const handleClearClick = () => {
        // @ts-ignore
        signaturePad.current.clear();
    }

    const handleSubmitClick = () => {

        let signature = localStorage.getItem('signature_svg')?.split(',')[1] ?? ''; // we only need the base64
        signature = atob(signature);
        const pattern = /(viewBox="[^"]*")[^>]/gm;
        signature = signature.replace(pattern, ` viewBox="0 0 ${isMobile() ? (window.outerWidth - 70) : 800} ${isMobile() ? (window.outerWidth - 70) / 1.77777 : 800 / 1.7777}" `);

        const routeParams = modalState.modalRouteParams;
        // if the form was called by the creator of a package, creation of the field was postponed up until
        // now and needs to be created here, also we need to pass a special param to the create signature API
        // call so the API knows it needs to create the signature for a user, not a signer
        if (routeParams.isCreator) {
            const userSigner = packageState.signers.find((signer: any) => {
                if (signer.email === userState?.currentUser?.email) {
                    return signer;
                }
            });

            // if the signer exists already, don't recreate
            if (userSigner !== undefined) {
                dispatchCreateSignatureField(routeParams, userSigner.id).then(unwrapResult).then(result => {
                    dispatchCreateSignature(signature, result.id);
                });
                return;
            }

            dispatch(createSigner({
                packageId: routeParams.packageId,
                firstName: userState?.currentUser?.firstName ?? "",
                lastName: userState?.currentUser?.lastName ?? "",
                email: userState?.currentUser?.email ?? "",
            })).then(unwrapResult).then(result => {
                dispatchCreateSignatureField(routeParams, result.id).then(unwrapResult).then(result => {
                    dispatchCreateSignature(signature, result.id);
                });
            });

        } else {
            dispatchCreateSignature(signature, routeParams.signatureFieldId);
        }
    }

    const dispatchCreateSignatureField = (routeParams: any, signerId: string) => {
        return dispatch(createSignatureField({
            packageId: routeParams.packageId,
            documentId: routeParams.documentId,
            signerId: signerId,
            pageId: routeParams.pageId,
            top: routeParams.top,
            left: routeParams.left,
            width: routeParams.width,
            height: routeParams.height
        }));
    }

    const dispatchCreateSignature = async (signature: string, fieldId: string) => {
        const routeParams = modalState.modalRouteParams;
        const oldSignature = packageState.signatures.find(s => s.signatureField.id === fieldId);
        const packageId = routeParams.packageId;
        const signatureParams = {
            packageId: packageId,
            signatureFieldId: fieldId,
            signature: btoa(signature),
        }

        if (oldSignature) {
            await dispatch(deleteSignature({packageId: packageId, signatureId: oldSignature.id}))
        }
        const result = await dispatch(createSignature(signatureParams));
        await dispatch(unsetActiveField());

        if (packageState.package.status !== "Draft" && result && packageState.package.phoneVerificationRequired) {
            dispatch(requestModalRoute(modalRoutes.verify, {
                packageId: packageId
            }));
        } else {
            dispatch(requestModalRoute(null, {}));
        }
    }

    return (
        <Wrappper>
            <canvas
                id={"signature-pad"}
                ref={canvas}
                width={isMobile() ? (window.outerWidth - 70) : 800}
                height={isMobile() ? (window.outerWidth - 70) / 1.77777 : 800 / 1.77777}
            />

            <div className={"actions"}>
                <Button text={isMobile() ? '' : "Opnieuw"} icon={<FaTrash/>} className={"delete"} onClick={handleClearClick}/>
                <Button text={"Handtekening gebruiken"} onClick={handleSubmitClick} className={"primary"}/>
            </div>
        </Wrappper>
    );
}


const Wrappper = styled.div`      
    flex: 0 0 450px;    
    padding-bottom: 20px;    
    min-width: 600px;
    
    @media screen and (max-width: 1000px) {
      min-width: auto;            
      padding-bottom: 10px;
    }
    
   #signature-pad {
      border: 1px solid #ccc;
      margin: 0 0 20px 0;
      border-radius: 5px;
   }
    
    h1 {      
      padding-bottom: 15px;
      font-size: 22px;
      font-weight: normal;      
      margin-top: -30px;
    }
  
  .help {
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff;
    
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem;
  }
  
  .name {
    display: flex;
    flex-direction: row;
    
    .formgroup {
      &:first-child {
        padding-right: 5px;
      }
      &:last-child {
        padding-left: 10px;
      }
    }
  }
  
  
  .formgroup {      
      flex: 1;  
      padding-bottom: 20px;
  }
  
  h5 {
    font-size: 0.9rem;
    padding-bottom: 10px;
  }
  
  .actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 0 -10px 0 -10px;
    
    .button {
      margin: 5px;
      
      @media screen and (max-width: 1000px) {
        &.delete {
          flex: 0 0 62px;
        }
      }
    }
  }
`;
