import BaseTransformer from "./BaseTransformer";
import DocumentTransformer from "./DocumentTransformer";
import SignatureFieldTransformer from "./SignatureFieldTransformer";
import UserTransformer from "./UserTransformer";
import TextFieldTransformer from "./TextFieldTransformer";

export default class PackageTransformer extends BaseTransformer {
    public static transform(data: any): any {
        return {
            id: data.id,
            name: data.name,
            status: data.status,
            documentCount: data.documentCount,
            signerCount: data.signerCount,
            signatureFieldCount: data.signatureFieldCount,
            signatureCount: data.signatureCount,
            textFieldCount: data.textFieldCount,
            textBodyCount: data.textBodyCount,
            user: data.user ? UserTransformer.transform(data.user) : null,
            documents: DocumentTransformer.transformList(data.documents),
            signatureFields: SignatureFieldTransformer.transformList(data.signatureFields),
            textFields: TextFieldTransformer.transformList(data.textFields),
            createdAt: data.createdAt ?? null,
            expiresAt: data.expiresAt ?? null,
            updatedAt: data.updatedAt ?? null,
            canceledAt: data.canceledAt ?? null,
            nextReminderAt: data.nextReminderAt ?? null,
            reminderInterval: data.reminderInterval ?? null,
            totalReminders: data.totalReminders ?? null,
            language: data.language ?? null,
            previewToken: data.previewToken ?? null,
            welcomeMessage: data.welcomeMessage ?? null,
            anonymousToken: data.anonymousToken ?? null,
            packageObjectToken: data.packageObjectToken ?? null,
            phoneVerificationRequired: data.phoneVerificationRequired ?? null,
        }
    }
}
