import React from 'react';

import {useTranslation} from "react-i18next";
import {useAppDispatch} from "../../../../app/store";
import {useSelector} from "react-redux";
import {modalRoutes, requestModalRoute, selectModal} from "../../../modal/modalSlice";
import {FaFont, FaSignature} from "react-icons/all";
import styled from "styled-components/macro";
import {fieldAction} from "./AddSigner";

export function AddField() {
    const dispatch = useAppDispatch();
    const modalState = useSelector(selectModal);
    const {t} = useTranslation();

    const handleAddSigner = () => {
        dispatch(requestModalRoute(modalRoutes.addSigner, {
            top: modalState.modalRouteParams.top,
            left: modalState.modalRouteParams.left,
            pageId: modalState.modalRouteParams.pageId,
            action: fieldAction.sign
        }));
    }

    const handleAddText = () => {
        dispatch(requestModalRoute(modalRoutes.addSigner, {
            top: modalState.modalRouteParams.top,
            left: modalState.modalRouteParams.left,
            pageId: modalState.modalRouteParams.pageId,
            action: fieldAction.text
        }));
    }

    return (
        <Wrapper>
            <div className={"options"}>
                <div className={"option"} onClick={handleAddSigner}>
                    <FaSignature className={"icon"}/>
                    <p>{(t('add_signer'))}</p>
                </div>
                <div className={"option"} onClick={handleAddText}>
                    <FaFont className={"icon"}/>
                    <p>{(t('add_text'))}</p>
                </div>
            </div>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    flex: 0 0 450px;   
    min-width: 600px;
    padding-bottom: 10px;
    
    @media screen and (max-width: 1000px) {
      min-width: auto;
    }

    h1 {
      padding-bottom: 15px;
      font-size: 22px;
      font-weight: normal;
      margin-top: -30px;
    }

    .options {
      display: flex;
      margin: 10px -15px 0px -15px;
      
      @media screen and (max-width: 1000px) {
          max-height: 50vh;
          overflow-y: scroll;
          flex-direction: column;
      }

      .option {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        background-color: #eee;
        height: 100px;
        flex: 1;
        margin: 15px;
        font-size: 18px;
        padding: 15px;
        cursor: pointer;
        transition: all .2s ease-in-out;
             
        &:hover {
          background-color: #ddd;
        }


        .icon {
          margin-bottom: 20px;
        }
        
        @media screen and (max-width: 1000px) {
          flex-direction: row;
          height: 25px;
          margin-bottom: 5px;
          justify-content: flex-start;
          
          .icon {
            margin: 0 10px 0 0;
          }
        }

      }
    }

`;
