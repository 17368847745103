import React from 'react';
import styled from 'styled-components';
import logo from '../assets/images/logo.svg';
import {useHistory} from "react-router";

export const Logo = () => {
    const history = useHistory();

    return (
        <Wrapper className={"logo"}>
            <a onClick={() => {
                history.push('/')
            }}>
                <img src={logo} alt={""}/>
            </a>
        </Wrapper>
    );
}

const Wrapper = styled.div`
  display: flex;  
  align-items: center;
  cursor: pointer;  
  
  a {
    display: flex;  
      align-items: center;
      flex: 1;
      cursor: pointer;
  }
  
  img {
    height: 30px;
  }

  flex: 0 0 200px;
  justify-content: center;
  
  @media screen and (max-width: 1000px) {
    flex: 0 0 100px;
    
    img {
      height: 25px;
    }
  }
    
`;
