import React, { memo } from 'react';
import styled from 'styled-components/macro';
import { FaSearch } from 'react-icons/fa';
import {IconInput} from "./IconInput";


interface Props {
    id: string;
    className?: string;
    placeholder?: string;
    onChange: (value: string) => any;
}

let interKeyTimer : any = null;

export const SearchBar = memo(
    ({ id, className, placeholder, onChange, ...restOf }: Props) => {

        const handleChange = (e : any) => {
            clearTimeout(interKeyTimer);
            interKeyTimer = setTimeout(() => {
                onChange(e.target.value);
            }, 200)
        }

        return (

            <Wrapper className={className} id={id}>
                <IconInput id={"search"} placeholder={placeholder} icon={<FaSearch/>} onChange={handleChange}/>
            </Wrapper>
        );
    },
);

const Wrapper = styled.div`
    
`;
