import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import styled from 'styled-components/macro';

import {
    fetchPackage,
    selectPackages,
} from '../packageSlice';

import {useParams} from "react-router-dom";

import {humanFileSize} from "../../../helpers";
import {useTranslation} from "react-i18next";
import {FaDownload} from "react-icons/all";
import {config} from "../../../app/config";
import {DocumentViewer} from "../../../components/DocumentViewer/DocumentViewer";
import {packageStatuses} from "../packageStatuses";
import {Button} from "../../../components/Button";
import {TopBar} from "../../../components";

export function PackageDownload() {
    const packagesState = useSelector(selectPackages);
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {id, token} = useParams();

    useEffect(() => {
        localStorage.setItem('_signingToken.' + id, token);

        dispatch(fetchPackage({id: id}));
    }, [dispatch, id, token]);

    const handleDownload = (e: any) => {
        e.preventDefault();
        const url = config.api_base_url + "/api/packages/" + packagesState.package.id + "/documents/" +
            packagesState.document.id + "/download?token=" + packagesState.document.previewToken;

        window.open(url)
    }

    const packageStatus = packagesState?.package?.status ?? null;

    return (
        <Wrapper>
            <TopBar/>
            <Header>
                <Title className={"title"}>
                    <div>{packagesState?.package?.name}</div>
                    <div className={"size"}>{humanFileSize(packagesState?.document?.size)}</div>
                </Title>
            </Header>
            <Middle>
                <Left>
                    <DocumentViewer mode="view" className={"documentViewer"} onSigningFieldClick={() => {}} onTextFieldClick={() => {}}/>
                </Left>
                <Right>
                    <div className={"details"}>
                        <div className={"field title"}>
                            <label>{t('details')}</label>
                        </div>
                        <div className={"field"}>
                            <label>{t('status')}</label>
                            <span>
                                {packagesState?.package?.status &&
                                <span className={"badge"}>{packageStatus && t(packageStatus)}</span>}
                            </span>
                        </div>
                        <div className={"field"}>
                            <label>{t('creation_date')}</label>
                            <span>{new Date(packagesState?.package?.createdAt).toLocaleDateString()}</span>
                        </div>
                    </div>


                    <div className={"actions"}>
                        {packagesState?.package?.status === packageStatuses.Completed &&
                        <Button icon={<FaDownload/>} className={"primary"} onClick={handleDownload} text={t('download')} />
                        }
                    </div>
                </Right>
            </Middle>
        </Wrapper>
    );
}


const Wrapper = styled.div`
  display:flex;
  flex-direction: column;
  flex: 1;
  padding: 20px 30px 20px 30px;
  
  @media screen and (max-width: 1000px) {
    padding: 20px 15px 20px 15px;  
  }
        
   .pages {
     height: calc(100vh - 100px);
   }
  
`;


const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Title = styled.div`
    display:flex;
    flex: 1;    
    flex-direction: column;
    padding: 15px 0 15px 0;
    
    .size {
      font-size: 0.8rem;
      color: #888;
    }
`;


const Middle = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  
  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
`;

const Left = styled.div`   
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Right = styled.div`  
  flex: 0 0 400px;
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  
  @media screen and (max-width: 1000px) {
    padding: 0;
    
    flex: 0 0 80px;
    
    .details {
      display: none;      
    }
  }
  
  .details {
      background-color: rgba(74,74,74,.5);
      border-radius: 8px;
      padding: 15px;     
  }
  
   
  .title {
    padding: 0 0 15px 0 !important;
    
    label {
        font-weight: bold;            
    }
  }

  .field {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0 10px 0;
    
    &:last-child {
      padding: 0;
    }
    
    label {
      display: flex;
      flex: 1;
    }
    
    span {
      flex: 0 0 50%;
    }       
  }
  
  .actions {
    padding-top: 30px;
  }
  
`;
