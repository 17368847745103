import React, {memo} from 'react';
import styled from 'styled-components/macro';

import {UploadButton} from '../../../components';

import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {
    selectPackages,
} from '../packageSlice';

import {Link, useParams} from "react-router-dom";
import {packageStatuses} from "../packageStatuses";

interface Props {
    className?: string;
}

export const PackageFilter = memo(({className}: Props) => {
    const {t} = useTranslation();
    const {status} = useParams();
    const packagesState = useSelector(selectPackages);

    return (
        <Wrapper className={className}>

            <Link to={"/packages"} className={status == null ? "active" : ""}>{t('all_transactions')} <span className={"badge"}>{packagesState.packageStats.all}</span></Link>
            <Link to={"/packages/" + packageStatuses.Draft} className={status === packageStatuses.Draft ? "active" : ""}>{t('draft_transactions')} <span className={"badge"}>{packagesState.packageStats.draft}</span></Link>
            <Link to={"/packages/" + packageStatuses.InProgress} className={status === packageStatuses.InProgress ? "active" : ""}>{t('waiting_for_signatures_transactions')} <span className={"badge"}>{packagesState.packageStats.inProgress}</span></Link>
            <Link to={"/packages/" + packageStatuses.Completed} className={status === packageStatuses.Completed ? "active" : ""}>{t('complete_transactions')} <span className={"badge"}>{packagesState.packageStats.completed}</span></Link>
            <Link to={"/packages/" + packageStatuses.Canceled} className={status === packageStatuses.Canceled ? "active" : ""}>{t('canceled_transactions')} <span className={"badge"}>{packagesState.packageStats.canceled}</span></Link>
        </Wrapper>
    );
});

const Wrapper = styled.div`
  display: flex;  
  flex-direction: row;  
  
   
  a {
    cursor:pointer;
    display: flex;    
    flex-direction: row;    
    color: white;
    padding: 12px 15px 12px 15px;
    border-radius: 50px;
    margin-right: 15px;
    height: 15px;
    transition: all .3s ease-in-out;
    
    .badge {
      background-image: linear-gradient(to right, rgb(53, 42, 180), rgb(98, 84, 255));
      display: flex;
      align-items: center;
      justify-content: center;
      color:white;
      border-radius: 50px;
      min-width: 22px;
      height: 22px;
      padding: 2px;
      margin-top: -5px;
      margin-right:-8px;
      margin-left: 10px;
      font-size: 0.8em;
    }
    
    
    &:hover {
      background-image: none;
      background-color: rgba(255,255,255,0.2);
    }
    
    &.active {
      background-image: linear-gradient(to right, rgb(53, 42, 180), rgb(98, 84, 255));
      
      .badge {
        background-image: none;
        background-color: white;
        color: #6254FF;
      } 
    }
  }
`;
