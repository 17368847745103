import React, {useState} from 'react';
import styled from 'styled-components/macro';
import graphic from '../../../assets/images/icon-rocket.svg';

import {registerUser} from '../userSlice';
import {modalRoutes, requestModalRoute} from '../../modal/modalSlice';
import {IconInput} from "../../../components/IconInput";
import {Button} from "../../../components/Button";
import {useTranslation} from "react-i18next";
import {unwrapResult} from "@reduxjs/toolkit";
import {useAppDispatch} from "../../../app/store";
import {useToasts} from "react-toast-notifications";
import {ModalFooter} from "../../../components/ModalFooter";

export function RegisterForm() {
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const {addToast} = useToasts();

    const initialValidationState = {
        firstName: [],
        lastName: [],
        email: [],
        password: []
    };

    const [validationErrors, setValidationErrors] = useState(initialValidationState);

    const handleFirstNameChange = (e: any) => {
        setFirstName(e.target.value);
    }

    const handleLastNameChange = (e: any) => {
        setLastName(e.target.value);
    }

    const handleEmailChange = (e: any) => {
        setEmail(e.target.value);
    }

    const handleSubmit = () => {
        dispatch(registerUser({
            firstName: firstName,
            lastName: lastName,
            email: email,
        })).then(unwrapResult).then(result => {
            if (result.error) {
                result.error === 'validation_error' ? setValidationErrors({...initialValidationState, ...result.validation_errors}) :
                    addToast(t(result.error), {appearance: 'error', autoDismiss: true});
                return;
            }
            dispatch(requestModalRoute(modalRoutes.validate, {}));
        })
    }

    const handleLoginClick = (e: any) => {
        e.preventDefault();
        dispatch(requestModalRoute(modalRoutes.login, {}));
    }

    return (
        <Wrapper>
            <form>
                <div className={"graphic"}>
                    <img src={graphic}/>
                </div>
                <div className={"name"}>
                    <div className={"formgroup"}>
                        <IconInput className={"firstname"} validationError={validationErrors.firstName[0]}
                                   required={true}
                                   placeholder={t('first_name')}
                                   onChange={handleFirstNameChange}/>
                    </div>
                    <div className={"formgroup"}>
                        <IconInput className={"lastname"} validationError={validationErrors.lastName[0]} required={true}
                                   placeholder={t('last_name')}
                                   onChange={handleLastNameChange}/>
                    </div>
                </div>
                <div className={"email"}>
                    <div className={"formgroup"}>
                        <IconInput className={"email"} validationError={validationErrors.email[0]} type={"email"}
                                   placeholder={t('emailaddress')}
                                   onChange={handleEmailChange}/>
                    </div>
                </div>
                <div className={"actions"}>
                    <div className={"buttons"}>
                        <Button text={t('proceed')} className={"button primary"} onClick={handleSubmit}/>
                    </div>
                </div>
            </form>

            <ModalFooter>
                <span><a href="#" onClick={handleLoginClick}>{t('already_account')}</a></span>
            </ModalFooter>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    flex: 0 0 450px;
    @media screen and (max-width: 1000px) {
      flex: 1;    
    }
    
    h1 {
      text-align: center;
      padding-bottom: 15px;
      font-size: 22px;
      font-weight: normal;      
    }
    
    .name {
      display: flex;
      flex: 1;
      flex-direction: row;
      
      .formgroup:first-child {
        padding-right:10px;
      }
      .formgroup:last-child {
        padding-left:10px;
      }
      
      @media screen and (max-width: 1000px) {
        flex-direction: column;
        
           .formgroup:first-child {
            padding-right:0;
          }
          .formgroup:last-child {
            padding-left:0;
          }
      }
    }
   
    
    .graphic {
      width:10rem;
      position: relative;
      margin: auto;
      padding-bottom: 20px;
      
      @media screen and (max-width: 1000px) {
        display: none;
      }
      
      img {       
        width:100%;
      }
    }
    
    .formgroup, .actions {
      flex: 1;
      padding: 10px 0 10px 0;
      
      label {
        display: block;
        color: #646464;
        padding-bottom: 10px;
      }
           
      a {
          color: #5942ad;
          
          &:hover {
            text-decoration: underline;
          }
      }
    }
    
    .actions {
      display: flex;
      flex-direction: row;     
      align-items: center;
      justify-content: center;
      
      .buttons {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
      }
    }
`;
