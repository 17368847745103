import React, {useEffect, useState} from 'react';
import styled from 'styled-components/macro';
import {useSelector} from "react-redux";
import {Button} from "../../../../components/Button";
import {fetchCurrentSigner, selectUser} from "../../../user/userSlice";
import {requestModalRoute, selectModal} from "../../../modal/modalSlice";
import {
  createSigner,
  createTextField, fetchSignatureFields, fetchTextFields,
  selectPackages, unsetActiveField,
  updateTextField
} from "../../packageSlice";
import {useAppDispatch} from "../../../../app/store";
import {unwrapResult} from "@reduxjs/toolkit";

export function WriteForm() {
  const dispatch = useAppDispatch();
  const modalState = useSelector(selectModal);
  const userState = useSelector(selectUser);
  const packageState = useSelector(selectPackages);
  const [textData, setTextData] = useState('');
  const [placeholder, setPlaceholder] = useState('');

  useEffect(() => {
    const routeParams = modalState.modalRouteParams;

    // when the signer is a user, there is no signer.
    if (!modalState.modalRouteParams.isCreator) {
      dispatch(fetchCurrentSigner({
        packageId: routeParams.packageId,
      }));
    }

    if (modalState.modalRouteParams.field) {
      setTextData(modalState.modalRouteParams.field.textBody);
      setPlaceholder(modalState.modalRouteParams.field.placeholder);
    }
  }, [dispatch]);

  const handleSubmitClick = () => {
    const routeParams = modalState.modalRouteParams;
    if (routeParams.isCreator) {
      const userSigner = packageState.signers.find((signer: any) => {
        if (signer.email === userState?.currentUser?.email) {
          return signer;
      }
});

      // if the signer exists already, don't recreate
      if (userSigner !== undefined) {
        dispatchCreateTextField(routeParams, userSigner.id, textData, placeholder);
        return;
      }

      dispatch(createSigner({
        packageId: routeParams.packageId,
        firstName: userState?.currentUser?.firstName ?? "",
        lastName: userState?.currentUser?.lastName ?? "",
        email: userState?.currentUser?.email ?? "",
      })).then(unwrapResult).then(result => {
        dispatchCreateTextField(routeParams, result.id, textData, placeholder).then(unwrapResult);
      });

    } else {
      dispatchUpdateTextField(
        routeParams.field.id,
        routeParams,
        routeParams.field.signer.id,
        textData
      );
    }
  }

  const dispatchCreateTextField = (routeParams: any, signerId: string, textBody: string, placeholder: string) => {
    dispatch(unsetActiveField());
    dispatch(requestModalRoute(null, {}));

    return dispatch(createTextField({
      packageId: routeParams.packageId,
      documentId: routeParams.documentId,
      signerId: signerId,
      pageId: routeParams.pageId,
      top: routeParams.top,
      left: routeParams.left,
      width: routeParams.width,
      height: routeParams.height,
      textBody,
      placeholder
    }));
  }

  const dispatchUpdateTextField = async (id: string, routeParams: any, signerId: string, textBody: string) => {
    await dispatch(updateTextField({
      id: id,
      packageId: routeParams.packageId,
      documentId: routeParams.documentId,
      signerId: signerId,
      pageId: routeParams.pageId,
      top: routeParams.field.top,
      left: routeParams.field.left,
      width: routeParams.field.width,
      height: routeParams.field.height,
      textBody,
      placeholder
    })).then(unwrapResult);

    dispatch(fetchTextFields(routeParams.packageId, routeParams.documentId));
    dispatch(unsetActiveField());
    dispatch(requestModalRoute(null, {}));
  }

  return (
    <Wrappper>
      {userState.currentUser?.id !== modalState.modalRouteParams?.signerId && !modalState.modalRouteParams.isMe && (
        <input value={placeholder} type={"text"} placeholder={"Naam van het veld"} onChange={(e) => {
          setPlaceholder(e.target.value)
        }}/>
      )}
      {!userState.currentUser && userState.currentSigner?.id === modalState.modalRouteParams?.field?.signerId && placeholder && (
        <div className={"placeholder"}>{placeholder}</div>
      )}

      {(modalState.modalRouteParams.isMe || userState.currentSigner?.id === modalState.modalRouteParams?.field?.signerId) && (
        <input value={textData} type={"text"} placeholder={"Waarde van het veld"} onChange={(e) => {
          setTextData(e.target.value)
        }}/>
      )}

      <div className={"actions"}>
        <Button text={"Opslaan"} onClick={handleSubmitClick} className={"primary"}/>
      </div>
    </Wrappper>
  );
}


const Wrappper = styled.div`      
    flex: 0 0 450px;    
    padding-bottom: 20px;    
    min-width: 600px;

    @media screen and (max-width: 1000px) {
      min-width: auto;            
      padding-bottom: 10px;
    }
    
    input:focus {
        outline: none;        
    }

    input {   
        padding: 5px 10px;
        color: rgb(33, 33, 33);    
        line-height: 24px;    
        box-shadow: none;
        outline: none;
        font-size:.9rem;
        background-color:transparent;
        border: 1px solid rgb(33, 33, 33);
        border-radius: 10px;
        width: calc(100% - 20px);
        margin-bottom: 10px;
    }
    
   #signature-pad {
      border: 1px solid #ccc;
      margin: 0 0 20px 0;
      border-radius: 5px;
   }
    
    h1 {      
      padding-bottom: 15px;
      font-size: 22px;
      font-weight: normal;      
      margin-top: -30px;
    }
  
  .help {
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff;
    
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem;
  }
  
  .name {
    display: flex;
    flex-direction: row;
    
    .formgroup {
      &:first-child {
        padding-right: 5px;
      }
      &:last-child {
        padding-left: 10px;
      }
    }
  }
  
  
  .formgroup {      
      flex: 1;  
      padding-bottom: 20px;
  }
  
  h5 {
    font-size: 0.9rem;
    padding-bottom: 10px;
  }
  
  .actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 0 -10px 0 -10px;
    
    .button {
      margin: 5px;
      
      @media screen and (max-width: 1000px) {
        &.delete {
          flex: 0 0 62px;
        }
      }
    }
  }
  
  .placeholder {
    margin: -10px 0px 10px; 
  }
`;
