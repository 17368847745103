import BaseTransformer from "./BaseTransformer";
import CustomerTransformer from "./CustomerTransformer";

export default class UserTransformer extends BaseTransformer {
    public static transform(data: any): any {
        return {
            id: data.id,
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            customer: data.customer ? CustomerTransformer.transform(data.customer) : null,
            validatedAt: data.validatedAt,
            totalFreePackages: data.totalFreePackages,
            createdAt: data.createdAt,
            updatedAt: data.updatedAt
        }
    }
}
