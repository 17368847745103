import React, {useEffect, useState} from 'react';
// import { CodeBlock, nord } from "react-code-blocks";

import styled from 'styled-components/macro';

import {TopBar} from "../../../components/TopBar";
import {Button} from "../../../components/Button";
import {HyperSpace} from "../../../components/HyperSpace";

import shuttle from "../../../assets/images/icon-rocket.svg";
import wrench from "../../../assets/images/icon-truck.svg";
import magnify from "../../../assets/images/icon-diver.svg";
import {Footer} from "../../../components/Footer";


export function Developers() {

    const [boost, setBoost] = useState(1);

    let command = [
        "// initialize the client",
        "$client = new LegalSignClient('your_token');",
        "",
        "// create new package",
        "$package = $client->packages->create([",
        "   'name' => 'My first signing package'",
        "]);",
        "",
        "// add documents",
        "$package->addDocument([",
        "   'name' => 'My contract'",
        "   'file' => 'path/to/contract.pdf';",
        "]);",
        "",
        "// add signers",
        "$package->addDocument([",
        "   'firstName' => 'John'",
        "   'firstName' => 'Doe'",
        "   'email' => 'john.doe@mydomain.com';",
        "]);",
        "",
        "// invite the signers",
        "$package->invite();",
    ].join("\n");

    return (
        <Wrapper>

            <HyperSpace className={"lightspeed"} boost={boost}/>

            <div className={"inner"}>

                <TopBar/>

                <Middle>
                    <MegaTron>
                        <h1>Developer resources</h1>
                        <p>Supercharge je app met onze eenvoudig te implementeren API.</p>
                        <div onMouseOver={() => {
                            setBoost(5)
                        }} onMouseOut={() => {
                            setBoost(1)
                        }}>
                            <Button className={"primary"} text={"Bekijk de API specificatie"} onClick={() => {
                                window.location.href = 'https://legalsign.app/api/'
                            }}/>
                        </div>
                    </MegaTron>

                    <Resources>
                        <div className={"resource"}>
                            <div className={"inner"}>
                                <h3>Getting started</h3>
                                <img src={shuttle} alt={"Getting Started"}/>
                                <p>Create your first document signing package in three small steps.</p>
                                {/*<Button text={"Get started"} onClick={() => {}}/>*/}
                            </div>
                        </div>

                        <div className={"resource"}>
                            <div className={"inner"}>
                                <h3>Client libraries</h3>
                                <img src={wrench} alt={"Getting Started"}/>
                                <p>Integrate our API using your favourite programming language.</p>
                                {/*<Button text={"Get your library"} onClick={() => {}}/>*/}
                            </div>
                        </div>

                        <div className={"resource"}>
                            <div className={"inner"}>
                                <h3>API Reference</h3>
                                <img src={magnify} alt={"Getting Started"}/>
                                <p>Dive deeper using our API specification.</p>
                                {/*<Button text={"Visit the reference"} onClick={() => {}}/>*/}
                            </div>
                        </div>
                    </Resources>
                </Middle>

                {/*<Examples>*/}
                {/*    <div className={"example"}>*/}
                {/*        <CodeBlock*/}
                {/*            text={command}*/}
                {/*            theme={nord}*/}
                {/*            language="php"*/}
                {/*        />*/}
                {/*    </div>*/}

                {/*</Examples>*/}
                <Footer />
            </div>
        </Wrapper>
    );
}

const Wrapper = styled.div`   
    width: 100vw;
    height: 100vh;
    overflow: auto;
    
  
    .inner {
      padding: 20px 30px 20px 30px;      
      position: relative;
      margin: 0 auto;
      z-index: 1;
    }
    
     .lightspeed {
      position: fixed;
      height: 100vh;
      width: 100vw;
      overflow: hidden;
      z-index: 0;
    }    
`;

const Middle = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;   
    padding: 100px 30px 0 30px; 
`;

const MegaTron = styled.div`
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    position: relative;
    
    h1 {
      font-size: 4rem;
      margin-bottom: 30px;
    }
    
    p {
      font-size: 1.5rem;
      max-width: 800px;
      line-height: 2rem;      
    }
    
    .button {
      margin-top: 30px;   
      max-width: 300px; 
    }
`;

const Resources = styled.div`
  display: flex;
  flex-direction: row;  
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  flex: 1;
  

  .resource {
      //background-color: rgba(74,74,74,.5);
      background-image: linear-gradient(to right, rgb(53, 42, 180), rgb(98, 84, 255));      
      margin: 20px;
      border-radius: 16px;
      padding: 3px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 0 0 250px;
      transition: all .3s ease-in-out;
      cursor: pointer;
      
      .inner {
        background-color: black;
        padding: 15px 20px 15px 20px;
        border-radius: 16px;
        
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex: 1;
      }
      
      &:hover {
        transform: scale(1.02);
      }
      
      img {
        height: 150px;
      }
      
      h3 {
        font-size: 1.5rem;
      }
      p {
        display: block;
        text-align: center;
        min-height: 50px;
      }
      
  }
`;

//
//
// const Examples = styled.div`
//       position: relative;
//       margin: 0 auto;
//       width: 800px;
//       padding: 80px 0 80px 0;
//
//       .example {
//           border-radius: 16px !important;
//           overflow: hidden;
//           display: flex;
//           flex-direction: column;
//           font-family: monospace !important;
//
//           .react-syntax-highlighter-line-number {
//             color: #333;
//           }
//
//           >span {
//             display: block;
//             background-image: linear-gradient(to right, rgb(7,6,33), rgb(25,25,59)) !important;
//             border-radius: 0 !important;
//           }
//
//       }
// `;
