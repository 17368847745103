import React, {memo} from 'react';
import styled from 'styled-components';
import {FaChevronLeft} from "react-icons/all";

interface Props {
    onClick: (e : any) => any;
}

export const BackButton = memo(({onClick, ...restOf}: Props) => {

    return (
        <Wrapper onClick={onClick}>
            <div className={"background"}></div>
            <div className={"inner"}><FaChevronLeft/></div>
        </Wrapper>
    );
});

const Wrapper = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  cursor: pointer;
  border-radius: 4px;
  transition: all .2s ease-in-out;
  background-image: linear-gradient(to right, rgb(53, 42, 180), rgb(98, 84, 255));
  position: relative;
  overflow: hidden;
  
  .inner {
    display: flex;
    flex: 1;  
    align-items: center;
    justify-content: center;  
    z-index: 2;
  }
  
  .background {
    background-image: linear-gradient(to left, rgb(53, 42, 180), rgb(98, 84, 255));
    opacity: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    position: absolute;    
    transition: all .3s ease-in-out;
  }
  
  &:hover {
    @media screen and (min-width: 1000px) {
        .background {
          opacity: 1;
        }
    }
  }
`;
