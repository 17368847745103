import React from 'react';
import styled from 'styled-components/macro';
import {Footer} from "../../../components/Footer";
import {TopBar} from "../../../components";

export function TermsOfService() {
  return (
    <Wrapper>
      <div className={"inner"}>
        <TopBar/>
        <Middle className={"odd"}>
          <div className={"resource"}>
            <div className={"inner"}>
              <h1>Voorwaarden</h1>
              <h2>LEVERINGSVOORWAARDEN INZAKE GEBRUIK MKA diensten: Mijn Kantoor, Legalsign en/of Zendoc</h2>
              <h3>Artikel 1 - Definities</h3>
              <p>
                In deze algemene leveringsvoorwaarden wordt verstaan onder:<br/>
                "Afnemer": de Persoon met wie Mijn Kantoorapp B.V. (hierna MKA) een Overeenkomst heeft gesloten of met
                wie
                MKA ter zake in onderhandeling is;<br/>
                "Diensten": alle online (cloud) en mobiele diensten die het onderwerp zijn van een Overeenkomst en die
                al
                dan niet als MKA diensten worden geleverd;<br/>
                "Documentatie": de schriftelijke en/of elektronische informatie die bij de Programmatuur hoort;<br/>
                "Gebruiker": Afnemer en/of een medewerker van Afnemer en/of de accountant van Afnemer en/of – indien
                Afnemer
                zelf een accountant of een administratiekantoor is – een cliënt van Afnemer;<br/>
                "Overeenkomst": elke overeenkomst tussen Partijen strekkende tot het leveren van Diensten door MKA aan
                Afnemer, elke wijziging of aanvulling van deze overeenkomst, alsmede alle feitelijke en
                rechtshandelingen
                ter voorbereiding en ter uitvoering van deze overeenkomst;<br/>
                "Partijen": MKA en Afnemer;<br/>
                "Persoon": natuurlijke of rechtspersoon of vennootschap zonder rechtspersoonlijkheid;<br/>
                "Programmatuur": de computerprogrammatuur die door MKA aan Afnemer voor gebruik beschikbaar wordt
                gesteld;<br/>
                "Vergoeding": de door Afnemer voor het gebruik van de Diensten en/of de Programmatuur te betalen
                vergoeding;<br/>
                "MKA": Mijn Kantoorapp B.V., gevestigd te Zwolle, ingeschreven in het Handelsregister van de Kamer van
                Koophandel onder nummer 68945272;<br/>
                “MKA diensten”: Diensten die door MKA worden geleverd onder de naam Mijn Kantoor, Legalsign en/of
                Zendoc;<br/>
                "Voorwaarden": de onderhavige algemene leveringsvoorwaarden van MKA;<br/>
                "Website": de websites welke door MKA beschikbaar worden gesteld via mijnkantoor.nl, zendoc.nl en
                legalsign.app.
              </p>
              <h3>Artikel 2 - Algemeen</h3>
              <p>
                2.1 De Voorwaarden zijn van toepassing op alle Overeenkomsten. Van de Voorwaarden kan enkel schriftelijk
                door Partijen worden afgeweken.<br/>
                2.2 Eventuele algemene voorwaarden van Afnemer zijn niet van toepassing op de Overeenkomst en worden
                uitdrukkelijk van de hand gewezen, ongeacht het moment waarop hiernaar wordt verwezen.<br/>
                2.3 De eventuele nietigheid van een deel van de Overeenkomst en/of de Voorwaarden laat de geldigheid van
                het
                overige deel van de Overeenkomst en/of de Voorwaarden onverlet.<br/>
                2.4 MKA is te allen tijde bevoegd derden in te schakelen bij de uitvoering van de Overeenkomst dan wel
                haar
                rechten en verplichtingen uit de Overeenkomst en de Voorwaarden over te dragen aan een derde.<br/>
                2.5 Iedere communicatie tussen MKA en/of Afnemer en/of Gebruiker kan elektronisch geschieden, behoudens
                voor
                zover in de Overeenkomst en/of de Voorwaarden en/of de wet anders wordt bepaald. Afnemer kan zich via de
                MKA
                supportomgeving aanmelden en afmelden voor de Diensten van MKA. De door MKA opgeslagen versie van de
                desbetreffende communicatie geldt als bewijs daarvan, behoudens tegenbewijs door Afnemer.
              </p>
              <p>
                Elektronische communicatie wordt geacht te zijn ontvangen op de dag van verzending, tenzij het tegendeel
                door de ontvanger daarvan wordt bewezen. Indien Afnemer de communicatie niet heeft ontvangen als gevolg
                van
                afleverings- en/of toegankelijkheidsproblemen met betrekking tot zijn e-mailbox, komt dit voor zijn
                risico,
                ook indien zijn e-mailbox bij een derde is gehuisvest.
              </p>
              <h3>Artikel 3 - Totstandkoming Overeenkomst</h3>
              <p>
                3.1 De Overeenkomst komt tot stand doordat Afnemer zich via de MKA supportomgeving of website aanmeldt
                voor
                MKA Diensten. De Overeenkomst kan door Afnemer worden uitgebreid of gewijzigd via de MKA
                supportomgeving,
                mits MKA deze uitbreiding of wijziging via de Programmatuur bevestigt.<br/>
                3.2 In afwijking van het bepaalde in artikel 6:227c lid 2 Burgerlijk Wetboek komen Partijen overeen dat
                MKA
                de Afnemer geen separate bevestiging van de totstandkoming van de Overeenkomst zal toezenden.<br/>
                3.3 Elke Partij is zelf verantwoordelijk voor het bewaren van een eigen kopie van de Overeenkomst.
              </p>
              <h3>Artikel 4 - Looptijd en beëindiging van de Overeenkomst</h3>
              <p>
                4.1 De Overeenkomst wordt aangegaan voor onbepaalde tijd, tenzij Partijen uitdrukkelijk anders zijn
                overeengekomen in de Overeenkomst.<br/>
                4.2 Partijen kunnen in de Overeenkomst overeenkomen dat de Overeenkomst wordt aangegaan voor een
                bepaalde
                minimumtermijn, in welk geval beëindiging op grond van het bepaalde in artikel 4.3 en artikel 4.4
                bedoelde
                opzegtermijnen voor het eerst mogelijk is tegen het einde van deze minimumtermijn.<br/>
                4.3 De Overeenkomst die niet is aangegaan voor een bepaalde minimumtermijn kan door een Partij worden
                opgezegd met inachtneming van een opzegtermijn van één maand, uit te oefenen tegen het einde van de
                kalendermaand. De opzegging kan plaatsvinden via de MKA supportomgeving.<br/>
                4.4 Afnemer is bevoegd het gebruik van bepaalde modules van de Programmatuur met inachtneming van de in
                artikel 4.3 genoemde opzeggingsvereisten gedeeltelijk op te zeggen tegen het einde van een
                kalendermaand.
                Een gedeeltelijke opzegging is slechts rechtsgeldig indien Afnemer uitdrukkelijk aangeeft op welke
                specifieke modules de opzegging betrekking heeft.<br/>
                4.5 Indien Afnemer zich via de MKA omgeving aanmeldt voor webservices en/of integraties in relatie tot
                door
                derden te leveren services, kunnen afwijkende voorwaarden gelden dan de voorwaarden die in dit artikel
                zijn
                opgenomen. Deze voorwaarden worden specifiek vermeld indien Afnemer deze services binnen MKA activeert.
                De
                opzegging kan plaatsvinden via de Programmatuur.
              </p>
              <h3>Artikel 5 - Prijzen en tarieven</h3>
              <p>
                5.1 MKA levert de Diensten tegen de prijzen en tarieven die op de Website en/of in de Programmatuur zijn
                vermeld.<br/>
                5.2 Alle door MKA opgegeven prijzen en tarieven zijn exclusief omzetbelasting en eventuele andere van
                overheidswege opgelegde heffingen.<br/>
                5.3 MKA behoudt zich het recht voor haar prijzen en tarieven te wijzigen. MKA zal Afnemer tenminste 30
                dagen
                vantevoren via de Website en/of de Programmatuur informeren over een wijziging van prijzen en tarieven.
                Indien de wijziging een verhoging inhoudt, heeft Afnemer gedurende 30 dagen na ontvangst van de
                kennisgeving
                van de prijs- en/of tariefverhoging het recht de Overeenkomst op te zeggen, bij gebreke waarvan Afnemer
                geacht wordt te hebben ingestemd met de prijs- en/of tariefverhoging.
              </p>
              <h3>Artikel 6 - Betaling</h3>
              <p>
                6.1 Betaling geschiedt door middel van de bij de aanmelding voor de Diensten gekozen betaalwijze, tenzij
                Partijen uitdrukkelijk anders zijn overeengekomen in de Overeenkomst.<br/>
                6.2 Indien wordt betaald middels een automatische SEPA-incasso vindt deze maandelijks vooraf plaats,
                tenzij
                Partijen uitdrukkelijk anders zijn overeengekomen in de Overeenkomst. Afnemer stemt er mee in dat de
                vooraankondiging voor de SEPA-incasso uiterlijk één dag vantevoren aan Afnemer zal worden
                gecommuniceerd.<br/>
                6.3 Indien betaling wordt gestorneerd, geweigerd of geannuleerd, is Afnemer vanaf dat moment automatisch
                in
                verzuim, zonder dat ingebrekestelling is vereist, en is MKA, onverminderd haar overige rechten,
                waaronder
                haar recht op betaling, bevoegd om de toegang van Afnemer en/of Gebruikers tot de Programmatuur geheel
                of
                gedeeltelijk te beperken of te blokkeren.<br/>
                6.4 Indien Afnemer ter zake van enige betaling in verzuim is, zijn alle vorderingen van MKA op Afnemer
                onmiddellijk en geheel opeisbaar. Afnemer is niet gerechtigd tot verrekening van aan MKA verschuldigde
                bedragen met enige (beweerdelijke) vordering van MKA op Afnemer.<br/>
                6.5 Bij buitengerechtelijke invordering is Afnemer, naast de hoofdsom en de vertragingsrente, in
                redelijkheid door MKA gemaakte incassokosten verschuldigd.<br/>
                6.6 Bij beëindiging van de Overeenkomst, om welke reden dan ook, zal MKA geen Vergoeding aan Afnemer
                restitueren, noch geheel noch gedeeltelijk.
              </p>
              <h3>Artikel 7 - Levertijden en levering</h3>
              <p>
                7.1 De door MKA opgegeven levertijden gelden steeds bij benadering en zullen nimmer te beschouwen zijn
                als
                fatale termijnen, tenzij Partijen uitdrukkelijk anders zijn overeengekomen.
              </p>
              <h3>Artikel 8 - Gebruiksrecht en -voorschriften</h3>
              <p>
                8.1 MKA verleent Afnemer het niet-overdraagbare en niet-exclusieve recht om de Programmatuur en de
                Documentatie gedurende de looptijd van de Overeenkomst enkel en alleen door Gebruikers te laten
                gebruiken,
                hetzij uitsluitend ten behoeve van Afnemer zelf, hetzij – indien Afnemer een accountant of een
                administratiekantoor is – mede ten behoeve van de cliënten van Afnemer. Afnemer is jegens MKA op gelijke
                wijze als voor eigen gedragingen aansprakelijk voor de gedragingen van Gebruikers. Alle handelingen van
                Gebruikers zijn volledig voor rekening en risico van Afnemer.<br/>
                8.2 Het is Afnemer verboden van de Diensten gebruik te maken of te laten maken in strijd met de
                Overeenkomst, de Voorwaarden of de wet. In geval van overtreding van dit verbod is MKA bevoegd het
                gebruik
                van de Diensten en/of de Programmatuur zonder voorafgaande aankondiging te beperken of te beëindigen.
                MKA is
                niet aansprakelijk voor schade die kan ontstaan als gevolg van het uitoefenen van deze bevoegdheid.<br/>
                8.3 Afnemer zal bij het gebruik van de Diensten en/of de Programmatuur op geen enkele wijze hinder of
                schade
                veroorzaken of laten veroorzaken aan MKA of aan derden.<br/>
                8.4 Afnemer zal geen handelingen verrichten of laten verrichten waarvan aangenomen kan worden dat deze
                mogelijkerwijs schade toebrengen aan de systemen van MKA of van derden.<br/>
                8.5 Afnemer mag de Diensten en/of de Programmatuur (of enig deel daarvan) niet (laten) reproduceren,
                namaken, kopiëren, dupliceren, verkopen, wederverkopen, verhuren of verhandelen.<br/>
                8.6 Afnemer is verantwoordelijk voor de inhoud en de juistheid van de gegevens die hij of een Gebruiker
                door
                middel van de Programmatuur bij MKA plaatst. Afnemer zal geen illegale gegevens plaatsen of frauduleuze
                handelingen verrichten of laten verrichten. MKA is gerechtigd om op bevel van de rechter of vordering
                van de
                bevoegde autoriteiten gegevens van een Gebruiker aan derden te verstrekken.<br/>
                8.7 Afnemer verplicht zich jegens MKA de Diensten en/of de Programmatuur enkel zodanig te gebruiken of
                te
                laten gebruiken dat de opgeslagen hoeveelheid informatie en het gerealiseerde volume van het
                datatransport
                niet significant afwijken van het gemiddelde gebruik van de Diensten door andere Afnemers. Indien dit
                gebruik significant afwijkt van het gemiddelde gebruik, zal MKA contact opnemen met Afnemer om
                specifieke
                voorwaarden met Afnemer overeen te komen voor het extra gebruik. Indien Partijen geen overeenstemming
                bereiken over deze specifieke voorwaarden, is MKA bevoegd om het gebruik van de Diensten door Afnemer
                en/of
                Gebruikers zonder voorafgaande aankondiging te beperken.<br/>
                8.8 Het is Afnemer niet toegestaan om de Diensten en/of de Programmatuur op een zodanige wijze te
                gebruiken
                dat de goede werking van de Diensten en/of de Programmatuur wordt verstoord. Indien het gebruik door
                Afnemer
                van de Diensten en/of de Programmatuur een verstoring van de goede werking van de Diensten en/of de
                Programmatuur tot gevolg heeft, is MKA gerechtigd om het gebruik van de Diensten en/of de Programmatuur
                door
                Afnemer te beperken of te blokkeren totdat de verstoring is verholpen. MKA zal Afnemer zo snel als
                redelijkerwijs mogelijk informeren over een dergelijke beperking of blokkering.<br/>
                8.9 Afnemer draagt zorg en staat in voor het correct functioneren van haar eigen hard- en software in
                overeenstemming met de minimale systeemeisen van MKA, configuratie, browser, randapparatuur en
                internetverbinding benodigd voor het gebruik van de Diensten en het treffen van de nodige maatregelen om
                zijn apparatuur, software en telecommunicatie- en internetverbindingen en/of dat van de Gebruikers te
                beschermen tegen virussen, computercriminaliteit en onrechtmatig gebruik door derden.<br/>
                8.10 Afnemer dient zorgvuldig om te gaan met de inloggegevens die MKA hem verstrekt om toegang te kunnen
                verkrijgen tot de Programmatuur. De inloggegevens zijn niet overdraagbaar en mogen niet worden gebruikt
                buiten de organisatie van Afnemer respectievelijk – indien Afnemer een accountant of een
                administratiekantoor is – buiten de organisatie van Afnemer en zijn cliënten, in welk geval Afnemer zijn
                cliënten de in dit artikel 8.10 bedoelde gebruiksbeperkingen en geheimhoudingsverplichtingen met
                betrekking
                tot de inloggegevens zal opleggen. Afnemer en/of de Gebruikers zijn jegens een ieder verplicht
                volstrekte
                geheimhouding te betrachten over de inloggegevens en zullen de inloggegevens op een veilige plaats
                bewaren.
                Afnemer is aansprakelijk voor ieder gebruik dat van zijn inloggegevens wordt gemaakt.<br/>
                8.11 MKA is bevoegd de inloggegevens van Afnemer en/of een Gebruiker te blokkeren indien MKA dat nodig
                vindt, bijvoorbeeld vanwege veiligheid, niet toegestaan gebruik, (een vermoeden van) fraude of indien
                (het
                vermoeden bestaat dat) de Afnemer zijn betalingsverplichtingen of andere verplichtingen niet
                nakomt.<br/>
                8.12 MKA is bevoegd de inlogprocedure van de Programmatuur naar eigen inzicht aan te passen. MKA zal
                Afnemer
                informeren over eventuele aanpassingen.
              </p>
              <h3>Artikel 9 - Bevoegdheden en verplichtingen MKA</h3>
              <p>
                9.1 In geval van een vermoeden van misbruik of oneigenlijk gebruik door Afnemer en/of een Gebruiker is
                MKA
                bevoegd om de toegang van Afnemer en/of een Gebruiker tot de Programmatuur zonder voorafgaande
                aankondiging
                voor onbepaalde tijd te beperken of te blokkeren. MKA is niet aansprakelijk voor schade die kan ontstaan
                als
                gevolg van het uitoefenen van deze bevoegdheid.<br/>
                9.2 MKA is bevoegd naar eigen inzicht wijzigingen en verbeteringen aan te brengen in de Programmatuur.
                MKA
                is niet verplicht Afnemer hierover te informeren.<br/>
                9.3 MKA garandeert dat backups worden gemaakt van de in de Programmatuur en/of de Diensten ingevoerde
                gegevens van Afnemer, danwel dat deze gegevens op andere wijze beschikbaar worden gehouden.<br/>
                9.4 MKA zal er zorg voor dragen dat de gegevens die Afnemer en/of Gebruikers door middel van de
                Programmatuur hebben ingevoerd zo goed als redelijkerwijs mogelijk zullen worden beveiligd tegen
                verlies,
                diefstal, ongeautoriseerde toegang en wijziging door derden.<br/>
                9.5 Onverminderd het bepaalde in artikel 12.2 zal MKA zich onthouden van het inzien van de gegevens die
                Afnemer en/of Gebruikers door middel van de Programmatuur en/of de Diensten bij MKA hebben geplaatst,
                met
                dien verstande dat MKA gerechtigd is gegevens en informatie met betrekking tot het gebruik van de
                Website of
                de Programmatuur te verzamelen, gebruiken, verwerken en bewaren om de levering van de Diensten te
                continueren en te verbeteren.
              </p>
              <h3>Artikel 10 - Gegevens</h3>
              <p>
                10.1 Voor zover de gegevens die Afnemer en/of Gebruikers door middel van de Programmatuur en/of de
                Diensten
                hebben ingevoerd persoonsgegevens bevatten, zal MKA deze persoonsgegevens verwerken in overeenstemming
                met
                de in Bijlage A opgenomen bewerkersvoorwaarden. De gegevens die Afnemer en/of Gebruikers door middel van
                de
                Programmatuur hebben ingevoerd worden opgeslagen in een cloud-omgeving binnen Europa die onder beheer
                staat
                van een door MKA ingeschakelde derde.<br/>
                10.2 Afnemer en/of een Gebruiker blijft te allen tijde eigenaar van de door hem ingevoerde
                gegevens.<br/>
                10.3 Voor zover Afnemer en/of Gebruiker met gebruikmaking van de Website of de Programmatuur
                persoonsgegevens verwerkt, is Afnemer en/of een Gebruiker daarvoor de verantwoordelijke in de zin van de
                Algemene Verordening Gegevensbescherming. Afnemer staat ervoor in dat hij de persoonsgegevens rechtmatig
                verwerkt. MKA zal de persoonsgegevens uitsluitend verwerken overeenkomstig Annex A, in opdracht van
                Afnemer
                en overeenkomstig de instructies van Afnemer of voorzover dat noodzakelijk is voor de uitvoering van de
                Overeenkomst. Afnemer vrijwaart MKA voor alle aanspraken van derden en/of Gebruikers die jegens MKA
                mochten
                worden ingesteld wegens schending van de Algemene Verordening Gegevensbescherming en/of andere wetgeving
                betreffende de verwerking van persoonsgegevens.<br/>
                10.4 Na beëindiging van de Overeenkomst, om welke reden dan ook, zal Afnemer (of de curator in geval van
                faillissement van Afnemer) geen toegang meer hebben tot de Programmatuur en zijn gegevens, met dien
                verstande dat MKA de gegevens gedurende drie maanden na beëindiging van de Overeenkomst beschikbaar zal
                houden, waarna de gegevens worden verwijderd.<br/>
                10.5 Afnemer is gehouden zorg te dragen voor een adequate naleving van toepasselijke wettelijke
                bewaartermijnen. Op MKA rust geen wettelijke bewaartermijn voor de door Afnemer ingevoerde
                gegevens.<br/>
                10.6 Afnemer is zelf verantwoordelijk voor het bewaren van een kopie van de gegevens welke hij door
                middel
                van de Programmatuur en/of de Diensten heeft ingevoerd.<br/>
              </p>
              <h3>Artikel 11 – Geheimhouding</h3>
              <p>
                11.1 Onder "vertrouwelijke informatie" wordt hier verstaan: alle vertrouwelijke informatie betreffende
                Afnemer of MKA.<br/>
                11.2 Vertrouwelijke informatie omvat onder meer, maar niet uitsluitend: de gegevens die Afnemer en/of
                Gebruiker door middel van de Programmatuur heeft ingevoerd; informatie die als "vertrouwelijk" is
                aangeduid;
                en overige informatie waarvan het vertrouwelijke karakter bekend moet worden verondersteld.<br/>
                11.3 Zowel MKA als Afnemer en/of Gebruiker zal vertrouwelijke informatie betreffende de andere partij
                niet
                openbaar maken of gebruiken voor een ander doel dan waarvoor deze informatie is verkregen.<br/>
                11.4 De geheimhoudingsverplichting geldt niet voor zover vertrouwelijke informatie openbaar dient te
                worden
                gemaakt krachtens de wet, een gerechtelijk bevel of een besluit van een overheidsinstantie, op
                voorwaarde
                dat de partij die tot openbaarmaking verplicht wordt zich tot het uiterste inspant om de omvang van de
                openbaarmaking te beperken en de andere partij op voorhand informeert over de openbaarmaking.<br/>
                11.5 De verplichting van Partijen tot geheimhouding eindigt indien en zodra vertrouwelijke informatie
                betreffende een Partij niet langer kan worden geacht een vertrouwelijk karakter te hebben, bijvoorbeeld
                omdat de betreffende informatie anders dan door toedoen van de andere Partij publiek bekend is.<br/>
              </p>
              <h3>Artikel 12 - Ondersteuning</h3>
              <p>
                12.1 Afnemer heeft recht op ondersteuning door MKA op basis van de door de Afnemer afgenomen Diensten.
                Dit
                recht houdt in dat Afnemer de Documentatie kan raadplegen en dat een door Afnemer als contactpersoon
                aangewezen Gebruiker zich na raadpleging van de Documentatie via de MKA supportomgeving tot MKA kan
                wenden
                met vragen over de functionaliteit van de Diensten. MKA zal zich inspannen de vragen adequaat en in de
                Nederlandse taal te beantwoorden. MKA kan niet instaan voor de juistheid en de volledigheid van de
                antwoorden. Ondersteuning van medewerkers van MKA zal uitsluitend plaatsvinden op werkdagen tijdens
                kantooruren.<br/>
                12.2 In het kader van het leveren van ondersteuning is MKA bevoegd de gegevens van Afnemer en/of
                Gebruiker,
                bedoeld in artikel 10, in te zien.<br/>
                12.3 Indien MKA in opdracht van Afnemer werkzaamheden verricht welke niet zijn inbegrepen in de
                Diensten,
                zal MKA deze werkzaamheden apart bij Afnemer in rekening brengen conform de op dat moment bij MKA
                geldende
                prijzen en tarieven. Deze Voorwaarden zijn van toepassing op het verrichten van de genoemde
                werkzaamheden.
              </p>
              <h3>Artikel 13 - Beschikbaarheid</h3>
              <p>
                13.1 MKA spant zich in voor optimale beschikbaarheid van en toegang tot de Website en de Programmatuur
                in de
                staat waarin deze zich van tijd tot tijd bevindt (beschikbaarstelling ‘as is’).<br/>
                13.2 MKA is bevoegd de toegang tot de Website en de Programmatuur zonder voorafgaande bekendmaking
                tijdelijk
                buiten gebruik te stellen of het gebruik ervan te beperken ten behoeve van onderhoudswerkzaamheden of te
                verrichten aanpassingen of verbeteringen van één of meer Diensten. MKA spant zich in dit tot een minimum
                te
                beperken en Afnemer indien mogelijk op voorhand te informeren.
              </p>
              <h3>Artikel 14 – Intellectueel eigendomsrechten</h3>
              <p>
                14.1 Alle auteursrechten, handelsnaamrechten, merkrechten en andere intellectueel eigendomsrechten op,
                alsmede alle soortgelijke rechten ter bescherming van informatie met betrekking tot de Website, de
                Programmatuur en de Documentatie zijn het exclusieve eigendom van MKA of haar licentiegever(s). De
                Overeenkomst zal geen enkele overdracht van deze rechten door MKA aan Afnemer en/of Gebruikers tot
                gevolg
                hebben.<br/>
                14.2 Het is Afnemer niet toegestaan een aanduiding van de intellectueel eigendomsrechten van MKA op of
                in de
                Programmatuur, de Website of de Documentatie te wijzigen, te verwijderen of onherkenbaar te maken. Het
                is
                Afnemer niet toegestaan enig merk of ontwerp of enige domeinnaam van MKA of een daarmee overeenstemmende
                naam of teken te gebruiken of te registreren in enig land.<br/>
                14.3 MKA is bevoegd technische voorzieningen te treffen en in stand te houden ter bescherming van de
                (intellectueel eigendomsrechten op) de Website, de Programmatuur en de Documentatie en met het oog op de
                overeengekomen beperkingen in het gebruik van de Website en de Programmatuur. Het is Afnemer niet
                toegestaan
                dergelijke technische voorzieningen te omzeilen of te verwijderen.<br/>
                14.4 MKA kan een controle (doen) uitvoeren om na te gaan of Afnemer en/of Gebruiker de Overeenkomst en
                de
                Voorwaarden naleeft, mits die controle op werkdagen tijdens de gebruikelijke kantooruren wordt
                uitgevoerd,
                op zodanige wijze dat de bedrijfsactiviteiten van de Afnemer en/of Gebruiker hierdoor niet onredelijk
                belemmerd worden. Een dergelijke controle wordt door een onafhankelijke door MKA geselecteerde en
                ingeschakelde deskundige uitgevoerd en Afnemer is verplicht deze deskundige de informatie, ondersteuning
                en
                toegang tot de Programmatuur en de Diensten te geven die redelijkerwijs nodig is om de deskundige in
                staat
                te stellen zijn controlerende taak naar behoren uit te voeren. Deze onafhankelijke deskundige zal een
                samenvattende notitie opstellen waaruit zijn bevindingen blijken met betrekking tot de controle van de
                door
                Afnemer afgegeven rapporten en de naleving door Afnemer van de Overeenkomst en de Voorwaarden, maar
                verschaft MKA in geen enkel geval andere informatie dan die hem bij de controle ter kennis komt. De
                kosten
                van deze controle zijn voor rekening van MKA, tenzij de controle aantoont dat Afnemer de Overeenkomst
                en/of
                de Voorwaarden niet naleeft, in welk geval de kosten voor rekening van Afnemer zijn.
              </p>
              <h3>Artikel 15 - Opschorting en ontbinding</h3>
              <p>
                15.1 Onverminderd haar overige rechten is MKA, zonder dat enige ingebrekestelling of rechterlijke
                tussenkomst vereist is, bevoegd een of meer van haar verplichtingen onder de Overeenkomst geheel of
                gedeeltelijk op te schorten dan wel de Overeenkomst geheel of gedeeltelijk te ontbinden, indien: (a)
                Afnemer
                een verbintenis die voor hem voortvloeit uit de Overeenkomst niet, niet tijdig of niet behoorlijk
                nakomt, en
                deze tekortkoming niet binnen een redelijke termijn heeft hersteld; en/of (b) MKA goede grond heeft om
                te
                vrezen dat Afnemer in de nakoming van zijn verbintenis zal tekortschieten; en/of (c) Afnemer in staat
                van
                faillissement is verklaard, zijn faillissement is aangevraagd, aan Afnemer, al dan niet voorlopige,
                surseance van betaling is verleend of een verzoek daartoe is gedaan, ten aanzien van Afnemer de
                schuldsaneringsregeling voor natuurlijke personen van toepassing is verklaard of een verzoek daartoe is
                gedaan, het bedrijf van Afnemer wordt geliquideerd; en/of (d) op zaken van Afnemer executoriaal beslag
                is
                gelegd dan wel conservatoir beslag dat niet binnen een maand na de datum van de beslaglegging is
                opgeheven.<br/>
                15.2 In geval van gehele of gedeeltelijke ontbinding van de Overeenkomst door MKA is zij niet gehouden
                tot
                enige schadevergoeding en zijn al haar vorderingen op Afnemer onmiddellijk en geheel opeisbaar.
              </p>
              <h3>Artikel 16 - Overmacht</h3>
              <p>
                16.1 In geval van overmacht is MKA bevoegd de nakoming van haar verbintenis of een gedeelte daarvan op
                te
                schorten en kan Afnemer geen nakoming of schadevergoeding vorderen.<br/>
                16.2 Het in artikel 16.1 bedoelde begrip overmacht omvat onder meer, maar niet uitsluitend: oorlog,
                oorlogsdreiging, natuurrampen, excessieve tijdelijke stijging van de vraag, overheidsmaatregelen,
                weersomstandigheden, het niet, vertraagd of niet volledig leveren aan MKA van bij derden (waaronder
                Microsoft Azure) bestelde zaken of diensten, stakingen, bedrijfsblokkades, stiptheidsacties, storingen
                in
                het bedrijf van MKA, brand, waterschade, storingen in telecommunicatie- en internetverbindingen,
                verkeersstoringen en virusinfecties in de Programmatuur.<br/>
                16.3 Als de periode van overmacht langer duurt dan een maand, is elk der Partijen bevoegd de
                Overeenkomst
                geheel of gedeeltelijk te ontbinden zonder tot schadevergoeding gehouden te zijn, met dien verstande dat
                indien MKA haar verbintenis voor of na het intreden van de overmacht gedeeltelijk is nagekomen, zij
                steeds
                recht heeft op een evenredig deel van de Vergoeding.
              </p>
              <h3>Artikel 17 – Klachten</h3>
              <p>
                17.1 Klachten over de Diensten dienen zo snel als redelijkerwijs mogelijk na ontdekking (maar uiterlijk
                binnen één maand na aanvang van de levering van de Diensten), en klachten over facturen binnen dertig
                dagen
                na verzenddatum van de factuur, aan MKA te worden gemeld, onder nauwkeurige opgave van de klacht en de
                aard
                van de gebreken.<br/>
                17.2 Klachten kunnen worden gemeld via de MKA supportomgeving of support@mijnkantoorapp.nl.<br/>
                17.3 Afnemer zal alle voor het onderzoek van de klacht noodzakelijke medewerking verlenen of laten
                verlenen.
                Indien Afnemer geen medewerking verleent of onderzoek anderszins niet of niet meer mogelijk is, is zijn
                klacht niet-ontvankelijk.<br/>
                17.4 Indien de klacht van Afnemer, mede gelet op het in dit artikel bepaalde, gegrond is, zal MKA na
                overleg
                met Afnemer indien mogelijk zorg dragen voor aanpassing of herstel. Afnemer erkent en aanvaardt dat de
                Website en de Programmatuur nimmer foutloos en ononderbroken zal functioneren en dat niet alle gebreken
                zullen (kunnen) worden verholpen.<br/>
                17.5 Eventuele rechtsvorderingen dienen op straffe van verval van alle rechten uiterlijk één jaar na
                tijdige
                melding van een klacht aanhangig te zijn gemaakt.
              </p>
              <h3>Artikel 18 - Aansprakelijkheid en vrijwaring</h3>
              <p>
                18.1 Behoudens voor zover bepalingen van dwingend recht zich hiertegen verzetten, geldt ter zake van de
                aansprakelijkheid van MKA voor schade die door Afnemer en/of derden en/of Gebruikers wordt geleden en
                ter
                zake van de vrijwaring van MKA door Afnemer de volgende regeling.<br/>
                18.2 Behoudens voor zover onder haar aansprakelijkheidsverzekering een hoger bedrag wordt uitgekeerd, is
                de
                totale aansprakelijkheid van MKA uit welken hoofde ook beperkt tot het bedrag van het totaal van de
                netto
                Vergoedingen die MKA heeft bedongen voor een periode van 6 maanden.<br/>
                18.3 MKA is uitsluitend gehouden tot vergoeding van schade aan personen en aan zaken als omschreven in
                de
                polisvoorwaarden van haar aansprakelijkheidsverzekering. MKA is dus onder meer niet aansprakelijk voor –
                en
                Afnemer dient zich te verzekeren tegen – gevolgschade, bedrijfsschade, stagnatieschade, schade als
                gevolg
                van verminking, vernietiging, diefstal of verlies van gegevens, schade als gevolg van wijziging van
                gegevens
                door niet-Gebruikers, schade als gevolg van ongeautoriseerde toegang tot de Website en de Programmatuur,
                gederfde winst, gemiste besparingen, schade als gevolg van aanspraken van afnemers van Afnemer, verlies
                van
                klanten, verminderde goodwill en reputatieschade.<br/>
                18.4 Hoewel met betrekking tot de inhoud van de door MKA geleverde Diensten de uiterste zorg is
                nagestreefd,
                kan voor de afwezigheid van fouten of onvolledigheden daarin, of in de doorgifte daarvan, niet worden
                ingestaan. MKA zal voor dergelijke fouten of onvolledigheden op geen enkele wijze aansprakelijk
                zijn.<br/>
                18.5 MKA is nimmer aansprakelijk voor enige schade van welke aard ook verband houdende met (a) het
                tijdelijk
                niet, niet juist of niet volledig beschikbaar zijn van de Website of de Programmatuur, (b) met het
                (niet)
                functioneren van programmatuur van Afnemer, Gebruiker of van derden, van apparatuur van Afnemer,
                Gebruiker,
                MKA of derden, of van internetverbindingen van Afnemer, Gebruiker, MKA of derden, of (c) verband
                houdende
                met het niet juist, niet volledig of niet tijdig verzenden of ontvangen van gegevens die door middel van
                de
                Programmatuur bij MKA worden geplaatst.<br/>
                18.6 Onverminderd hetgeen hiervoor is bepaald, is MKA voor Diensten die zij van derden heeft betrokken,
                niet
                verder aansprakelijk dan deze derden jegens MKA.<br/>
                18.7 Voor zover nakoming door MKA niet blijvend onmogelijk is, ontstaat aansprakelijkheid van MKA wegens
                een
                toerekenbare tekortkoming in de nakoming van een verbintenis slechts indien Afnemer MKA onverwijld in
                gebreke heeft gesteld onder nauwkeurige opgave van de aard van de tekortkoming en daarbij een redelijke
                termijn voor herstel van de tekortkoming heeft gesteld en MKA ook na verloop van die termijn
                toerekenbaar
                tekort blijft schieten in de nakoming van haar verbintenis.<br/>
                18.8 Afnemer zal MKA vrijwaren voor iedere aanspraak van een derde jegens MKA (waaronder licentiegevers
                van
                Afnemer en Gebruikers) met betrekking tot door MKA geleverde of te leveren Diensten. Afnemer dient de
                redelijke kosten van verweer tegen aanspraken van derden aan MKA te vergoeden.<br/>
                18.9 Afnemer erkent en aanvaardt dat de hoogte van de Vergoeding is vastgesteld met inachtneming van het
                bepaalde in dit artikel.
              </p>
              <h3>Artikel 19 - Toepasselijk recht en geschillen</h3>
              <p>
                19.1 De rechtsverhouding tussen Partijen wordt beheerst door het Nederlandse recht. De toepasselijkheid
                van
                het Weens Koopverdrag is uitdrukkelijk uitgesloten.<br/>
                19.2 Alle geschillen tussen Partijen die niet minnelijk kunnen worden opgelost zullen worden beslecht
                door
                de Rechtbank Midden-Nederland, locatie Zwolle.
              </p>
              <h3>Artikel 20 - Wijzigingen</h3>
              <p>
                20.1 MKA is te allen tijde bevoegd de Voorwaarden te wijzigen. De meest recente versie van de
                Voorwaarden
                wordt altijd op de website geplaatst. Het is de verantwoordelijkheid van de Afnemer om deze website
                daartoe
                te controleren. Afnemer heeft gedurende 30 dagen na ontvangst van de kennisgeving van de wijzigingen het
                recht de Overeenkomst op te zeggen tegen de in de kennisgeving genoemde ingangsdatum, bij gebreke
                waarvan
                Afnemer geacht wordt te hebben ingestemd met de wijzigingen.
              </p>
            </div>
          </div>
        </Middle>

        <Footer/>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`   
    width: 100vw;
    height: 100vh;
    overflow: auto;

    .inner {
      padding: 20px 30px 20px 30px;      
      position: relative;
      margin: 0 auto;
      z-index: 1;
      
      &.dark {
        background: black;
      }
      
      .icon {
        font-size: 5rem;
        margin: 20px 0 20px 0;
      }
    }
    
     .lightspeed {
      position: fixed;
      height: 100vh;
      width: 100vw;
      overflow: hidden;
      z-index: 0;
    }    
`;

const Middle = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    // padding: 30px; 
    border-radius: 16px;
    margin: 0 auto;
    max-width: 984px;
    margin-top: 50px;
    
    h1 {
      font-size: 2rem;
      margin-bottom: 30px;
    }

    p {
      max-width: 1200px;
      line-height: 1.2rem;
      margin-bottom: 10px;
    }

    h3, h2 {
      margin-top: 10px;
      margin-bottom: 5px;
    }
    
    .resource {
      background-image: linear-gradient(to right, rgb(53, 42, 180), rgb(98, 84, 255)); 
      border-radius: 16px;
      padding: 3px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transition: all .3s ease-in-out;
      cursor: pointer;
      flex-flow: wrap;
      
      .inner {
        width: 100%;
        background-color: black;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1;
        
        p {
          color: #c9c9c9;
        }
      }
    }
`;
