import React, {useState} from 'react';
import styled from 'styled-components/macro';

import {fetchCurrentUser, fetchToken, validateRequestUser} from '../userSlice';
import {modalRoutes, requestModalRoute} from '../../modal/modalSlice';
import {IconInput} from "../../../components/IconInput";
import {Button} from "../../../components/Button";
import {useTranslation} from "react-i18next";
import {useAppDispatch} from "../../../app/store";
import {unwrapResult} from "@reduxjs/toolkit";
import {useToasts} from "react-toast-notifications";
import {ModalFooter} from "../../../components/ModalFooter";
import {useHistory} from "react-router";
import {bindPackage, selectPackages} from "../../package/packageSlice";
import {useSelector} from "react-redux";

export function LoginForm() {
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const {addToast} = useToasts();
    const history = useHistory();
    const packagesState = useSelector(selectPackages);

    const initialValidationState = {
        password: [],
        email: []
    };

    const [validationErrors, setValidationErrors] = useState(initialValidationState);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [trustedClient, setTrustedClient] = useState(false);

    const handleEmailChange = (e: any) => {
        setEmail(e.target.value);
    }

    const handlePasswordChange = (e: any) => {
        setPassword(e.target.value);
    };

    const handleTrustedClientChange = (e: any) => {
        setTrustedClient(!trustedClient);
    };

    const handleSubmit = () => {
        dispatch(fetchToken({
            email: email,
            password: password,
            trustedClient: trustedClient
        })).then(unwrapResult).then(result => {
            if (result.error) {
                // user validation error? request token and route to token input form
                if (result?.error === "User not validated.") {
                    handleUnvalidatedUser();
                    return;
                }

                // other errors
                result.error === 'validation_error' ? setValidationErrors({...initialValidationState, ...result.validation_errors}) :
                    addToast(t(result.error), {appearance: 'error', autoDismiss: true});
                return;
            }

            dispatch(fetchCurrentUser()).then(unwrapResult).then(result => {
                dispatch(requestModalRoute(null, {}));

                // claim package
                if(packagesState?.package?.id) {
                    dispatch(bindPackage({
                        id: packagesState.package.id,
                        anonymousToken: localStorage.getItem('_packageToken.' + packagesState.package.id) ?? ''
                    }));
                }

                if(document.location.pathname === '/') {
                    history.push('/packages');
                }
            });
        })
    }

    const handleUnvalidatedUser = () => {
        dispatch(validateRequestUser({email: email})).then(unwrapResult).then(result => {
            if (result.error) {
                addToast(t(result.error), {appearance: 'error', autoDismiss: true});
            } else {
                dispatch(requestModalRoute(modalRoutes.validate, {}));
            }
        })
    };

    const handleRecoverPasswordClick = (e: any) => {
        e.preventDefault();
        dispatch(requestModalRoute(modalRoutes.requestPasswordReset, {}));
    }

    const handleRegisterClick = (e: any) => {
        e.preventDefault();
        dispatch(requestModalRoute(modalRoutes.register, {}));
    }

    const handleReturnPressed = () => {
        handleSubmit();
    }

    return (
        <Wrapper>
            <form>
                <div className={"email"}>
                    <div className={"formgroup"}>
                        <IconInput className={"email"} validationError={t(validationErrors.email[0])} type={"email"}
                                   placeholder={t('emailaddress')}
                                   onChange={handleEmailChange}/>
                    </div>
                </div>
                <div className={"password"}>
                    <div className={"formgroup"}>
                        <IconInput className={"password"} validationError={t(validationErrors.password[0])}
                                   type={"password"}
                                   placeholder={t('password')}
                                   onChange={handlePasswordChange} onReturn={handleReturnPressed}/>
                    </div>
                </div>
                <div className={"trustedClient"} onClick={handleTrustedClientChange}>
                    <div className={"formgroup"}>
                        <input
                            type="checkbox"
                            onChange={handleTrustedClientChange}
                            checked={trustedClient}
                        />
                        <span>{t('trust_pc_for_30_days')}</span>
                    </div>
                </div>
                <div className={"actions"}>
                    <Button text={t('login')} className={"button primary"} onClick={handleSubmit}/>
                    <span><a href={""} onClick={handleRecoverPasswordClick}>{t('recover_password')}</a></span>
                </div>
                <ModalFooter>
                    <span><a href="#" onClick={handleRegisterClick}>{t('no_account')}</a></span>
                </ModalFooter>
            </form>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    flex: 0 0 450px;
    @media screen and (max-width: 1000px) {
      flex: 1;    
    }
    
    .formgroup, .actions {
        padding: 10px 0 10px 0;
    }
       
    .actions {
      text-align: center;
      margin-top: 10px;
      
      .primary {
        margin-bottom: 20px;
      }
      
      a {
        color: #666;
      }
    }
    
    .trustedClient {
        padding: 10px 0 0 20px;
        cursor: pointer;
        
        .formgroup {
            display: flex;
            align-items: center;
            flex-direction: row;
        }

        span {
            margin-left: 5px;
        }
    }
`;
