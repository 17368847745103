import BaseTransformer from "./BaseTransformer";
import DocumentTransformer from "./DocumentTransformer";
import SignatureFieldTransformer from "./SignatureFieldTransformer";

export default class PackageStatsTransformer extends BaseTransformer {
    public static transform(data: any): any {
        return {
            all: data.statusCounts.all,
            draft: data.statusCounts.draft,
            inProgress: data.statusCounts.inProgress,
            completed: data.statusCounts.completed + data.statusCounts.signed,
            expired: data.statusCounts.expired,
            canceled: data.statusCounts.canceled
        }
    }
}
