import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppThunk, RootState} from '../../app/store';

export const modalRoutes = {
    register: 'register',
    validate: 'validate',
    login: 'login',
    requestPasswordReset: 'requestPasswordReset',
    setPassword: 'setPassword',
    addField: 'addField',
    addSigner: 'addSigner',
    createSigner: 'createSigner',
    sign: 'sign',
    write: 'write',
    verify: 'verify',
    customButtons: 'customButtons',
    packageSettings: 'packageSettings',
    createLongLivedToken: 'createLongLivedToken',
    userMenu: 'userMenu',
    phoneVerification: 'phoneVerification',
    editUser: 'editUser'
}

interface UserState {
    modalRoute: string | null,
    modalRouteParams: any | null,
}

const initialState: UserState = {
    modalRoute: null,
    modalRouteParams: {},
};

export const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        modelRouteRequested: (state, action: PayloadAction<{ route: any | null, params: any | null }>) => {
            state.modalRoute = action.payload.route;
            state.modalRouteParams = action.payload.params;
        },
    },
});

export const {modelRouteRequested} = modalSlice.actions;


// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched

export const requestModalRoute = (route: string | null, params: any | null): AppThunk => dispatch => {
    dispatch(modelRouteRequested({route, params}));
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.package.value)`
export const selectModal = (state: RootState) => state.modal;

export default modalSlice.reducer;
