import React from 'react';
import styled from 'styled-components/macro';
import {Footer} from "../../../components/Footer";
import {TopBar} from "../../../components";

export function PrivacyPolicy() {
  return (
    <Wrapper>
      <div className={"inner"}>
        <TopBar/>
        <Middle className={"odd"}>
          <div className={"resource"}>
            <div className={"inner"}>
              <h1>Privacy policy</h1>
              <h2>Privacy statement voor de websites van mijnkantoor.nl, legalsign.app en zendoc.nl</h2>
              <p>
                Op een aantal plaatsen op de websites van MKA diensten wordt om gegevens gevraagd die te herleiden zijn
                naar individuele personen, zoals een telefoonnummer, e-mail- of postadres. MKA streeft na om
                persoonsgegevens die tijdens een bezoek aan de websites worden achtergelaten zo te verwerken en te
                beheren dat privacy wordt beschermd en gewaarborgd.
              </p>
              <h3>Doel verwerking van persoonsgegevens</h3>
              <p>
                Als een informatie aanvraag wordt ingediend of anderszins contact wordt gelegd met MKA, legt
                MKA gegevens vast. De gegevens die in dergelijke gevallen worden toegestuurd, worden
                verzameld, opgeslagen en gebruikt om verzoeken te kunnen beantwoorden.
              </p>
              <h3>Bewaren van persoonsgegevens</h3>
              <p>
                MKA bewaart persoonsgegevens niet langer dan noodzakelijk is voor de hierboven genoemde
                doeleinden waarvoor persoonsgegevens zijn verzameld, opgeslagen of worden gebruikt.
              </p>
              <h3>Verstrekken persoonsgegevens</h3>
              <p>
                Alle aan MKA verstrekte persoonsgegevens zullen, tenzij anders kenbaar wordt gemaakt, niet
                door Mijn Kantoor aan derden worden verstrekt, tenzij dit bij wet is vereist of door de
                justitiële autoriteiten wordt verlangd.
              </p>
              <h3>Rechten</h3>
              <p>
                Elke betrokkene heeft het recht tot inzage en correctie van eigen persoonsgegevens. Door in
                te loggen in de supportomgeving van MKA diensten kunnen eigen gegevens gemakkelijk in worden
                gezien en gecorrigeerd.
              </p>
              <h3>Geheimhouding</h3>
              <p>
                Alle aan MKA verstrekte persoonsgegevens zullen vertrouwelijk worden behandeld. MKA, de
                medewerkers van MKA en zij die in opdracht van MKA toegang hebben tot persoonsgegevens zijn
                verplicht tot geheimhouding van de persoonsgegevens waarvan zij kennis nemen, behoudens voor
                zover zij door wet of regelgeving verplicht worden mededelingen te doen.
              </p>
              <h3>Beveiliging</h3>
              <p>
                MKA hanteert verschillende maatregelen om de veiligheid en integriteit van je
                persoonsgegevens te kunnen waarborgen. Hieronder vallen maatregelen tegen ongeoorloofde
                toegang, ongeoorloofd gebruik, ongeoorloofde wijziging en onrechtmatig en onbedoelde
                vernietiging of onbedoeld verlies.
              </p>
              <h3>Websites van derden</h3>
              <p>
                Op de websites mijnkantoor.nl, zendoc.nl en legalsign.app kunnen links worden aangetroffen
                naar websites van derden. Hoewel deze websites met zorg zijn geselecteerd draagt MKA geen
                verantwoordelijkheid voor de omgang met persoonsgegevens door deze derden. Lees hiervoor het
                privacy statement van deze websites voor meer informatie.
              </p>
              <h3>Aanpassen privacybeleid</h3>
              <p>
                MKA behoudt zich het recht voor dit privacybeleid aan te passen. Wijzigingen zullen altijd
                op deze website worden gepubliceerd. Voor vragen over het privacybeleid van MKA diensten kun
                je altijd contact opnemen met info@mijnkantoorapp.nl.
              </p>
            </div>
          </div>
        </Middle>
        <Footer/>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`   
    width: 100vw;
    height: 100vh;
    overflow: auto;

    .inner {
      padding: 20px 30px 20px 30px;      
      position: relative;
      margin: 0 auto;
      z-index: 1;
      
      &.dark {
        background: black;
      }
      
      .icon {
        font-size: 5rem;
        margin: 20px 0 20px 0;
      }
    }
    
     .lightspeed {
      position: fixed;
      height: 100vh;
      width: 100vw;
      overflow: hidden;
      z-index: 0;
    }    
`;

const Middle = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    // padding: 30px; 
    border-radius: 16px;
    margin: 0 auto;
    max-width: 984px;
    margin-top: 50px;
    
    h1 {
      font-size: 2rem;
      margin-bottom: 30px;
    }

    p {
      max-width: 1200px;
      line-height: 1.2rem;
      margin-bottom: 10px;
    }

    h3, h2 {
      margin-top: 10px;
      margin-bottom: 5px;
    }
    
    .resource {
      background-image: linear-gradient(to right, rgb(53, 42, 180), rgb(98, 84, 255)); 
      border-radius: 16px;
      padding: 3px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transition: all .3s ease-in-out;
      cursor: pointer;
      flex-flow: wrap;
      
      .inner {
        width: 100%;
        background-color: black;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1;
        
        p {
          color: #c9c9c9;
        }
      }
    }
`;
