import React, {useEffect, useState} from 'react';
import {FaTrash} from "react-icons/all";
import styled from 'styled-components/macro';
import {Rnd} from "react-rnd";
import './rnd.css';
import {useSelector} from "react-redux";
import {downloadSignature, selectPackages} from "../../packageSlice";
import {unwrapResult} from "@reduxjs/toolkit";
import {useAppDispatch} from "../../../../app/store";
import {fieldAction} from "./AddSigner";

interface Props {
  mode: string,
  field: any,
  active: boolean,
  left: number,
  top: number,
  width: number,
  height: number,
  imageWidth: number,
  imageHeight: number,
  showDelete: boolean,

  boundsChanged(id: string, width: number, height: number, left: number, top: number, action: string, textData?: string, signerId?: string, imageWidth?: number, imageHeight?: number): any;
  imageLoaded(field: any, imageWidth?: number, imageHeight?: number): any;

  onClick(id: string): any;

  onDeleteClick(id: string): any;
}

export function SignatureField(props: Props) {
  let rndInstance: any;
  const [width, setWidth] = useState(props.width);
  const [height, setHeight] = useState(props.height);
  const [left, setLeft] = useState(props.left);
  const [top, setTop] = useState(props.top);
  const dispatch = useAppDispatch();
  const [signatureData, setSignatureData] = useState();
  const packagesState = useSelector(selectPackages);
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const signature = packagesState.signatures.find(s => s.signatureField.id === props.field.id);
    if (!signature) {
      return;
    }

    dispatch(downloadSignature({
      packageId: packagesState.package.id,
      signatureId: signature.id
    })).then(unwrapResult).then(result => {
      setSignatureData(result);
    })
  }, [props.field.fieldId, packagesState.signatures])

  useEffect(() => {
    setWidth(props.width);
    setHeight(props.height);
    setLeft(props.left);
    setTop(props.top);

    rndInstance && rndInstance.updateSize({
      height: props.height,
      width: props.width
    });
    rndInstance && rndInstance.updatePosition({
      y: props.top,
      x: props.left
    });
  }, [props, rndInstance]);

  useEffect(() => {
    packagesState.signatures.forEach((signature) => {
      if ((signature.signatureField.imageWidth === 0 || signature.signatureField.imageHeight === 0) && rndInstance && signature.signatureField.id === props.field.id && imageLoaded) {
        const innerImage = rndInstance.resizableElement.current.getElementsByTagName('img')[0];
        props.imageLoaded(
          props.field,
          innerImage.width,
          innerImage.height
        );
      }
    });
  }, [imageLoaded]);

  const handleResize = (e: any, direction: any, ref: any, delta: any, p: any) => {
    const _width = parseInt(ref.style.width.replace('px', ''));
    const _height = parseInt(ref.style.height.replace('px', ''));
    const innerImage = ref.getElementsByTagName('img')[0];
    setWidth(_width);
    setHeight(_height);
    setLeft(p.x);
    setTop(p.y);
    props.boundsChanged(props.field.id, _width, _height, p.x, p.y, fieldAction.sign, undefined, undefined, innerImage.width, innerImage.height);
  }

  const hasParentWithClass = (element: any, classname: string): boolean => {
    if (element.className && element.className.split !== undefined && element.className.split(' ').indexOf(classname) >= 0) return true;
    return element.parentNode && hasParentWithClass(element.parentNode, classname);
  }

  const handleDragStop = (e: any, p: any) => {
    if (p.x.toPrecision(2) === left.toPrecision(2) && p.y.toPrecision(2) === top.toPrecision(2)) {
      return;
    }
    setLeft(p.x);
    setTop(p.y);

    props.boundsChanged(props.field.id, width, height, p.x, p.y, fieldAction.sign);
  }

  const handleMouseDown = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    props.onClick(props.field.id)
  }

  const handleDeleteClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    // @todo: prevent handleDragStop from firing patch to api

    props.onDeleteClick(props.field.id)
  }

  const HandleClasses = props.active ? {
    bottomLeft: 'rnd-handle rnd-handle-bottom-left',
    bottomRight: 'rnd-handle rnd-handle-bottom-right',
    topLeft: 'rnd-handle  rnd-handle-top-left',
    topRight: 'rnd-handle  rnd-handle-top-right',
  } : {};


  const renderWrapper = () => {
    return (<Wrappper className={props.active ? 'signature-field signature-field active' : 'signature-field'}>
      {props.showDelete &&
          <div className={"delete"}>
              <div className={"icon"} onClick={handleDeleteClick}><FaTrash/></div>
          </div>
      }
      <div className={"inner"}>

        {signatureData ?
          <>
            {signatureData &&
                <img className={"signature"} src={`data:image/svg+xml;base64,${signatureData}`} onLoad={() => {setImageLoaded(true)}}/>}
          </>
          :
          <>
            <span className={"name"}>{props.field.signer.firstName}</span>
          </>
        }

      </div>
    </Wrappper>);
  }

  return (
    props.mode === 'compose') ?
    <Rnd
      ref={rnd => rndInstance = rnd}
      default={{x: left, y: top, width: width, height: height}}
      bounds={"parent"}
      onDragStop={handleDragStop}
      onResizeStop={handleResize}
      onMouseDown={handleMouseDown}
      onClick={handleMouseDown}
      resizeHandleClasses={HandleClasses}
      minWidth={50}
      minHeight={25}
    >
      {renderWrapper()}
    </Rnd> : renderWrapper()
}


const Wrappper = styled.div`
    cursor:pointer;
    border-radius: 4px;
    width:100%;
    height: 100%;
    display:flex;
    flex-direction:column;   
    overflow:hidden;
    background-color: rgba(98,84,255,0.3);
    color: #333;
    border:2px solid #6254FF;
    transition: all .2s ease-in-out;
 
    &.active {        
        height:100%;
        background-color: rgba(98,84,255,0.4);
        
        .delete {
            display: flex;
        }
    }
    
    .delete {
        display: none;
        justify-content: flex-end;
        padding: 4px;
        position: absolute; 
        top: -30px;
        right: -30px;     
        
        .icon {
            border-radius: 50px;
            color: white;
            background: #6254FF;
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all .3s ease-in-out;
            
            &:hover {
              background: #3727f1;
            }
        }   
    }
       
    .inner {
        display: flex;
        flex-direction: column;
        flex: 1;
        align-items: center;
        justify-content: center;
        
        .name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;            
        }
        
        .signature {
          height: calc(100%);
        }        
    }
`;