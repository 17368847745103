import React, {useState} from 'react';
import styled from "styled-components/macro";
import {Button} from "../../../components/Button";
import {useAppDispatch} from "../../../app/store";
import {fetchPackage, selectPackages, updatePackage} from "../packageSlice";
import {useSelector} from "react-redux";
import {requestModalRoute} from "../../modal/modalSlice";

export function PhoneVerification() {
  const dispatch = useAppDispatch();
  const packagesState = useSelector(selectPackages);

  function handleEmailClick() {
    updatePhoneVerification(false);
  }

  function handleEmailAndSmsClick() {
    updatePhoneVerification(true);
  }

  function updatePhoneVerification(phoneVerificationRequired: boolean) {
    dispatch(updatePackage({
      id: packagesState.package.id,
      phoneVerificationRequired: phoneVerificationRequired
    })).then(() => {
      dispatch(requestModalRoute(null, {}));
      dispatch(fetchPackage({id: packagesState.package.id}));
    })
  }

  return (
    <Wrapper>
      <div className={"actions"}>
        <Button text={"E-mail"} className={"button primary"} onClick={handleEmailClick}/>
      </div>
      <div className={"actions"}>
        <Button text={"E-mail en SMS verificatie"} className={"button primary"} onClick={handleEmailAndSmsClick}/>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  flex: 0 0 450px;
  @media screen and (max-width: 1000px) {
    flex: 1;    
  }  margin-bottom: 15px;
    
  .actions {
    flex: 1;
    padding: 10px 0 10px 0;
    text-align: center;
  }
`;