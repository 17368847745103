import React, {memo} from 'react';
import {useHistory} from "react-router";
import {useAppDispatch} from "../app/store";
import styled from 'styled-components/macro';
import {FaPlusCircle} from 'react-icons/fa';
import {useTranslation} from "react-i18next";
import { createPackage, createDocument } from "../features/package/packageSlice";
import {unwrapResult} from "@reduxjs/toolkit";
import {Button} from "./Button";
import {useSelector} from "react-redux";
import {selectUser} from "../features/user/userSlice";


type InputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement>;

interface Props extends InputProps {
    id?: string;
    className?: string;
    placeholder?: string;
    loading: boolean;
}

export const UploadButton = memo(
    ({id, className, loading}: Props) => {
        const {t} = useTranslation();
        const userState = useSelector(selectUser);
        const dispatch = useAppDispatch();
        const history = useHistory();
        let fileInput: any;

        const handleClick = () => {
            fileInput.click();
        }

        const handleFileChange = async (e: any) => {
            const file = e.target.files[0];
            const customerId = userState?.currentUser?.customer?.id; // for now...

            dispatch(createPackage({name: file.name, customerId: customerId})).then(unwrapResult).then(result => {
                const packageId = result.id;

                dispatch(createDocument({packageId: packageId, document: { file: file}}))
                    .then(unwrapResult)
                    .then(result => {
                        history.push("/packages/" + packageId + '/documents/' + result.id + '/compose');
                    });
            });
        }

        return (
            <Wrapper className={className}>
                <Button text={t("upload_pdf")} className={"primary"} loading={loading} onClick={handleClick}/>
                <input type={"file"} ref={ref => fileInput = ref} accept=".pdf" onChange={handleFileChange}/>
            </Wrapper>
        );
    },
);

const Wrapper = styled.div`
  min-width: 120px;
  input {
    display:none;
  }
   display: none;
  
    @media screen and (max-width: 1000px) {
      display: block;
    }
`;
