import React, {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import styled from 'styled-components/macro';
import {
    selectPackages, setCurrentPage
} from '../../features/package/packageSlice'

import ReactTooltip from "react-tooltip";
import {DocumentViewerPage} from "./DocumentViewerPage";
import {ToolBar} from "../../features/package/compose/components/ToolBar";
import {selectUser} from "../../features/user/userSlice";
import {packageStatuses} from "../../features/package/packageStatuses";
import {config} from "../../app/config";
import {Document} from "react-pdf";
import {LoadingSpinner} from "../LoadingSpinner";

interface Props {
    onSigningFieldClick(id: string): any;
    onTextFieldClick(id: string, pageId?: string, field?: any): any;
    mode: string;
    className?: string;
}

export function DocumentViewer(props: Props) {
    const packagesState = useSelector(selectPackages);
    const userState = useSelector(selectUser);
    const dispatch = useDispatch();

    const maxScale = 3;
    const minScale = .3
    const pageWidth = window.innerWidth;
    const pageMarginBottom = 20;
    const isMobile = window.innerWidth <= 1000;

    const [scale, setScale] = useState(isMobile ? .9 : .5);

    const handleZoomIn = () => {
        if (scale < maxScale) {
            setScale(scale + .2);
        }
    }

    const handleZoomOut = () => {
        if (scale > minScale) {
            setScale(scale - .2);
        }
    }


    const handleScroll = (e: any) => {
        const height = parseInt(e.target.children[0].style.height.replace('px', ''));
        const page = Math.floor(e.target.scrollTop / (height + pageMarginBottom));

        dispatch(setCurrentPage(page));
    }

    // const pagesRef = React.useRef(null)
    // usePinch(state => {
    //     state.event.preventDefault();
    //     // @todo: fix issue where pinch is not working when fingers are on different pages
    //
    //     const delta = state.delta[0] + state.delta[1];
    //     const current = pagesRef.current ?? new HTMLElement();
    //
    //     // on mobile devices the scale must be added
    //     const newScale = isMobile ? scale + (delta / 100) : scale - (delta / 200);
    //     const percentageScrolled = (current.scrollTop / current.scrollHeight) * 100;
    //
    //     if (newScale > minScale && newScale < maxScale) {
    //         setScale(newScale);
    //     }
    //
    //     // keep relative scroll offset percentage, so view doesn't shift on zoom
    //     current.scrollTop = current.scrollHeight / 100 * percentageScrolled;
    //     current.scrollLeft = current.scrollWidth * newScale;
    //
    // }, {domTarget: pagesRef, eventOptions: {passive: false}})


    const renderPages = () => {
        if (!packagesState.package || !packagesState.signatureFields || !packagesState.document) {
            return;
        }

        const pages: any = [];


        const document = packagesState.document;
        const fields = packagesState.signatureFields.filter((f: any) => {
            return f.documentId === document.id
        });

        const textFields = packagesState.textFields.filter((f: any) => {
            return f.documentId === document.id
        });

        packagesState.document.pages.map((p :any) => {
            const pageHeight = pageWidth * parseFloat(p.ratio);
            
            pages.push(<DocumentViewerPage
                key={p.id}
                page={p}
                width={pageWidth}
                height={pageHeight}
                renderPreview={true}
                marginBottom={pageMarginBottom}
                scale={scale}
                onSigningFieldClick={props.onSigningFieldClick}
                signatureFields={fields.filter((f: any) => {
                    return packagesState?.package?.status !== packageStatuses.Completed && f.pageId === p.id && (props.mode !== "sign" || f.signerId === userState?.currentSigner?.id)
                })}
                onTextFieldClick={props.onTextFieldClick}
                textFields={textFields.filter((f: any) => {
                    return packagesState?.package?.status !== packageStatuses.Completed && f.pageId === p.id && (props.mode !== "sign" || f.signerId === userState?.currentSigner?.id)
                })}
            />);
        });

        return pages;
    }

    const url = config.api_base_url + "/api/packages/" + packagesState?.package?.id + "/documents/" +
        packagesState?.document?.id + "/download?token=" + packagesState?.document?.previewToken;

    return (
        <Wrapper className={props.className}>
            <ToolBar
                activeFieldId={packagesState.activeFieldId}
                onZoomIn={handleZoomIn}
                onZoomOut={handleZoomOut}
                onDelete={() => {}}
                showDelete={false}
            />

            {packagesState?.document?.id &&
            <Pages onScroll={handleScroll} className={"pages"} id={"pages"}>
                <Document
                    file={url}
                    loading={<Loading><LoadingSpinner/></Loading>}
                    onLoadProgress={(e) => {
                    }}
                >
                    {renderPages()}
                </Document>
            </Pages>}

            <>
                {/*<Controls className={"controls"}>*/}
                {/*    <div onClick={handleZoomOut} data-tip={t('zoom_out')}><span><FaMinus/></span></div>*/}
                {/*    <div onClick={handleZoomIn} data-tip={t('zoom_in')}><span><FaPlus/></span></div>*/}
                {/*</Controls>*/}
                {!isMobile && <ReactTooltip place="top" type="dark" effect="solid"/>}
            </>

        </Wrapper>
    );
}

const Wrapper = styled.div`
  &:hover {
    .controls {
        opacity: 1;
    }
  }
  display: flex;  
  flex-direction: column;
  position: relative;
  overflow: hidden;
  border-radius: 32px;    
  background-color:rgba(151,151,151,.33);
  
  @media screen and (max-width: 1000px) {
    border-radius: 8px;  
  }
`;

const Pages = styled.div`         
  padding: 70px 0 25px 0;
  overflow: auto;
  
    scrollbar-color: #6254FF #f1f1f1;
    scrollbar-width: thin;
  
      /* width */
    ::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }
    
    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #6254FF;
    }
    
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #7467fa;
    }
`;


const Loading = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  min-height: 500px;
`;

//
// const Controls = styled.div`
//   display:flex;
//   opacity: 0;
//   position: absolute;
//   margin: 0 auto;
//   bottom: 5%;
//   left: 50%;
//   margin-left: -50px;
//   width:100px;
//   background-color: rgb(66, 66, 66);
//   z-index: 3;
//   align-items:center;
//   justify-content:center;
//   border-radius:4px;
//   transition: opacity 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s;
//
//     -webkit-touch-callout: none; /* iOS Safari */
//     -webkit-user-select: none; /* Safari */
//      -khtml-user-select: none; /* Konqueror HTML */
//        -moz-user-select: none; /* Old versions of Firefox */
//         -ms-user-select: none; /* Internet Explorer/Edge */
//             user-select: none;
//
//
//   div {
//     cursor:pointer;
//     display:block;
//     padding: 5px;
//     margin: 5px;
//     border-radius:4px;
//     transition: background 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s;
//
//     &:hover {
//         background-color: black;
//     }
//
//     span {
//         color:white;
//         width: 20px;
//         height: 20px;
//         font-size: 0.6rem;
//         display:flex;
//         align-items:center;
//         justify-content:center;
//         border: 1px solid white;
//         border-radius: 40px;
//     }
//   }
// `;
